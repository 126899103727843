// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.work-preference .content-section {
  padding: 0px 180px 0px 0px;
}
.work-preference .content-section .title {
  color: #232340;
  font-size: 3.125rem;
  font-weight: 600;
  font-family: "Lexend Deca", sans-serif;
}
.work-preference .content-section .content {
  color: #232340;
  font-size: 1.125rem;
  font-family: "Lexend Deca", sans-serif;
}
.work-preference .content-section .learn-more-link {
  color: #5468e7;
  font-size: 1.125rem;
  font-family: "Lexend Deca", sans-serif;
  display: flex;
  align-items: center;
  gap: 7px;
  text-decoration: underline;
  margin-top: 30px;
}

@media only screen and (max-width: 991px) {
  .work-preference img {
    width: 100%;
  }
  .work-preference .content-section {
    padding: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/home/ChooseWorkPreference.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;AAAJ;AACI;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,sCAAA;AACN;AACI;EACE,cAAA;EACA,mBAAA;EACA,sCAAA;AACN;AACI;EACE,cAAA;EACA,mBAAA;EACA,sCAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,0BAAA;EACA,gBAAA;AACN;;AAIA;EAEI;IACE,WAAA;EAFJ;EAIE;IACE,YAAA;EAFJ;AACF","sourcesContent":[".work-preference {\n  .content-section {\n    padding: 0px 180px 0px 0px;\n    .title {\n      color: #232340;\n      font-size: 3.125rem;\n      font-weight: 600;\n      font-family: \"Lexend Deca\", sans-serif;;\n    }\n    .content {\n      color: #232340;\n      font-size:  1.125rem;\n      font-family: \"Lexend Deca\", sans-serif;;\n    }\n    .learn-more-link {\n      color: #5468e7;\n      font-size:  1.125rem;\n      font-family: \"Lexend Deca\", sans-serif;;\n      display: flex;\n      align-items: center;\n      gap: 7px;\n      text-decoration: underline;\n      margin-top: 30px;\n    }\n  }\n}\n\n@media only screen and (max-width: 991px) {\n  .work-preference {\n    img{\n      width: 100%;\n    }\n    .content-section {\n      padding: 0px;\n  }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
