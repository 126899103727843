import React from "react";

const SidebarLoader = () => {
  return (
    <div className="p-2">
      <p className="placeholder-glow">
        <span
          className="placeholder col-12 mb-2"
          style={{ height: 15, color: "#e2e5ec" }}
        ></span>
        <span
          className="placeholder col-10 mb-2"
          style={{ height: 15, color: "#e2e5ec" }}
        ></span>
        <span
          className="placeholder col-10 mb-2"
          style={{ height: 15, color: "#e2e5ec" }}
        ></span>
        <span
          className="placeholder col-10 mb-2"
          style={{ height: 15, color: "#e2e5ec" }}
        ></span>
        <span
          className="placeholder col-10 mb-2"
          style={{ height: 15, color: "#e2e5ec" }}
        ></span>
        <span
          className="placeholder col-10 mb-2"
          style={{ height: 15, color: "#e2e5ec" }}
        ></span>
        <span
          className="placeholder col-12 mb-2"
          style={{ height: 15, color: "#e2e5ec" }}
        ></span>
        <span
          className="placeholder col-10 mb-2"
          style={{ height: 15, color: "#e2e5ec" }}
        ></span>
        <span
          className="placeholder col-10 mb-2"
          style={{ height: 15, color: "#e2e5ec" }}
        ></span>
      </p>
    </div>
  );
};

export default SidebarLoader;
