import React, { useEffect, useState } from "react";
import { Button, ListGroup, Form, Dropdown } from "react-bootstrap";
import { TASK_STATUS_TYPE, FILTER_TYPE } from "./../../../utils/types";
import { getTaskStatusColor } from "./../../../utils/utils";
import { CustomToggle } from "./../../../utils/utils";

const TaskSortList = ({
  getSortWiseFilters,
  filteredProjectId,
  filteredType,
}) => {
  const [checkedStatuses, setCheckedStatuses] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");

  useEffect(() => {
    if (filteredType != FILTER_TYPE.SORT) {
      setCheckedStatuses([]);
      setSelectedOrder("");
    }
  }, [filteredType]);

  useEffect(() => {
    (checkedStatuses.length > 0 || selectedOrder != "") &&
      getSortWiseFilters(checkedStatuses, selectedOrder);
  }, [checkedStatuses, selectedOrder]);

  const handleCheckboxChange = (status) => {
    setCheckedStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
  };

  const handleRadioChange = (event) => {
    setSelectedOrder(event.target.value);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} variant="secondary">
        <Button
          variant="link"
          className="align-items-center icon-with-btn text-dark"
        >
          <i className="ri-arrow-up-down-fill fs-5" /> Sort
        </Button>
      </Dropdown.Toggle>

      <Dropdown.Menu className="mt-10-f me--10-f dropdown-max-width">
        <div className="dropdown-menu-body">
          <div className="flex-fill bg-gray-100 b-5">
            <div className="mt-2">
              <div className="d-flex justify-content-center align-items-center">
                <p className="text-center">Sort this board by column</p>
              </div>
              <div className="p-3 pt-0 d-flex text-white">
                <ListGroup
                  style={{ backgroundColor: "#242341" }}
                  className="border-0 w-60"
                >
                  {TASK_STATUS_TYPE.map((status) => (
                    <ListGroup.Item
                      className="d-flex align-items-center  justify-content-between bg-gray-200 icon-with-btn"
                      key={status.id}
                      //style={{ backgroundColor: "#242341" }}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={`bg-${getTaskStatusColor(
                            status.id
                          )} me-2 text-white  text-capitalize p-2 rounded-circle task-status-icon`}
                          style={{
                            backgroundColor: `bg-${getTaskStatusColor(
                              status.id
                            )}`,
                          }}
                        >
                          {status.name.slice(0, 1)}
                        </div>

                        <p className="m-0 p-2">{status.name}</p>
                      </div>
                      <div>
                        <Form.Check
                          type="checkbox"
                          className="p-2 align-items-end"
                          onChange={() => handleCheckboxChange(status.id)}
                          checked={checkedStatuses.includes(status.id)}
                        ></Form.Check>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <ListGroup className="ms-2">
                  <ListGroup.Item
                    className="d-flex align-items-center justify-content-between bg-gray-200"
                    //style={{ backgroundColor: "#242341" }}
                  >
                    Ascending
                    <Form.Check
                      type="radio"
                      className="p-2 align-items-end"
                      value="asc"
                      onChange={handleRadioChange}
                      checked={selectedOrder === "asc"}
                    ></Form.Check>
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="d-flex align-items-center justify-content-between bg-gray-200"
                    //style={{ backgroundColor: "#242341" }}
                  >
                    Descending
                    <Form.Check
                      type="radio"
                      className="p-2 align-items-end"
                      value="desc"
                      onChange={handleRadioChange}
                      checked={selectedOrder === "desc"}
                    ></Form.Check>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TaskSortList;
