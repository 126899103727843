import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  accountActivate,
  emailVerificationLink,
} from "../store/actions/authActions";
import pageSvg from "../assets/svg/mailbox.svg";

const VerifyAccount = () => {
  const navigate = useNavigate();
  const [reSendActivationLink, setReSendActivationLink] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const dispatch = useDispatch();
  const { accountActivated, sendEmilVerification } = useSelector(
    (state) => state.auth
  );

  const handleAccountActivate = async () => {
    try {
      const res = await dispatch(accountActivate(token));
      if (res.payload.status === "error") {
        setReSendActivationLink(true);
      } else {
        navigate("/signin");
      }
    } catch (error) {
      console.error("Error activating account:", error);
    }
  };

  const handleResendActivationLink = () => {
    dispatch(emailVerificationLink({ email }));
  };

  return (
    <>
      <div className="page-auth">
        <div className="content">
          <Container>
            <Card className="card-auth">
              <Card.Body className="text-center">
                <div className="mb-5">
                  <img src={pageSvg} alt="Email veryfication" width={200} />
                </div>
                <Card.Title>Verify your email address</Card.Title>
                <Card.Text className="mb-5">
                  Please check your email and click the verify button or link to
                  verify your account.
                </Card.Text>

                <Row className="g-2 g-sm-3">
                  <Col lg>
                    {!reSendActivationLink ? (
                      <Button variant="primary" onClick={handleAccountActivate}>
                        Verify Account
                      </Button>
                    ) : (
                      <Button
                        onClick={handleResendActivationLink}
                        variant="primary"
                        className="btn-sign"
                        disabled={sendEmilVerification === "loading"}
                      >
                        {sendEmilVerification === "loading" ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Resend Activation Link"
                        )}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
