import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../servces/Api";
import Alert from "../../components/common/Alert";

export const getUserProfileInfo = createAsyncThunk(
  "getUserProfileInfo",
  async (userId) => {
    const response = await get(`/api/get_user_det/${userId}`);
    const data = await response.data;
    if (response.status === 200) {
      return data;
    } else {
      return { err: "something went wrong" };
    }
  }
);

export const updateUserProfileInfo = createAsyncThunk(
  "updateUserProfile",
  async (updatedData) => {
    try {
      const response = await post("/api/update_profile", updatedData);
      if (response.error) {
        Object.values(response.error).forEach((err) => {
          Alert.error("Error Profile  save");
          return false;
        });
      } else {
        if (response.status === 200) {
          Alert.success("Profile Saved ");
        } else {
          Alert.error("Error Profile save");
          return false;
        }
      }
    } catch (error) {
      Alert.error("Error Profile Create");
      return false;
    }
  }
);
