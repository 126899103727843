import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormModal from '../common/FormModal';
import { Form, Spinner } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";
import { post, get } from '../../servces/Api';
import Alert from '../common/Alert';

function ChatRatingModal({ modalState, toggleFormModal, project_id }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [dataInit, setDataInit] = useState({})
    const [rating, setRating] = useState();
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        toggleFormModal();
    };

    const ratingChanged = (newRating) => {
        setRating(newRating);
    };

    useEffect(() => {
        if (modalState.ref_number) {
            getFeedback();
        }
    }, [modalState.ref_number])

    const getFeedback = async () => {
        const id = modalState.ref_number;
        try {
            const response = await get(`/api/load_customer_feedback/${id}`);
            if (response.status === 200) {
                setDataInit(response.data)
                const ratingValue = response.data?.feedback_rate ? Number(response.data.feedback_rate) : null;
                setRating(ratingValue);
            }
        } catch (error) {
            Alert.error("Unable to Get Feedback!");
        }
    };

    const sendFeedback = async (data) => {
        setLoading(true);
        const feedbackData = {
            project_id: project_id,
            task_id: modalState.ref_number,
            feedback_rate: rating,
            comment: data.feedback,
        };

        try {
            const response = await post("/api/save_customer_feedback", feedbackData);
            if (response.status === 200) {
                Alert.success("Successfully sent Feedback!");
                handleClose();
            }
        } catch (error) {
            Alert.error("Unable to Send Feedback!");
            setLoading(false);
            handleClose();
        }
    };

    return (
        <FormModal
            moduleName={dataInit.comment ? "View Feedback" : "Add Feedback"}
            modalState={modalState.open}
            toggleFormModal={handleClose}
            size="md"
        >
            <form onSubmit={handleSubmit(sendFeedback)}>
                <div>
                    <p className="text-center">
                        We highly value your feedback! Kindly take a moment
                        to rate your experience and provide us with your
                        valuable feedback.
                    </p>
                    <div className='d-flex justify-content-center'>
                        {!rating &&
                            <ReactStars
                                count={5}
                                size={50}
                                onChange={ratingChanged}
                                activeColor="#ffd700"
                                value={rating}
                            />
                        }
                        {rating &&
                            <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={50}
                                activeColor="#ffd700"
                                value={rating}
                                edit={dataInit.comment ? false : true}
                            />
                        }
                    </div>
                    <div>
                        <Form.Control
                            as="textarea"
                            aria-label="With textarea"
                            placeholder="Enter Feedback"
                            style={{ height: "150px" }}
                            value={dataInit.comment}
                            disabled={dataInit.comment}
                            {...register("feedback", { required: "Please input feedback" })}
                        />
                        {errors.feedback && <p className="text-danger">{errors.feedback.message}</p>}
                    </div>
                    {!dataInit.comment &&
                        <div className='d-flex justify-content-center mr-5 mt-4'>
                            <Button type="submit" disabled={loading} className='fs-6 text-capitalize'>
                                {loading && <Spinner animation="border" size="sm" />}{" "} Save Feedback
                            </Button>
                        </div>
                    }
                </div>
            </form>
        </FormModal>
    );
}

export default ChatRatingModal;
