import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../store/actions/dashboardActions"; // Corrected the import
import { Card, Col, Nav, Row, Alert } from "react-bootstrap";
import { formatDate } from "../../utils/utils";
import CountLoader from "../dashboard/ContLoader";
import ListLoader from "../dashboard/ListLoader";
import Avatar from "../common/Avatar";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, error, dashboardData } = useSelector(
    (state) => state.dashboard
  );
  const colCount = 4;

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);

  // Dashboard count section
  const countData = [
    {
      label: "Users",
      icon: "ri-group-line",
      value: dashboardData?.data?.all_users_count || 0,
      smallText: "Our system all users",
    },
    {
      label: "Active Users",
      icon: "ri-user-heart-line",
      value: dashboardData?.data?.active_users_count || 0,
      smallText: "Our system active users",
    },
    {
      label: "Onboard Users",
      icon: "ri-user-add-line",
      value: dashboardData?.data?.pending_onboard_users_count || 0,
      smallText: "Our system onboard users",
    },
    {
      label: "Inactive Users",
      icon: "ri-user-unfollow-line",
      value: dashboardData?.data?.inactive_users_count || 0,
      smallText: "Our system inactive users",
    },
  ];

  return (
    <>
      {isLoading ? (
        <CountLoader colCOunt={colCount} />
      ) : (
        <Row>
          {countData.map((card, index) => (
            <Col xs="6" xl="3" key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">
                    {card.label}
                  </Card.Title>
                  <h3 className="card-value mb-1">
                    <i className={card.icon}></i> {card.value}
                  </h3>
                  <small>{card.smallText}</small>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      <Row className="g-3 mt-2">
        {isLoading ? (
          <ListLoader rowCount={10} xlCol={4} />
        ) : (
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Recently joined companies</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {dashboardData?.data?.new_company?.length > 0 ? (
                    dashboardData.data.new_company.map((user, index) => (
                      <li className="people-item" key={index}>
                        <Avatar initial={user.com_name.charAt(0)} />
                        <div className="people-body">
                          <h6>
                            <Link to="">{user.com_name}</Link>
                          </h6>
                          <span>{formatDate(user.created_at)}</span>
                        </div>
                        <Nav as="nav" className="nav-icon">
                          <Link to="/manage_user_accounts">
                            <i className="ri-contacts-line"></i>
                          </Link>
                        </Nav>
                      </li>
                    ))
                  ) : (
                    <li className="people-item">
                      <div className="people-body">
                        <Alert
                          variant="primary"
                          className="d-flex align-items-center mt-2 mb-2"
                        >
                          <small>
                            There are no records at the moment. Please check
                            back later.
                          </small>
                        </Alert>
                      </div>
                    </li>
                  )}
                </ul>
              </Card.Body>
              {dashboardData?.data?.new_company?.length !== 0 ? (
                <Card.Footer className="d-flex justify-content-start">
                  <Link to="/manage_user_accounts" className="fs-sm">
                    Manage Users
                  </Link>
                </Card.Footer>
              ) : (
                ""
              )}
            </Card>
          </Col>
        )}

        {isLoading ? (
          <ListLoader rowCount={10} xlCol={4} />
        ) : (
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Recently joined users </Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {dashboardData?.data?.new_users?.length > 0 ? (
                    dashboardData.data.new_users.map((user, index) => (
                      <li className="people-item" key={index}>
                        <Avatar initial={user.first_name.charAt(0)} />
                        <div className="people-body">
                          <h6>
                            <Link to="">{`${user.first_name} ${user.last_name}`}</Link>
                          </h6>
                          <span>{formatDate(user.created_at)}</span>
                        </div>
                        <Nav as="nav" className="nav-icon">
                          <Link to="/manage_user_accounts">
                            <i className="ri-contacts-line"></i>
                          </Link>
                        </Nav>
                      </li>
                    ))
                  ) : (
                    <li className="people-item">
                      <div className="people-body">
                        <Alert
                          variant="primary"
                          className="d-flex align-items-center mt-2 mb-2"
                        >
                          <small>
                            There are no records at the moment. Please check
                            back later.
                          </small>
                        </Alert>
                      </div>
                    </li>
                  )}
                </ul>
              </Card.Body>
              {dashboardData?.data?.new_users?.length !== 0 ? (
                <Card.Footer className="d-flex justify-content-start">
                  <Link to="/manage_user_accounts" className="fs-sm">
                    Manage Users
                  </Link>
                </Card.Footer>
              ) : (
                ""
              )}
            </Card>
          </Col>
        )}

        {isLoading ? (
          <ListLoader rowCount={10} xlCol={4} />
        ) : (
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6"> Unapproved onboard users</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {dashboardData?.data?.pending_users?.length > 0 ? (
                    dashboardData.data.pending_users.map((user, index) => (
                      <li className="people-item" key={index}>
                        <Avatar initial={user.first_name.charAt(0)} />
                        <div className="people-body">
                          <h6>
                            <Link to="">{`${user.first_name} ${user.last_name}`}</Link>
                          </h6>
                          <span>{formatDate(user.created_at)}</span>
                        </div>
                        <Nav as="nav" className="nav-icon">
                          <Link to="/user_onboard">
                            <i className="ri-contacts-line"></i>
                          </Link>
                        </Nav>
                      </li>
                    ))
                  ) : (
                    <li className="people-item">
                      <div className="people-body">
                        <Alert
                          variant="primary"
                          className="d-flex align-items-center mt-2 mb-2"
                        >
                          <small>
                            There are no records at the moment. Please check
                            back later.
                          </small>
                        </Alert>
                      </div>
                    </li>
                  )}
                </ul>
              </Card.Body>
              {dashboardData?.data?.pending_users?.length !== 0 ? (
                <Card.Footer className="d-flex justify-content-start">
                  <Link to="/user_onboard" className="fs-sm">
                    Onboard users
                  </Link>
                </Card.Footer>
              ) : (
                ""
              )}
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default AdminDashboard;
