import { createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../servces/Api"; // Ensure this path is correct
import Alert from "../../components/common/Alert";

export const CustomerSupport = createAsyncThunk(
  "Support/CustomerSupport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await post("/api/get_in_touch", data);

      if (response.status === 200) {
        Alert.success("Your message was sent successfully!");
        return response.data;
      } else {
        Alert.error("Failed to send message. Please try again later.");
        return rejectWithValue(response.data);
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to send message. Please try again later.");
      }
      return rejectWithValue(error.response ? error.response.data : null);
    }
  }
);
