import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import mockupImg from "../../assets/img/mockup.png";
import "../../assets/styles/components/home/MockupSection.scss";

const MockupSection = () => {
  return (
    <>
      <div className="mockup-area pd-top-60 pd-bottom-60 bg-light-gray">
        <Container fluid>
          <Row>
            <Col lg={6} className="order-xs-2">
              <div className="content-section mb-0 ">
                <h2 className="title">Chats for your distributed teams</h2>
                <p className="content mb-4">
                  Team combines the immediacy of real-time chat with an email
                  threading model. With Team, you can catch up on important
                  conversations while ignoring irrelevant ones.
                </p>
                <Link className="learn-more-link" to="/about">
                  Learn more <i className="ri-arrow-right-s-line"></i>
                </Link>
              </div>
            </Col>
            <Col lg={6} className="order-xs-1 text-end p-0">
              <img className="main-img full-img" src={mockupImg} alt="img" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MockupSection;
