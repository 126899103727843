import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUserPackageExpired } from "../store/reducers/authSlice";

const RoleBasedRoute = ({ element, requiredRoles }) => {
  const isAuthenticated = useSelector((state) => state.auth.userInfo);
  const UserData = useSelector((state) => state.auth.userInfo);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;

    const checkSubscriptionStatus = async () => {
      if (UserData?.package_end_date !== null) {
        try {
          const endTimeDate = new Date(UserData?.package_end_date);
          const currentTime = new Date();

          if (endTimeDate) {
            if ((currentTime > endTimeDate) && isAuthenticated) {
              dispatch(setUserPackageExpired());
              if (currentPath === '/pricing_packages') {
                return false;
              } else {
                if (currentPath === '/upgrade-package') {
                  return false
                } else {
                  return navigate("/upgrade-package");
                }
              }
            }
          }
        } catch (error) {
          console.error('Error checking subscription status:', error);
        }
      }
    };

    const intervalId = setInterval(checkSubscriptionStatus, 10000);

    checkSubscriptionStatus();

    return () => clearInterval(intervalId);

  }, [UserData, location]);

  if (requiredRoles.includes(isAuthenticated.user_role)) {
    return element;
  } else {
    return <Navigate to="/dashboard" replace />;
  }
};

export default RoleBasedRoute;
