import React from "react";

import Footer from "../../components/dashboard/Footer";
import ProjectList from "../../components/dashboard/projects/ProjectList";

const MyProjects = () => {
  const moduleName = "My Projects";

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{ moduleName }</li>
            </ol>
            <h4 className="main-title mb-0">{ moduleName }</h4>
          </div>
          {/* <div className="col-md-6">
            <Form>
              <Row className="g-2">
                <Col col="3">
                  <Form.Select aria-label="Default select example">
                    <option>select</option>
                    <option value="1">Ongoing</option>
                    <option value="2">Completed</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Control type="text" placeholder="Search" />
                </Col>
              </Row>
            </Form>
          </div> */}
        </div>
        <div className="inner-wrapper">
          <ProjectList />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyProjects;
