import { useState, useEffect } from "react";
import { ROLES } from "../utils/types";

const userRoleMap = {
  1: ROLES.ADMIN,
  2: ROLES.CUSTOMER,
  3: ROLES.COMPANY,
  4: ROLES.DELIVERY,
  5: ROLES.FIELD,
};

const useUserRole = (userId) => {
  const [userRoleId, setUserRoleId] = useState(null);
  const [userRoleName, setUserRoleName] = useState(null);

  useEffect(() => {
    const fetchUserRole = () => {
      if (userId != null) {
        const role = userRoleMap[userId] ?? null;
        if (role === null) {
          console.warn(`Unknown user role ID: ${userId}`);
        }
        setUserRoleId(role);
        setUserRoleName(
          Object.keys(ROLES).find((key) => ROLES[key] === role) ?? null
        );
      }
    };

    fetchUserRole();
  }, [userId]);

  return { userRoleId, userRoleName };
};

export default useUserRole;
