import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeaderImage from "../../assets/img/HeaderImage.png";
import styles from "../../assets/styles/components/home/Banner.module.scss";

export default function Banner() {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <div
        className={`bg-relative pb-0 bg-cover ${styles.banner_area}`}
        style={{ backgroundImage: `url(${HeaderImage})` }}
      >
        <div className="container">
          <div className={`row ${styles.banner_conent}`}>
            <div
              className={`col-lg-6 pb-xl-5 align-self-center ${styles.banner_text}`}
            >
              <div className={`pe-xl-4 ${styles.banner_inner}`}>
                <h1 className="animate__animated animate__fadeIn">
                  We are the next generation of Field Services Partner
                </h1>
                <p className="w-50 animate__animated animate__fadeIn">
                  WE PROVIDE MEANINGFUL PARTNERSHIP FOR END-TO-END MANAGED
                  SERVICE EXCELLENCE
                </p>
                <div
                  className={`d-flex mt-4 animate__animated animate__fadeIn ${styles.subscribe}`}
                >
                  <div>
                    <Form.Control
                      className="form-control-lg mb-2"
                      type="email"
                      value={email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                    />
                  </div>
                  <div>
                    <Link
                      to={`/signup?email=${email}`}
                      type="submit"
                      className={`btn btn-primary mt-0 ${styles.btn}`}
                    >
                      Join Now
                    </Link>
                  </div>
                  {/* <div className="col-lg-6 align-self-center">
                    <Form.Control
                      className="form-control-lg mb-2"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  <Link to="/signup" type="submit"  className={`btn btn-primary ${styles.btn}`}>Join Now</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
