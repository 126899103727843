import { createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../servces/Api";
import Alert from "../../components/common/Alert";

export const saveFeedbackData = createAsyncThunk(
  "saveFeedbackData",
  async (data) => {
    try {
      const response = await post("/api/save_feedback", data, false);
      if (response.error) {
        Object.values(response.error).forEach((err) => {
          Alert.error("Error feedback data save");
        });
        return false;
      } else {
        if (response.status === 200) {
          Alert.success("Feedback data Saved Success");
        } else {
          Alert.error("Error feedback data save");
          return false;
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error related to save_feedback API
        Alert.error("Error feedback data save: 404 Not Found");
        return false;
      } else {
        // Handle other errors, including token refresh
        Alert.error("Error feedback data save");
        return false;
      }
    }
  }
);
