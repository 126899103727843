import React from 'react'
import myImage from '../assets/img/server_down.svg';

const NotFound = () => {
  return (
    <div className='content'>
      <div class="container">
        <div class="row mt-4">
          <div class="col-lg-12 text-center">

            <img width="50%" src={ myImage } alt="server down" />

            <h1 class="error-number">404</h1>
            <h2 class="error-title">Page Not Found</h2>
            <p class="error-text">Oopps. The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved.</p>
            <a href="../" class="btn btn-primary btn-error">Back to Home</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound