import { createAsyncThunk } from "@reduxjs/toolkit";
import Alert from "../../components/common/Alert";
import { BlobServiceClient } from "@azure/storage-blob";
import {
  uploadFileReset,
  uploadFileStart,
  uploadFileSuccess,
  uploadFileFailure,
} from "../reducers/fileUploadSlice";

export const uploadFile = createAsyncThunk(
  "fileUpload/uploadFile",
  async ({ file, sasToken }, { rejectWithValue, dispatch }) => {
    dispatch(uploadFileStart());
    try {
      const blobUrl = await handleFileUpload({ file, sasToken });
      Alert.success("File uploaded successfully");
      dispatch(uploadFileSuccess(blobUrl));
      return blobUrl;
    } catch (error) {
      Alert.error("Error uploading file:", error);
      console.error("Error uploading file:", error);
      dispatch(uploadFileFailure(error));
      return rejectWithValue(error.message);
    }
  }
);

export const uploadReset = createAsyncThunk(
  "fileUpload/uploadReset",
  async (_, { dispatch }) => {
    dispatch(uploadFileReset());
  }
);

const handleFileUpload = async ({ file, sasToken }) => {
  const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME;
  const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;

  const blobServiceClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
  );

  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blobName = `${Date.now()}_${file.name}`;
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);

  try {
    await blockBlobClient.uploadBrowserData(file, {
      blobHTTPHeaders: { blobContentType: file.type },
    });
    return blockBlobClient;
  } catch (error) {
    console.error("File upload error: ", error);
    throw error;
  }
};
