import React, { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { Card, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/actions/authActions";

const CompletedOnboard = ({ isCustomerOrAdmin, countdown }) => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(logoutUser());
  };

  return (
    <>
      <div className="text-center">
        <i
          className="ri-checkbox-circle-fill rounded-circle border border-success text-success"
          style={{ fontSize: 60 }}
        ></i>
        <h3>Onboard process succesfuly completed!</h3>
        {isCustomerOrAdmin ? (
          <>
            <p>
              {countdown > 0 && (
                <span>
                  Please wait <strong>{countdown}</strong> seconds...
                </span>
              )}
              You will be automatically redirected to the
              <strong> signin page</strong> . Thank you!
            </p>
          </>
        ) : (
          <>
            <p>
              Your account will be approved by the system administrator very
              soon. You will receive an email notification once the admin has
              approved your account.
            </p>
            <p>Click the 'Log Out' button to log out of the system.</p>
            <Button variant="primary" onClick={handleLogout}>
              <i className="ri-logout-box-r-line"></i> Log Out
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default CompletedOnboard;
