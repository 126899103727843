import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ListLoader = ({ rowCount, xlCol }) => {
  return (
    <Col md="6" xl={ xlCol }>
      <SkeletonTheme baseColor="#e2e5ec" highlightColor="#f3f5f9">
        <div className="card w-100">
          <div className="card-body">
            <h5 className="card-title">
              <Skeleton width={ `80%` } />
            </h5>
            <p className="card-text">
              <Skeleton count={ rowCount } height={ 30 } />
            </p>
            <Skeleton width={ `60%` } />
          </div>
        </div>
      </SkeletonTheme>
    </Col>
  );
};

export default ListLoader;