import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Form, Table, Alert, Button } from "react-bootstrap";
import Footer from "../../components/dashboard/Footer";
import useAuth from "../../hooks/useAuth";
import useUserRole from "../../hooks/getUserRole";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserRoles,
  fetchPermissions,
  savePermissions,
} from "../../store/reducers/permissionsSlice";
import TableLoader from "../../components/common/TableLoader";

const UserPermissions = () => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state.permissions.userRoles);
  const { loading, permissions, error } = useSelector(
    (state) => state.permissions
  );

  const moduleName = "UserRole Permissions";
  const roleSelectRef = useRef(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [modulePermissions, setModulePermissions] = useState([]);
  const isRoleIdNullOrZero = selectedRoleId === null || selectedRoleId === 0;

  useEffect(() => {
    dispatch(fetchUserRoles());
  }, []);

  const handleRoleChange = (userRoleId) => {
    setSelectedRoleId(userRoleId);
    dispatch(fetchPermissions(userRoleId));
  };

  useEffect(() => {
    if (permissions) {
      const modulesArray = permissions.map((permission) => ({
        module_id: permission.id,
        module_name: permission.name,
        view: permission.is_view,
        add: permission.is_add,
        edit: permission.is_edit,
        cancel: permission.is_cancel,
        approval: permission.is_approval,
      }));
      setModulePermissions(modulesArray);
    }
  }, [permissions]);

  const resetForm = () => {
    setModulePermissions([]);
    setSelectedRoleId(null);
    roleSelectRef.current.value = "0";
  };

  const handlePermissionChange = (moduleId, permissionType, currentValue) => {
    setModulePermissions((prevModulePermissions) => {
      const updatedPermissions = prevModulePermissions.map((module) => {
        if (module.module_id === moduleId) {
          return {
            ...module,
            [permissionType]: !currentValue,
          };
        }
        return module;
      });

      return updatedPermissions;
    });
  };

  const handleSavePermissions = async () => {
    dispatch(savePermissions({ selectedRoleId, modulePermissions }));
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{ moduleName }</li>
            </ol>
            <h4 className="main-title mb-0">{ moduleName }</h4>
          </div>
        </div>
        <div className="d-flex flex-row mb-4 col-md-3">
          <Form.Select
            ref={ roleSelectRef }
            onChange={ (e) => handleRoleChange(e.target.value) }
          >
            <option value="0">Select Role</option>
            { userRoles?.map((role) => (
              <option key={ role.id } value={ role.id }>
                { role.role_name }
              </option>
            )) }
          </Form.Select>
        </div>

        { isRoleIdNullOrZero ? (
          <Alert variant="primary" className="d-flex align-items-center mb-2">
            <i className="ri-information-line"></i> User role not selected.
            Please select a user role.
          </Alert>
        ) : (
          <Row className="justify-content-center">
            <div className="container">
              { loading ? (
                <TableLoader rowCount={ 15 } columnCount={ 6 } />
              ) : (
                <Table bordered striped className="mb-4">
                  <thead>
                    <tr>
                      <th>Module</th>
                      <th>View</th>
                      <th>Add</th>
                      <th>Edit</th>
                      <th>Cancel</th>
                      <th>Approval</th>
                    </tr>
                  </thead>
                  <tbody>
                    { modulePermissions?.map((module) => (
                      <tr key={ module.module_id }>
                        <td>{ module.module_name }</td>
                        <td>
                          <input
                            type="checkbox"
                            id={ `${module.module_name}-view` }
                            checked={ module.view }
                            onChange={ () =>
                              handlePermissionChange(
                                module.module_id,
                                "view",
                                module.view
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id={ `${module.module_name}-add` }
                            checked={ module.add }
                            onChange={ () =>
                              handlePermissionChange(
                                module.module_id,
                                "add",
                                module.add
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id={ `${module.module_name}-edit` }
                            checked={ module.edit }
                            onChange={ () =>
                              handlePermissionChange(
                                module.module_id,
                                "edit",
                                module.edit
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id={ `${module.module_name}-cancel` }
                            checked={ module.cancel }
                            onChange={ () =>
                              handlePermissionChange(
                                module.module_id,
                                "cancel",
                                module.cancel
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id={ `${module.module_name}-approval` }
                            checked={ module.approval }
                            onChange={ () =>
                              handlePermissionChange(
                                module.module_id,
                                "approval",
                                module.approval
                              )
                            }
                          />
                        </td>
                      </tr>
                    )) }
                  </tbody>
                </Table>
              ) }

              <div className="d-flex gap-3">
                <Button
                  type="button"
                  onClick={ resetForm }
                  variant="primary"
                  className="btn-sign"
                  disabled={ loading }
                >
                  { loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Reset"
                  ) }
                </Button>
                <Button
                  type="button"
                  onClick={ handleSavePermissions }
                  variant="primary"
                  className="btn-sign"
                  disabled={ loading }
                >
                  { loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Save"
                  ) }
                </Button>
              </div>
            </div>
          </Row>
        ) }

        <Footer />
      </div>
    </>
  );
};

export default UserPermissions;
