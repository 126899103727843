import React, { useState } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import useUserRole from "../../../hooks/getUserRole";
import DownloadButton from "../DownloadButton";
import FormModal from "../../common/FormModal";
import UpdateProfile from "../../common/profile/UpdateProfile";
import { formatDate } from "../../../utils/utils";
import style from "../../../assets/styles/components/user-manage/userInfoView.module.scss";
import ProfileLoading from './profileLoading';
import userAvatar from "../../../assets/img/user.png";

const ProfileView = ({ myProfile = false }) => {
  const { profileData, isLoading, error } = useSelector(
    (state) => state.profile
  );
  const [showModalState, setShowModalState] = useState(false);
  const { userRoleName } = useUserRole(profileData.userdet?.user_role_id);
  const {
    first_name = "",
    last_name = "",
    address1 = "",
    address2 = "",
    address3 = "",
    region = "",
    country = "",
    contact = "",
    email = "",
    status = "",
    is_pending = false,
    is_onboard = false,
    doc = "",
    created_at = "",
    user_description = "",
    company = {},
  } = profileData?.userdet || {};

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleEditProfile = (profileData) => {
    if (profileData) {
      toggleFormModal();
    }
  };

  return (
    <>
      { isLoading ? (
        <ProfileLoading />
      ) : (
        <div className="col-lg-12">
          <div className="media-profile mb-3 bg-gray-200 p-3">
            <div className="media-img mb-3 mb-sm-0">
              <img src={ userAvatar } alt="" />
            </div>
            <div className="media-body pl-1">
              <h5 className="media-name">
                { first_name && last_name && first_name + " " + last_name }{ " " }
                { userRoleName && <Badge bg="primary">{ userRoleName }</Badge> }
              </h5>

              <p className="d-flex gap-2 mb-2">
                <i className="ri-map-pin-line"></i>{ " " }
                { `
                  ${address1 ? address1 + " " : ""}
                  ${address2 ? address2 + " " : ""}
                  ${address3 ? address3 + " " : ""}
                  ${region ? region + " " : ""}
                  ${country || ""}
                `}
              </p>
              <p className="mb-0 w-80">{ user_description }</p>
            </div>
            { myProfile && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Edit <strong>Profile</strong>.
                  </Tooltip>
                }
              >
                <Button
                  variant="secondary"
                  className="btn-icon"
                  onClick={ () => {
                    handleEditProfile(profileData);
                  } }
                >
                  <i className="ri-edit-2-line"></i>
                </Button>
              </OverlayTrigger>
            ) }
          </div>
          <div className="about-text go-to">
            <div className={ `row ${style.about_list}` }>
              <div className="col-md-6">
                <div className={ style.media }>
                  <label>Email</label>
                  <p>{ email ? <a href={ `mailto:${email}` }>{ email }</a> : "-" }</p>
                </div>
                <div className={ style.media }>
                  <label>Contact No</label>
                  <p>{ contact ? contact : "-" }</p>
                </div>

                <div className={ style.media }>
                  <label>Created date</label>
                  <p>{ created_at ? `${formatDate(created_at)}` : "-" }</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className={ style.media }>
                  <label>Address</label>
                  <p>{ `
                  ${address1 ? address1 + " " : ""}
                  ${address2 ? address2 + " " : ""}
                  ${address3 ? address3 + " " : ""}
                  ${region ? region + " " : ""}
                  ${country || ""}
                `}</p>
                </div>
                <div className={ style.media }>
                  <label>Company</label>
                  <p>{ company ? company?.com_name : "-" }</p>
                </div>

                <div className={ style.media }>
                  <label>Status</label>
                  <p>
                    { is_onboard === 0 ? (
                      <Badge
                        bg={ `${is_onboard === 0 && is_pending === 1
                          ? "danger"
                          : "success"
                          }` }
                      >
                        { is_onboard === 0 && is_pending === 1
                          ? "Document Upload Pending"
                          : "Admin Approval Pending" }
                      </Badge>
                    ) : (
                      <Badge bg={ `${status === 1 ? "success" : "danger"}` }>
                        { status === 1 ? "Active" : "Inactive" }
                      </Badge>
                    ) }
                  </p>
                </div>
              </div>
              { doc !== "0" && (
                <div className="col-md-12">
                  <div className={ style.media }>
                    <label>Documents</label>
                    <DownloadButton
                      fileName={ doc }
                      toolTip={ "Click here download document" }
                    />
                  </div>
                </div>
              ) }
            </div>
          </div>
        </div>
      ) }

      {/* TO DO: FEUTER USING THIS CODE */ }
      {/* <div className={`${style.counter} card pt-3 shadow-sm`}>
            <div className="row">
              <div className="col-6 col-lg-3">
                <div className="count-data text-center">
                  <h6 className="count h2" data-to="500" data-speed="500">
                    500
                  </h6>
                  <p className="m-0px font-w-600">Happy Clients</p>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="count-data text-center">
                  <h6 className="count h2" data-to="150" data-speed="150">
                    150
                  </h6>
                  <p className="m-0px font-w-600">Project Completed</p>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="count-data text-center">
                  <h6 className="count h2" data-to="850" data-speed="850">
                    850
                  </h6>
                  <p className="m-0px font-w-600">Ongoin Projects</p>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="count-data text-center">
                  <h6 className="count h2" data-to="190" data-speed="190">
                    190
                  </h6>
                  <p className="m-0px font-w-600">Customers</p>
                </div>
              </div>
            </div>
          </div> */}
      <FormModal
        moduleName={ "Update Profile" }
        modalState={ showModalState }
        toggleFormModal={ toggleFormModal }
        width="600px"
      >
        <UpdateProfile
          profileData={ profileData }
          isLoading={ isLoading }
          onClose={ toggleFormModal }
          parent={ 'my_profile' }
        />
      </FormModal>
    </>
  );
};

export default ProfileView;
