import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import router from "../../routes/Router";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { getSidebarRoutes } from "../../store/reducers/sidebarSlice";
import SidebarLoader from "./SidbarLoader";
import NFSLogoDark from "../../assets/img/icon.png";
//import NFSLogoLight from "../../assets/img/icon_mob.png";

const Sidebar = () => {
  const isAuthenticated = useAuth();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.sideBarRoutes);
  const [matchedMenu, setMatchedMenu] = useState([]);
  const [memuCategory, setMemuCategory] = useState([]);
  const [routesList, setRoutesList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const scrollBarRef = useRef(null);

  const matchingSidebarRoutes = async () => {
    dispatch(getSidebarRoutes());
  };

  useEffect(() => {
    if (isAuthenticated) {
      matchingSidebarRoutes();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (data) {
      setMemuCategory(data.memu_category);
      const mergedArray = data.menus
        .map((menuItem) => {
          const matchingRoute = router.routes[2].children.find(
            (route) => route.path.substring(1) === menuItem.route
          );
          if (matchingRoute) {
            return {
              ...menuItem,
              ...matchingRoute,
            };
          }
          return null;
        })
        .filter(Boolean);
      setRoutesList(mergedArray);
      setDataLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    if (dataLoaded) {
      const createMatchedMenuObject = () => {
        const matchedMenuObject = memuCategory.map((category) => {
          const categoryMenus = routesList.filter(
            (menu) => menu.menu_category_id === category.id
          );
          return {
            category: category,
            menus: categoryMenus,
          };
        });
        setMatchedMenu(matchedMenuObject);
      };

      createMatchedMenuObject();
    }
  }, [dataLoaded, memuCategory, routesList]);

  return (
    <div className={`sidebar`}>
      <div className="sidebar-header">
        <Link to="/dashboard" className="sidebar-logo">
          <img className="mob_logo" src={NFSLogoDark} alt="Logo" width={100} />
        </Link>
      </div>
      {loading === "pending" ? (
        <SidebarLoader />
      ) : (
        <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
          <SidebarMenu
            onUpdateSize={() => scrollBarRef.current.updateScroll()}
            matchedMenu={matchedMenu}
          />
        </PerfectScrollbar>
      )}
    </div>
  );
};

const SidebarMenu = ({ onUpdateSize, matchedMenu }) => {
  const [expandedCategories, setExpandedCategories] = useState([]);

  useEffect(() => {
    if (matchedMenu.length > 0) {
      const allCategoryIds = matchedMenu.map((match) => match.category.id);
      setExpandedCategories(allCategoryIds);
      onUpdateSize();
    }
  }, [matchedMenu, onUpdateSize]);

  const toggleMenu = (categoryId) => {
    setExpandedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
    onUpdateSize();
  };

  const populateMenu = (m) => {
    if (m.menus.length > 1) {
      const menu = m.menus.map((menu) => (
        <li key={menu.id} className="nav-item">
          <NavLink to={menu.path} className="nav-link">
            <i className={menu.icon.props.class}></i> <span>{menu.name}</span>
          </NavLink>
        </li>
      ));
      return (
        <ul
          key={m.id}
          className={`nav nav-sidebar ${
            expandedCategories.includes(m.category.id) ? "show" : ""
          }`}
        >
          {menu}
        </ul>
      );
    } else if (m.menus.length === 1) {
      return (
        <ul
          key={m.menus[0].id}
          className={`nav nav-sidebar ${
            expandedCategories.includes(m.category.id) ? "show" : ""
          }`}
        >
          <li className="nav-item">
            <NavLink to={m.menus[0].path} className="nav-link">
              <i className={m.menus[0].icon.props.class}></i>{" "}
              <span>{m.menus[0].name}</span>
            </NavLink>
          </li>
        </ul>
      );
    }
    return null;
  };

  return (
    <>
      <div className="nav-group">
        {matchedMenu?.map((match) => (
          <div
            key={match.category.id}
            className={`${
              expandedCategories.includes(match.category.id)
                ? "active_menu"
                : ""
            }`}
          >
            {match.menus.length > 0 ? (
              <div
                className="nav-label"
                onClick={() => toggleMenu(match.category.id)}
              >
                {match.category.menu_category_name}
              </div>
            ) : null}

            {populateMenu(match)}
          </div>
        ))}
      </div>
    </>
  );
};

window.addEventListener("click", function (e) {
  // Close sidebar when clicked outside of it
  let tar = e.target;

  // Hide sidebar offset when clicked outside of sidebar
  if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
    document.querySelector("body").classList.remove("sidebar-show");
  }
});

export default Sidebar;
