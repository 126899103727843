import { configureStore } from "@reduxjs/toolkit";
import { LOGOUT_USER } from "../utils/types";
import { encryptTransform } from "redux-persist-transform-encrypt";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import authReducer from "../store/reducers/authSlice";
import dashboardReducer from "../store/reducers/dashboardSlice";
import sidebarReducer from "../store/reducers/sidebarSlice";
import profileReducer from "../store/reducers/profileSlice";
import permissionsReducer from "../store/reducers/permissionsSlice";
import userManageReducer from "../store/reducers/userManageSlice";
import onboardReducer from "../store/reducers/onboardSlice";
import tenderReducer from "../store/reducers/tenderSlice";
import fileUploadReducer from "../store/reducers/fileUploadSlice";
import projectReducer from "../store/reducers/projectSlice";
import projectManageReducer from "../store/reducers/projectManageSlice";
import customerSupportReducer from "../store/reducers/customerSupportSlice"

const ENABLE_REDUX_DEV_TOOLS = false; //TODO : please remove go to production

const encryptor = encryptTransform({
  secretKey: "Super-Secret-key-jrtec",
  onError: function (error) {
    // Handle the error.
  },
});

const appReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  sideBarRoutes: sidebarReducer,
  profile: profileReducer,
  permissions: permissionsReducer,
  usersManage: userManageReducer,
  userOnboarded: onboardReducer,
  tender: tenderReducer,
  fileUpload: fileUploadReducer,
  project: projectReducer,
  projectManage: projectManageReducer,
  cutomerSupport: customerSupportReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  transforms: [encryptor],
};

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: ENABLE_REDUX_DEV_TOOLS,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

let persistor = persistStore(store);

export { store, persistor };
