import React, { useState } from "react";
import { Button, Row, Form, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Rating from "react-rating-stars-component";
import { useForm } from "react-hook-form";
import FormModal from "../common/FormModal";
import { saveFeedbackData } from "../../store/actions/feedbackAction";
import style from "../../assets/styles/components/home/feedbackForm.module.scss";

const FeedbackForm = () => {
  const [showModalState, setShowModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [rate, setRate] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const ratingChanged = (newRating) => {
    setRate(newRating);
  };

  const onSubmit = (data) => {
    setLoading(true);
    data.rate = rate;
    dispatch(saveFeedbackData(data));
    reset();
    setRate(0); // Reset the rating
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    toggleFormModal();
  };

  return (
    <>
      <div className="row">
        <div className="text-center mt-2">
          <button
            type="button"
            className="btn btn-primary feed-btn"
            onClick={ toggleFormModal }
          >
            Add Your Feedback
          </button>
        </div>
      </div>
      <FormModal
        moduleName={ "Feedback" }
        modalState={ showModalState }
        toggleFormModal={ toggleFormModal }
        width="600px"
      >
        <form onSubmit={ handleSubmit(onSubmit) } className={ style.feedback_form }>
          <Row>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  required
                  { ...register("full_name", { required: "Name is required" }) }
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  className="form-input"
                  required
                  { ...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email format",
                    },
                  }) }
                />
                { errors.email && <p>{ errors.email.message }</p> }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>Rating</Form.Label>
                <Rating
                  count={ 5 }
                  size={ 40 }
                  onChange={ ratingChanged }
                  activeColor="#ffa534"
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>Share your experience in scaling</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="4"
                  style={ { height: 100 } }
                  { ...register("q_1", { required: "Name is required" }) }
                ></Form.Control>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  Are you satisfied with the order/quoting process in abdolit
                  Manager? If not, please explain why.
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  required
                  { ...register("q_2", { required: "Name is required" }) }
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  Have you found it easy to track project progress using abdolit
                  Manager? If not, please provide feedback.
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  required
                  { ...register("q_3", { required: "Name is required" }) }
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  Are there any improvements you would like to see in the change
                  request workflow?
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  required
                  { ...register("q_4", { required: "Name is required" }) }
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  Is there anything else you'd like to share about abdolit Manager,
                  such as specific challenges you've faced or suggestions for
                  enhancements?
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  required
                  { ...register("q_5", { required: "Name is required" }) }
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  Would you recommend abdolit Manager to others in your industry?
                  Why or why not?
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  required
                  { ...register("q_6", { required: "Name is required" }) }
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  How long have you been using abdolit Manager?
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  required
                  { ...register("q_7", { required: "Name is required" }) }
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  Please rate your overall satisfaction with abdolit Manager on a
                  scale of 1 to 10{ " " }
                  <small>
                    (1 being very dissatisfied and 10 being very satisfied)
                  </small>
                  .
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  required
                  { ...register("q_8", { required: "Name is required" }) }
                />
              </div>
            </div>
          </Row>
          <Row className="g-2 mt-2">
            <Col>
              <Button
                type="submit"
                className="btn-sign w-100"
                disabled={ loading }
              >
                { loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Submit"
                ) }
              </Button>
            </Col>
            <Col>
              <Button
                type="button"
                variant="outline-primary"
                className="btn-sign w-100"
                onClick={ toggleFormModal }
              >
                Close
              </Button>
            </Col>
          </Row>
        </form>
      </FormModal>
    </>
  );
};

export default FeedbackForm;
