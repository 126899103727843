// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BidList_tender_info_list__d5W4W {
  padding-top: 10px;
}
.BidList_tender_info_list__d5W4W .BidList_media__WpdRH {
  padding: 5px 0;
  display: flex;
}
.BidList_tender_info_list__d5W4W label {
  font-weight: 600;
  width: 195px;
  margin: 0;
  position: relative;
}
.BidList_tender_info_list__d5W4W label:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
  width: 1px;
  height: 12px;
  background: #20247b;
  transform: rotate(15deg);
  margin: auto;
  opacity: 0.5;
}
.BidList_tender_info_list__d5W4W p {
  margin: 0;
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/tender-list/BidList.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,cAAA;EACA,aAAA;AAEJ;AAAE;EACE,gBAAA;EACA,YAAA;EACA,SAAA;EACA,kBAAA;AAEJ;AADI;EAEE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EAKA,wBAAA;EACA,YAAA;EACA,YAAA;AAEN;AACE;EACE,SAAA;EACA,eAAA;AACJ","sourcesContent":[".tender_info_list {\n  padding-top: 10px;\n  .media {\n    padding: 5px 0;\n    display: flex;\n  }\n  label {\n    font-weight: 600;\n    width: 195px;\n    margin: 0;\n    position: relative;\n    &:after {\n      //content: \"\";\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      right: 11px;\n      width: 1px;\n      height: 12px;\n      background: #20247b;\n      -moz-transform: rotate(15deg);\n      -o-transform: rotate(15deg);\n      -ms-transform: rotate(15deg);\n      -webkit-transform: rotate(15deg);\n      transform: rotate(15deg);\n      margin: auto;\n      opacity: 0.5;\n    }\n  }\n  p {\n    margin: 0;\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tender_info_list": `BidList_tender_info_list__d5W4W`,
	"media": `BidList_media__WpdRH`
};
export default ___CSS_LOADER_EXPORT___;
