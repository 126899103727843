import React from 'react';
import FormModal from './FormModal';
import "../../assets/styles/components/common/LimitationExceedModal.scss";
import PackageEndImg from "../../assets/img/package-end.png";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LimitationExceedModal = ({ showViewModal, closeModal, name }) => {
    const navigate = useNavigate();

    return (
        <FormModal
            id="upgrade-modal"
            modalState={showViewModal}
            toggleFormModal={closeModal}
            size="md"
            staticBackdrop={true}
        >
            <div className='main-conatiner'>
                <img className='main-image' src={PackageEndImg} alt='shield-img' />
                <p className='main-text'>Your {name} has been exceeded!</p>
                <div className='desc-container'>
                    <p>{`You have reached the maximum number of ${name} for your current plan. Please upgrade to a more suitable package to continue.`}</p>
                </div>
                <div className='btn-container'>
                    <button
                        onClick={() => {
                            navigate("/pricing_packages");
                        }}
                        className='btn'
                    >
                        <i style={{ fontSize: "14px" }} className="ri-refresh-line"></i>Upgrade now
                    </button>
                </div>
            </div>
        </FormModal>
    );
};

export default LimitationExceedModal;
