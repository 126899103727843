import React from "react";

const ConfirmAlert = ({ show, onConfirm, onCancel, title, message }) => {
  if (!show) {
    return null; // Hide the alert if show is false
  }

  const handleCancel = () => {
    onCancel();
    // Additional logic to hide the alert
  };

  return (
    <div
      className="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      <strong>{title}</strong> {message}
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={handleCancel}
      ></button>
      <button
        type="button"
        className="btn btn-success mx-2"
        onClick={onConfirm}
      >
        Yes
      </button>
      <button type="button" className="btn btn-danger" onClick={handleCancel}>
        No
      </button>
    </div>
  );
};

export default ConfirmAlert;
