import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Col, Container, Image, Row, Spinner } from "react-bootstrap";

import Footer from "../../components/dashboard/Footer";
import { getSasToken } from "../../utils/utils";

import { fetchProjectInfo } from "../../store/actions/projectActions";
import ProjectInfo from "../../components/dashboard/projects/ProjectInfo";
import TenderViewLoading from "./TenderViewLoading";
import styles from "../../assets/styles/components/tender-list/TenderView.module.scss";
import DefaultImg from "../../assets/img/CardImage.jpg";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProjectView = () => {
  const query = useQuery();
  const projectId = query.get("projectId");
  const projectName = query.get("projectName");
  const moduleName = projectName;
  const dispatch = useDispatch();
  const { loading, projectInfoData } = useSelector((state) => state.project);

  const [currentImgSrc, setCurrentImgSrc] = useState(DefaultImg);
  const sasToken = getSasToken();
  const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
  const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME;

  useEffect(() => {
    if (projectInfoData?.images) {
      const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${projectInfoData.images}?${sasToken}`;
      setCurrentImgSrc(fileUrl);
    } else {
      setCurrentImgSrc(DefaultImg);
    }
  }, [projectInfoData, sasToken, storageAccountName, containerName]);

  const handleImageError = () => {
    setCurrentImgSrc(DefaultImg); // Set default image on error
  };

  useEffect(() => {
    dispatch(fetchProjectInfo(projectId));
  }, [dispatch, projectId]);

  return (
    <>
      <div className="main main-app tender-view p-3 p-lg-4">
        { loading ? (
          <TenderViewLoading />
        ) : (
          <>
            <div className="border border-secondary">
              <Container fluid className="p-0">
                <Row className="m-0">
                  <Col className="p-0">
                    <div className={ styles.banner_container }>
                      <Image
                        src={ currentImgSrc }
                        className={ styles.banner_image }
                        fluid
                        onError={ handleImageError }
                      />
                      <div className={ styles.overlay_content }>
                        <div>
                          <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item" aria-current="page">
                              <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                              <Link to="/my_projects">Project</Link>
                            </li>
                            <li className="breadcrumb-item active">
                              { moduleName }
                            </li>
                          </ol>
                          <h4 className="main-title mb-0">{ moduleName }</h4>
                        </div>
                        <div className="d-flex gap-2">
                          <Link
                            to="/my_projects"
                            className="btn btn-primary icon-with-btn"
                          >
                            <i className="ri-arrow-left-s-line"></i>{ " " }
                            <span>Back to my project</span>
                          </Link>
                          <Link
                            to={ `/project-chat?projectId=${projectId}` }
                            className="btn btn-primary"
                          >
                            <i className="ri-question-answer-line"></i>{ " " }
                            <span>Chat</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="inner-wrapper">
              <div
                className={ `${styles.TenderView_info} border border-secondary p-4` }
              >
                { projectInfoData && (
                  <ProjectInfo projectInfoData={ projectInfoData } />
                ) }
              </div>
            </div>
          </>
        ) }
        <Footer />
      </div>
    </>
  );
};

export default ProjectView;
