import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTaskList,
  createTask,
  updateTask,
  taskStatusUpdate,
} from "../actions/projectManageActions";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  projectTaskListData: null,
  taskData: null,
  taskStatusData: null,
};

const projectManageSlice = createSlice({
  name: "projectManage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Load task data
      .addCase(fetchTaskList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchTaskList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.projectTaskListData = action.payload;
      })
      .addCase(fetchTaskList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.success = false;
      })

      //Create Task and assign
      .addCase(createTask.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.taskData = action.payload;
      })
      .addCase(createTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.success = false;
      })

      //Spesific task view
      .addCase(updateTask.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.taskData = action.payload;
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.success = false;
      })

      //Spesific task status update
      .addCase(taskStatusUpdate.pending, (state) => {
        state.error = null;
        state.success = false;
      })
      .addCase(taskStatusUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.taskStatusData = action.payload;
      })
      .addCase(taskStatusUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.success = false;
      });
  },
});

export default projectManageSlice.reducer;
