import React from "react";
import { Badge } from "react-bootstrap";
import { formatDate } from "../../../utils/utils";
import DownloadButton from "../../common/DownloadButton";
import { TENDER_PROGRESS } from "../../../utils/types";
import styles from "../../../assets/styles/components/tender-list/TenderView.module.scss";

const TenderView = ({ tenderData, rejectReson }) => {
  const getTenderProgressType = (progressId) => {
    const progress = TENDER_PROGRESS.find(item => item.id === progressId);
    return progress ? progress : { type: "Unknown", bg: "secondary" };
  };

  const progress = getTenderProgressType(tenderData?.tender_progress_type);

  return (
    <>
      { tenderData && (
        <div>
          <p className="post-text">{ tenderData?.tender_description }</p>
          <div className={ `row ${styles.tender_info_list}` }>
            <div className="col-md-6">
              <div className={ styles.media }>
                <label>
                  <i className="ri-link"></i> Ref.No.
                </label>
                <p>{ tenderData?.id }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-flag-2-line"></i> Status
                </label>
                <p>
                  <Badge bg={ progress.bg }>{ progress.type }</Badge>
                </p>
              </div>
              { rejectReson &&
                <div className={ styles.media }>
                  <label>
                    <i className="ri-calendar-line"></i> Reject Reason
                  </label>
                  <p>
                    { rejectReson }
                  </p>
                </div>
              }

              <div className={ styles.media }>
                <label>
                  <i className="ri-community-line"></i> Company
                </label>
                <p>{ tenderData?.com_name }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-map-pin-line"></i> Location
                </label>
                <p>{ `${tenderData?.region}, ${tenderData?.country}` }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-contacts-book-line"></i> Contact
                </label>
                <p>{ tenderData?.contact }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-calendar-line"></i> Publish date
                </label>
                <p>{ formatDate(tenderData?.created_at) }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-calendar-line"></i> Closing date
                </label>
                <p className="text-danger">
                  { formatDate(tenderData?.tender_closing_date) }
                </p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-attachment-line"></i> Attachments
                </label>
                <p className="text-danger">
                  <DownloadButton
                    fileName={ tenderData?.attachments }
                    toolTip="Click here download document"
                  />
                </p>
              </div>
            </div>
          </div>
        </div >
      ) }
    </>
  );
};

export default TenderView;
