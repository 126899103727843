import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import useAuth from "../../../hooks/useAuth";
import { formatDate } from "../../../utils/utils";
import { TENDER_PROGRESS } from "../../../utils/types";
import { fetchTendersForBided } from "../../../store/actions/tenderActions";
import PaginationWithDataTable from "../../common/PagintionWithDataTable";

const TenderListForBided = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuth();
  const { loading, tendersForbidedData } = useSelector((state) => state.tender);

  //fetch data
  const fetchTendersForBidedData = useCallback(() => {
    dispatch(fetchTendersForBided());
  }, [dispatch]);

  //fetch tender data in page load
  useEffect(() => {
    fetchTendersForBidedData();
  }, [fetchTendersForBidedData]);

  //set table columns
  const columns = [
    {
      name: "Ref.No",
      selector: (row) => row.id,
      width: "75px",
    },
    {
      name: "Created date",
      selector: (row) => formatDate(row.created_at),
      width: "130px",
    },
    {
      name: "Tender name",
      selector: (row) => row.tender_name,
    },
    {
      name: "Added by",
      selector: (row) =>
        row.user_id === isAuthenticated.id ? (
          <Badge bg="primary">Our tender</Badge>
        ) : (
          <Badge bg="secondary">{ row.com_name }</Badge>
        ),
      width: "150px",
    },
    {
      name: "Tender type",
      selector: (row) => row.tender_type,
      width: "120px",
    },
    {
      name: "Closing date",
      selector: (row) => formatDate(row.tender_closing_date),
      width: "130px",
    },
    {
      name: "Status",
      selector: (row) => {
        const statusType = TENDER_PROGRESS.find(
          (status) => status.id === row.tender_progress_type
        );
        if (row.user_id === isAuthenticated.id) {
          if (statusType && [1, 2, 3, 4, 5, 6, 7].includes(statusType.id)) {
            return (
              <Badge className="badge" bg={ statusType.bg }>
                { statusType.type }
              </Badge>
            );
          }
        } else {
          if (statusType && [1, 2, 3, 7].includes(statusType.id)) {
            return (
              <Badge className="badge" bg={ statusType.bg }>
                { statusType.type }
              </Badge>
            );
          } else {
            return (
              <Badge className="badge" bg="danger">
                Bidding is over
              </Badge>
            );
          }
        }
        return null;
      },

      width: "260px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-2">
          <OverlayTrigger
            placement="top"
            overlay={ <Tooltip>View tender information</Tooltip> }
          >
            <div style={ { display: "inline-block" } }>
              <Link
                className="d-flex btn btn-primary btn-sm"
                to={ `/tender-view?tenderId=${row.id}&tenderName=${row.tender_name
                  }&forBided=${true}` }
              >
                <i className="ri-eye-line"></i>
              </Link>
            </div>
          </OverlayTrigger>
        </div>
      ),
      width: "105px",
    },
  ];

  //Set table rows data
  const rows = Array.isArray(tendersForbidedData?.tenders)
    ? tendersForbidedData.tenders.map(
      ({
        id,
        created_at,
        tender_name,
        tender_description,
        customer_name,
        customer_email,
        tender_type,
        tender_option,
        tender_closing_date,
        status,
        attachments,
        com_name,
        user_id,
        tender_progress_type,
      }) => ({
        id,
        created_at,
        tender_name,
        tender_description,
        customer_name,
        customer_email,
        tender_type,
        tender_option,
        tender_closing_date,
        status,
        attachments,
        com_name,
        user_id,
        tender_progress_type,
      })
    )
    : [];

  return (
    <>
      <PaginationWithDataTable
        dataColumns={ columns }
        entities={ rows }
        loading={ loading }
        rowCount={ 4 }
        columnCount={ 8 }
      />
    </>
  );
};

export default TenderListForBided;
