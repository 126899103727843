import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Footer from "../../components/dashboard/Footer";
import CustomerQuotationTable from "../../components/dashboard/tenders/CustomerQuotationTable";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CustomerQuotation = () => {
  const moduleName = "Create customer quotation";
  const query = useQuery();
  const tenderId = query.get("tenderId");
  const tenderName = query.get("tenderName");
  const bidId = parseInt(query.get("bidId"));
  const { loading, bidList, tenderViewData } = useSelector(
    (state) => state.tender
  );

  const getQuotationDetailsByBidId = (bidId) => {
    const companyQuotation = bidList.companyQuotations?.find(
      (quotation) => quotation.id === bidId
    );
    return companyQuotation;
  };

  const getCustomerQuotationDetails = (bidId) => {
    const companyQuotation = getQuotationDetailsByBidId(bidId);
    if (companyQuotation && companyQuotation.customer_quotation_id) {
      const customerQuotation = bidList.customerQuotations?.find(
        (quotation) => quotation.id === companyQuotation.customer_quotation_id
      );
      return customerQuotation || null;
    }
    return null;
  };

  const combinedQuotationDetails =
    getCustomerQuotationDetails(bidId) === null
      ? getQuotationDetailsByBidId(bidId)
      : getCustomerQuotationDetails(bidId);

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{ moduleName }</li>
            </ol>
            <h4 className="main-title mb-0">{ moduleName }</h4>
          </div>
          <div>
            <Link
              to={ `/tender-view?tenderId=${tenderId}&tenderName=${tenderName}` }
              className="btn btn-primary icon-with-btn"
            >
              <i className="ri-arrow-left-s-line"></i>{ " " }
              <span>Back to tender view</span>
            </Link>
          </div>
        </div>
        <div className="inner-wrapper">
          <CustomerQuotationTable
            selectedQuotation={ combinedQuotationDetails }
            bidId={ bidId }
            tenderData={ tenderViewData?.tender[0] }
          />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CustomerQuotation;
