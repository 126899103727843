import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Form, Button, Alert, Spinner } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UploadFile from "../../common/UploadFile";
import { TASK_STATUS_TYPE, ROLES } from "../../../utils/types";
import { CustomInput } from "../../../utils/utils";
import useAuth from "../../../hooks/useAuth";
import {
  fetchTaskList,
  createTask,
  updateTask,
} from "../../../store/actions/projectManageActions";
import { deleteBlobFile } from "../../../store/actions/fileActions";
import CustomSearchSelect from "../../common/CustomSearchSelect";

const TaskForm = ({
  isEdit,
  toggleFormModal,
  editData,
  projectId,
  projectName,
}) => {
  const isAuthenticated = useAuth();
  const dispatch = useDispatch();
  const { isLoading, projectTaskListData } = useSelector(
    (state) => state.projectManage
  );

  const projectList = projectTaskListData ? projectTaskListData.projects : [];
  const allUserList = projectTaskListData ? projectTaskListData.allUsers : [];

  const [selectedProjectOption, setSelectedProjectOption] = useState(null);

  const [selectedAssigneeOption, setSelectedAssigneeOption] = useState(
    editData?.assignee_id || ""
  );
  const userRoleNumber = parseInt(isAuthenticated?.user_role, 10);
  const isCompany = userRoleNumber === ROLES.COMPANY;
  const [attachmentDocName, setAttachmentDocName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      task_title: "",
      project_id: projectId || selectedProjectOption || "",
      assignee_id: selectedAssigneeOption,
      task_status_id: 4,
      task_closing_date: new Date(),
      task_description: "",
      attachments: "",
    },
  });

  useEffect(() => {
    setSelectedProjectOption([{ value: projectId, label: projectName }]);
  }, [projectId, projectName]);

  useEffect(() => {
    if (isEdit && editData) {
      setValue("task_title", editData.task_title);
      setValue("project_id", editData.project_id || projectId);
      setSelectedProjectOption({
        value: editData.project_id,
        label: projectName,
      });
      setValue("assignee_id", editData.assignee_id);
      setSelectedAssigneeOption({
        value: editData.assignee_id,
        label: `${editData.assignee.first_name} ${editData.assignee.last_name}`,
      });
      setValue("task_status_id", editData.task_status_id);
      setValue("task_closing_date", editData.task_closing_date);
      setValue("task_description", editData.task_description);
      setAttachmentDocName(editData.attachments || "");
    }
  }, [isEdit, editData, setValue]);

  const formReset = async () => {
    toggleFormModal();
    reset();
  };

  const getProjectOptions = () => {
    return projectList.map((project) => ({
      value: project.id,
      label: project.project_title,
    }));
  };

  const handleProjectChange = (selectedOption) => {
    setSelectedProjectOption(selectedOption);
    setValue("project_id", selectedOption.value);
  };

  const getAssigneeOptions = () => {
    return allUserList.map((person) => ({
      value: person.id,
      label: `${person.first_name} ${person.last_name}`,
    }));
  };

  const handleAssigneeChange = (selectedOption) => {
    setSelectedAssigneeOption(selectedOption);
    setValue("assignee_id", selectedOption.value);
  };

  const onSubmit = async (data) => {
    const dateString = data.task_closing_date;
    const dateObject = new Date(dateString);
    const formatClosingDate = dateObject
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    data.task_closing_date = formatClosingDate;
    data.attachments = attachmentDocName || "";

    if (!isEdit) {
      dispatch(createTask(data)).then(() => {
        dispatch(fetchTaskList());
        formReset();
      });
    } else {
      data.task_id = editData.id;
      dispatch(updateTask(data)).then(() => {
        dispatch(fetchTaskList());
        formReset();
      });
    }
  };

  //Delete uploaded file
  const handleDeleteBlob = async (blobName) => {
    try {
      setIsDeleting(true);
      await dispatch(deleteBlobFile({ blobName })).then((result) => {
        if (result.payload.success) {
          setAttachmentDocName("");
        }
      });
    } catch (error) {
      // Handle error (if needed)
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Form onSubmit={ handleSubmit(onSubmit) }>
      <Row>
        <div className="col-md-12 mb-3">
          <Form.Group>
            <Form.Label>Task title</Form.Label>
            <Form.Control
              type="text"
              { ...register("task_title", {
                required: "Task title is required",
              }) }
              disabled={ !isCompany || isLoading }
            />
            { errors.task_title && (
              <small className="text-danger">{ errors.task_title.message }</small>
            ) }
          </Form.Group>
        </div>
      </Row>
      <Row>
        <div className="col-md-6 mb-3">
          <Form.Group>
            <Form.Label>Select Project</Form.Label>
            <CustomSearchSelect
              options={ getProjectOptions() }
              selectedOption={ selectedProjectOption }
              handleChange={ handleProjectChange }
              isMultiValue={ false }
              disabled={ !isCompany || isLoading || Boolean(projectId) }
            />
            { errors.project_id && (
              <small className="text-danger">{ errors.project_id.message }</small>
            ) }
          </Form.Group>
        </div>
        <div className="col-md-6 mb-3">
          <Form.Group>
            <Form.Label>Select Assignee</Form.Label>
            <CustomSearchSelect
              options={ getAssigneeOptions() }
              selectedOption={ selectedAssigneeOption }
              handleChange={ handleAssigneeChange }
              isMultiValue={ false }
              disabled={ !isCompany || isLoading }
            />
            { errors.assignee_id && (
              <small className="text-danger">
                { errors.assignee_id.message }
              </small>
            ) }
          </Form.Group>
        </div>
      </Row>
      <Row>
        <div className="col-md-6 mb-3">
          <Form.Group>
            <Form.Label>Select Status</Form.Label>
            <Form.Control
              as="select"
              { ...register("task_status_id", {
                required: "Status is required",
              }) }
              disabled
            >
              <option value="">Select Status</option>
              { TASK_STATUS_TYPE.map((status) => (
                <option key={ status.id } value={ status.id }>
                  { status.name }
                </option>
              )) }
            </Form.Control>
            { errors.task_status_id && (
              <small className="text-danger">
                { errors.task_status_id.message }
              </small>
            ) }
          </Form.Group>
        </div>
        <div className="col-md-6 mb-3">
          <Form.Group>
            <Form.Label>Task due date</Form.Label>
            <Controller
              control={ control }
              name="task_closing_date"
              rules={ { required: "Task deadline is required" } }
              render={ ({ field }) => (
                <ReactDatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  minDate={ new Date() }
                  selected={ field.value }
                  onChange={ (date) => field.onChange(date) }
                  customInput={ <CustomInput /> }
                  disabled={ !isCompany || isLoading }
                />
              ) }
            />
            { errors.task_closing_date && (
              <small className="text-danger">
                { errors.task_closing_date.message }
              </small>
            ) }
          </Form.Group>
        </div>
      </Row>
      <Row>
        <div className="col-md-12 mb-3">
          <Form.Group>
            <Form.Label>Task description</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Task description"
              { ...register("task_description", {
                required: "Task description is required",
              }) }
              disabled={ !isCompany || isLoading }
            />
            { errors.task_description && (
              <small className="text-danger">
                { errors.task_description.message }
              </small>
            ) }
          </Form.Group>
        </div>
      </Row>
      <Row>
        <div className="col-md-12">
          { attachmentDocName ? (
            <>
              <Form.Label>Uploaded tender document</Form.Label>
              <Alert variant="secondary" className="d-flex align-items-center">
                <i className="ri-file-text-line"></i>
                <span className="me-auto">{ attachmentDocName }</span>

                <i
                  type="button"
                  onClick={ () => handleDeleteBlob(attachmentDocName) }
                  className={ `ri-delete-bin-5-line link-danger pe-auto ${isDeleting ? "disabled" : ""
                    }` }
                >
                  { isDeleting && (
                    <Spinner animation="border" size="sm" className="ms-2" />
                  ) }
                </i>
              </Alert>
            </>
          ) : (
            <UploadFile
              lableName="Upload tender document "
              uploadInfo="[Max file size 4Mb]"
              disabled={ isLoading || !isCompany }
              setFileName={ setAttachmentDocName }
              loading={ isLoading }
            />
          ) }
        </div>
      </Row>
      { isCompany && (
        <Row>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <Button
              type="button"
              variant="outline-primary"
              onClick={ toggleFormModal }
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={ !isCompany }>
              { isEdit ? "Update Task" : "Create Task" }
            </Button>
          </div>
        </Row>
      ) }
    </Form>
  );
};

export default TaskForm;
