import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../servces/Api";

//Fetch all dashboard data
export const fetchDashboardData = createAsyncThunk(
  "dashboard/dashboard-data",
  async (_, thunkApi) => {
    try {
      const response = await get("api/dashboard");
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return thunkApi.rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return thunkApi.rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);
