import { createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../servces/Api";
import Alert from "../../components/common/Alert";

// Action User Creat
export const userOnboard = createAsyncThunk("user/onboard", async (data) => {
  try {
    const response = await post("/api/do_onboard", data);
    if (response.status === 200) {
      if (response.data.user_role === "2" || response.data.user_role === "1") {
        Alert.success("Your onboarding process is successfully completed.");
      } else {
        Alert.success(
          "Your onboarding process is successfully completed. Your account will be approved by the system administrator very soon."
        );
      }
      return response.data;
    }
  } catch (error) {
    Alert.error("Onboarding process is failed. Please try again later.");
    return false;
  }
});
