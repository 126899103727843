import React, { useState, forwardRef, useEffect } from "react";
import {
  Button,
  Row,
  Form,
  Card,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import FormModal from "../../common/FormModal";
import { getUserProfileInfo } from "../../../store/actions/profileActions";
import UpdateProfile from "../../common/profile/UpdateProfile";

const UpdateUser = forwardRef(({ userId }, ref) => {
  const dispatch = useDispatch();
  const [showModalState, setShowModalState] = useState(false);
  const { profileData, isLoading, error } = useSelector(
    (state) => state.profile
  );

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const handleViewInfo = (userId) => {
    dispatch(getUserProfileInfo(userId)).then(() => {
      toggleFormModal();
    });
  };
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={ <Tooltip>Update user information</Tooltip> }
      >
        <div ref={ ref } style={ { display: "inline-block" } }>
          <Button
            variant="primary"
            size="sm"
            onClick={ () => {
              handleViewInfo(userId);
            } }
            className="mr-2 d-flex"
          >
            <i className="ri-edit-line"></i>
          </Button>
        </div>
      </OverlayTrigger>
      <FormModal
        moduleName={ "Update user information" }
        modalState={ showModalState }
        toggleFormModal={ toggleFormModal }
        width="600px"
      >
        <UpdateProfile profileData={ profileData } isLoading={ isLoading } onClose={ toggleFormModal } parent={ 'user_profile' } />
      </FormModal>
    </>
  );
});

export default UpdateUser;
