import React from "react";

import Footer from "../../components/dashboard/Footer";
const BlankPage = () => {
  const moduleName = "Blank Page";

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{ moduleName }</li>
            </ol>
            <h4 className="main-title mb-0">{ moduleName }</h4>
          </div>
          <div>{/* TODO: Added any acction buttons */ }</div>
        </div>
        <div className="inner-wrapper">
          {/* TO DO: Added any data component */ }
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlankPage;
