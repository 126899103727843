import React, { useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { Button, Form, Spinner } from "react-bootstrap";
import { getSasToken } from "../../utils/utils";
import Alert from "./Alert";

const UploadFile = ({
  setFileName,
  uploadInfo,
  disabled = false,
  lableName,
}) => {
  const sasToken = getSasToken();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedExtensions =
      /(\.pdf|\.png|\.jpg|\.jpeg|\.doc|\.docx|\.xlsx)$/i;

    if (file) {
      if (!allowedExtensions.exec(file.name)) {
        setUploadStatus(
          "Invalid file type. Only .pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx are allowed."
        );
        setSelectedFile(null);
      } else if (file.size > MAX_FILE_SIZE) {
        setUploadStatus("File size exceeds the limit of 5 MB.");
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
        setUploadStatus("");
      }
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setUploadStatus("No file selected");
      return;
    }

    const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME;
    const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;

    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobName = `${Date.now()}_${selectedFile.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    setLoading(true);

    try {
      await blockBlobClient.uploadBrowserData(selectedFile, {
        blobHTTPHeaders: { blobContentType: selectedFile.type },
      });
      setUploadStatus(`File uploaded successfully: ${blobName}`);
      Alert.success(`File uploaded successfully: ${blobName}`);
      setFileName(blobName);
    } catch (error) {
      setUploadStatus("File upload failed");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="col-md-12 mb-3">
      <div className="form-group">
        <Form.Label>
          { lableName } <small className="d-inline">{ uploadInfo }</small>
        </Form.Label>
        <div className="d-flex justify-content-between gap-2">
          <Form.Control
            type="file"
            accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx"
            className="form-control"
            onChange={ handleFileChange }
            disabled={ loading || disabled }
          />
          <Button
            variant="outline-primary"
            onClick={ handleFileUpload }
            disabled={ loading || disabled || !selectedFile }
            style={ { width: 160 } }
          >
            <>
              { loading && (
                <Spinner animation="border" size="sm" color="white" />
              ) }{ " " }
              Upload file
            </>
          </Button>
        </div>
        { uploadStatus && (
          <div className="mt-2">
            <small
              style={ {
                color: uploadStatus.includes("successfully") ? "green" : "red",
              } }
            >
              { uploadStatus }
            </small>
          </div>
        ) }
      </div>
    </div>
  );
};

export default UploadFile;
