import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../servces/Api";

export const getSidebarRoutes = createAsyncThunk(
  "getSidebarRoutes",
  async () => {
    const response = await get("/api/showMenuAccessByRole");
    const data = await response.data;
    if (response.status === 200) {
      return data;
    } else {
      return { err: "something went wrong" };
    }
  }
);

const initialState = {
  data: {
    memu_category: [],
    menus: [],
  },
  loading: "idle",
  error: null,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSidebarRoutes.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getSidebarRoutes.fulfilled, (state, action) => {
        if (action.payload.err) {
          state.loading = "failed";
        } else {
          state.loading = "completed";
          state.data = action.payload;
        }
      })
      .addCase(getSidebarRoutes.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      });
  },
});

export default sidebarSlice.reducer;
