import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Sidebar from "../components/dashboard/Sidebar";
import useAuth from "../hooks/useAuth";
import Header from "../components/dashboard/Header";

const DashboardLayout = () => {
  const isAuthenticated = useAuth();

  const location = useLocation();
  if (location.pathname === "/accept_quotation") {
    localStorage.setItem("intendedUrl", location.pathname + location.search);
  }

  //Manage sidebar mobile and desktop
  const bc = document.body.classList;
  bc.remove("sidebar-show");
  window.scrollTo(0, 0);

  return isAuthenticated && isAuthenticated.is_onboard === 1 ? (
    <>
      { location.pathname !== "/upgrade-package" && <Sidebar /> }
      { location.pathname !== "/upgrade-package" && <Header sidebarShow={ true } />}
      <Outlet />
    </>
  ) : (
    <Navigate to="/signin" />
  );
};

export default DashboardLayout;
