import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { userDowngradePackage } from '../store/actions/authActions';
import { setUserPackageNotExpired } from '../store/reducers/authSlice';
import { useNavigate } from "react-router-dom";

//images 
import bg from "../assets/img/bg/login.jpg";
import ShieldImg from "../assets/img/Shield.png";
import "../assets/styles/components/common/PackageTermination.scss";

const PackageTermination = () => {
  const package_id = useSelector((state) => state.auth.userInfo.package_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="page-sign" style={{ backgroundImage: `url(${bg})` }}>
        <Card>
          <Card.Body>
            <div className='conatiner'>
              <img className='main-image' src={ShieldImg} alt='shield-img' />
              <p className='main-text'>Your {package_id === 1 ? "Free trial" : "Subscription"} is expired!</p>
              <div className='desc-container'>
                <p>Hi there, your package subscription is expired! You can upgrade it by clicking the options below or contact us for further infromation</p>
              </div>
              <div className='btn-container'>
                <button
                  onClick={() => {
                    navigate("/pricing_packages");
                  }}
                  className='btn'
                >
                  <i style={{ fontSize: "14px" }} className="ri-refresh-line"></i>Upgrade now
                </button>
                <div className='text'>Or</div>
                <button
                  onClick={() => {
                    dispatch(userDowngradePackage())
                      .then(() => {
                        navigate("/dashboard");
                        dispatch(setUserPackageNotExpired());
                      })
                      .catch((error) => {
                        console.error("Upgrade failed:", error);
                      });
                  }}
                  className='btn'
                >
                  <i style={{ fontSize: "14px" }} className="ri-arrow-down-line"></i>
                  {package_id === 1 ? "Continue" : "Downgrade to"} free plan
                </button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default PackageTermination;
