import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { LOGOUT_USER } from "../../utils/types";
import { logoutUser } from "../../store/actions/authActions";
import userAvatar from "../../assets/img/user.png";
import useAuth from "../../hooks/useAuth";
import useUserRole from "../../hooks/getUserRole";
import { store, persistor } from "../../store/store";
import { post, get } from "../../servces/Api";
import { useSelector } from "react-redux";
import pusher from "../../pusherConfig";
import Alert from "../common/Alert";
import { Badge } from "react-bootstrap";
import { Alert as AlertBoosstrap } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import FormModal from "../common/FormModal";
import CustomerSupportForm from "../customer-support/CustomerSupportForm";
import NotificationLoader from "../common/NotificationLoader";

const Header = ({ sidebarShow = true }) => {
  const dispatch = useDispatch();
  const paragraphRef = useRef(null);
  const dropdownRef = useRef(null);
  const hasLoadedNotifications = useRef(false);
  const navigate = useNavigate();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const UserId = useSelector((state) => state.auth.userInfo.id);
  const UserData = useSelector((state) => state.auth.userInfo);
  const isAuthenticated = useAuth();
  const { userRoleName } = useUserRole(isAuthenticated.user_role);
  const [notifications, setNotifications] = useState([]);
  const [notificatioList, setNotificatioList] = useState([]);
  const [timeLeft, setTimeLeft] = useState({});
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalLength, setTotalLength] = useState(0);

  const toggleFormModal = () => {
    setShowModal(!showModal);
  };

  const getTimeLeft = useCallback((packageEndDate) => {
    const now = new Date();
    const endDate = new Date(packageEndDate);
    const timeDifference = endDate - now;

    if (!packageEndDate) {
      return "";
    }

    if (timeDifference <= 0) {
      return { type: "", message: "Package Expired !" };
    }

    const seconds = Math.floor((timeDifference / 1000) % 60);
    const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (days < 1) {
      return { type: "countdown", hours, minutes, seconds, show: true };
    } else if (days < 7) {
      return { type: "days", message: ` ${days} days`, show: true };
    }
  }, []);

  const updateTimeLeft = useCallback(() => {
    const newTimeLeft = getTimeLeft(UserData?.package_end_date);
    if (JSON.stringify(newTimeLeft) !== JSON.stringify(timeLeft)) {
      setTimeLeft(newTimeLeft);
    }
  }, [UserData, timeLeft, getTimeLeft]);

  useEffect(() => {
    updateTimeLeft();
    const interval = setInterval(updateTimeLeft, 1000);
    return () => clearInterval(interval);
  }, [updateTimeLeft]);

  useEffect(() => {
    const channel = pusher.subscribe("notification-channel");
    channel.bind("new-notification", (data) => {
      const notification = data.notifications;
      if ( notification?.sender && UserId === notification?.receiver_id) {
        setNotifications((prevNotifications) => [
          notification,
          ...prevNotifications,
        ]);
        setTotalLength((prevLength) => prevLength + 1);
      }
    });
    return () => {
      channel.unbind();
      pusher.unsubscribe("notification-channel");
    };
  }, []);

  useEffect(()=>{
    if (UserId && !hasLoadedNotifications.current) {
      fetchNotifications();
      hasLoadedNotifications.current = true;
    }
  }, [UserId])

  useEffect(() => {
    if (sidebarShow) {
      document.body.classList.add("sidebar-show");
    } else {
      document.body.classList.add("sidebar-hide");
    }
  }, [sidebarShow]);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await post(`/api/load_notification`);
      if (response.status === 200) {
        setNotifications(response.data.notifications.data);
        setNotificatioList(response.data.notifications);
        setTotalLength(response.data?.notifications?.total);
        if (response.data.notifications.data?.length > 0) {
          const urlForNext = response.data?.notifications?.links.find(
            (item) => item.label === "Next &raquo;"
          )?.url;
          if (urlForNext) {
            const baseUrl = process.env.REACT_APP_API_ENDPOINT;
            const relativeUrl = urlForNext?.replace(baseUrl, "");
            setNextPageUrl(relativeUrl);
          }
        }
      } else {
        Alert.error("Unable to get notifications!");
      }
    } catch (error) {
      Alert.error("Unable to get notifications!");
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreNotifications = async () => {
    if (!nextPageUrl) return;

    try {
      setLoadingMore(true);
      const response = await post(nextPageUrl);
      if (response.status === 200) {
        const newNotifications = response.data.notifications.data;
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...newNotifications,
        ]);
        setNotificatioList((prevNotificationList) => ({
          ...prevNotificationList,
          data: [...prevNotificationList.data, ...newNotifications],
        }));
        const nextPageLink = response.data.notifications.links.find(
          (link) => link.label === "Next &raquo;"
        );
        setNextPageUrl(nextPageLink ? nextPageLink.url : null);
        setLoadingMore(false);
      }
    } catch (error) {
      setLoadingMore(false);
      Alert.error("Unable to get more notifications!");
    }
  };

  useEffect(() => {
    const checkOverflow = () => {
      const paragraph = paragraphRef.current;
      if (paragraph) {
        const totalHeight = paragraph.scrollHeight;
        const maxHeight =
          parseFloat(getComputedStyle(paragraph).lineHeight) * 2;
        setIsOverflowing(totalHeight > maxHeight);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  function formatDateTime(dateString) {
    const date = new Date(dateString);

    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const dateOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    const formattedDate = date.toLocaleDateString("en-CA", dateOptions);

    return `${formattedDate} ${formattedTime}`;
  }

  function NotificationList() {
    function getInitials(name) {
      const words = name.split(" ");

      let initials = "";
      if (words.length > 0) initials += words[0][0];
      if (words.length > 1) initials += words[1][0];

      return initials.toUpperCase();
    }

    function getStatus(data) {
      const status = data?.replace("Task", "").trim();
      return status;
    }

    const setReadNotifictaion = async (item) => {
      setLoading(true);
      const response = await get(`/api/clear_notification/` + item.id);
      if (response.status === 200) {
        // navigate(`/${item.url}`);
        fetchNotifications();
      } else {
        Alert.error("Unable to get notifications!");
        setLoading(false);
      }
    };
    const notiList =
      notifications &&
      notifications.map((item, key) => {
        return (
          <li
            onClick={() => setReadNotifictaion(item)}
            className="list-group-item"
            key={key}
          >
            <div
              // className={item.status === "online" ? "avatar online" : "avatar"}
              className="avatar"
            >
              {getInitials(
                `${item?.sender?.first_name} ${item?.sender?.last_name}`
              )}
            </div>
            <div className="list-group-body">
              <p
                ref={paragraphRef}
                className={isOverflowing ? "p-overflow" : ""}
              >
                {item?.sender?.first_name} {item.sender?.last_name} change the
                status of {item?.message} to{" "}
                {getStatus(item?.notification_type?.type_name)}
              </p>
              <span>{formatDateTime(item?.created_at)}</span>
            </div>
            {!item?.sender_id && (
              <Badge className="badge" bg="warning" text="dark">
                System
              </Badge>
            )}
          </li>
        );
      });

    if (notifications.length > 0) {
      return (
        <InfiniteScroll
          dataLength={totalLength}
          next={() => fetchMoreNotifications()}
          hasMore={totalLength > notifications.length}
          scrollableTarget="scrollableDropdownMenu"
        >
          <ul id="scrollableDropdownMenu" className="list-group">
            {loading ? <NotificationLoader divCount={6} /> : notiList}
            {loadingMore && (
              <div className="loader">
                <div
                  class="spinner-grow spinner-grow-sm text-secondary"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </ul>
        </InfiniteScroll>
      );
    } else {
      return (
        <ul className="list-group-empty">
          No new notifications at the moment.
        </ul>
      );
    }
  }

  const handleLogout = async () => {
    dispatch(logoutUser());
    store.dispatch({ type: LOGOUT_USER });
    persistor.purge();
    await localStorage.setItem("logout", true);
  };

  const renderTimeLeft = () => {
    if (timeLeft.type === "countdown") {
      return `${timeLeft.hours ? timeLeft.hours : ""} ${timeLeft.hours ? "Hrs : " : ""
        } ${timeLeft.minutes ? timeLeft.minutes : ""} ${timeLeft.minutes ? "Mins : " : ""
        } ${timeLeft.seconds} Secs`;
    } else {
      return timeLeft.message;
    }
  };

  return (
    <div className="header-main px-3 px-lg-4">
      {sidebarShow && (
        <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
          <i className="ri-menu-2-fill"></i>
        </Link>
      )}

      <div className="me-auto"></div>

      {isAuthenticated &&
        isAuthenticated.is_onboard === 1 &&
        UserData.user_role !== "2" &&
        UserData?.package_end_date &&
        timeLeft?.show && (
          <AlertBoosstrap
            style={{ marginTop: "20px" }}
            variant="warning"
            dismissible
          >
            <span>Remaining Time for Current Package : </span>&nbsp;
            <strong>{renderTimeLeft()}, </strong>
            <Link to="/pricing_packages" style={{ fontSize: "13px" }}>
              Click Here
            </Link>
            &nbsp;
            <span>to Upgrade.</span>
          </AlertBoosstrap>
        )}

      {isAuthenticated && isAuthenticated.is_onboard === 1 && (
        <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
          <Dropdown.Toggle as={CustomToggle}>
            {notificatioList.total > 0 && (
              <small>{totalLength}</small>
            )}
            <i className="ri-notification-3-line"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="mt-10-f me--10-f" ref={dropdownRef}>
            <div className="dropdown-menu-header">
              <h6 className="dropdown-menu-title">Notifications</h6>
            </div>
            {NotificationList()}
          </Dropdown.Menu>
        </Dropdown>
      )}

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        {isAuthenticated && (
          <Dropdown.Menu className="mt-10-f">
            <div className="dropdown-menu-body">
              <div className="avatar avatar-xl online mb-3">
                <img src={userAvatar} alt="" />
              </div>
              <h5 className="mb-1 text-dark fw-semibold">{`${isAuthenticated?.first_name} ${isAuthenticated.last_name}`}</h5>
              <p className="fs-sm text-secondary">{userRoleName}</p>
              {isAuthenticated.is_onboard === 1 && (
                <>
                  <nav className="nav">
                    <Link to="/my_profile">
                      <i className="ri-profile-line"></i> My Profile
                    </Link>
                  </nav>
                  <hr />
                  {/* <nav className="nav">
                    <Link to="">
                      <i className="ri-lock-line"></i> Privacy Settings
                    </Link>
                    <Link to="">
                      <i className="ri-user-settings-line"></i> Account Settings
                    </Link>
                  </nav> */}
                </>
              )}
              <nav className="nav">
                <Link to="#" onClick={toggleFormModal}>
                  <i className="ri-question-line"></i> Help Center
                </Link>
                <Link to="#" onClick={handleLogout}>
                  <i className="ri-logout-box-r-line"></i> Log Out
                </Link>
              </nav>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>

      <FormModal
        id="customerSupportFormModal"
        modalState={showModal}
        moduleName="Customer Support"
        toggleFormModal={toggleFormModal}
        size="lg"
        staticBackdrop={true}
        noHeader={false}
      >
        <CustomerSupportForm toggleFormModal={toggleFormModal} />
      </FormModal>
    </div>
  );
};

export default Header;
