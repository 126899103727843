import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BlobServiceClient } from "@azure/storage-blob";

import PaginationWithDataTable from "../../common/PagintionWithDataTable";
import { getSasToken } from "../../../utils/utils";
import Alert from "../../common/Alert";
import SaveCustomerQuotationData from "./SaveCustomerQuotationData";
import SendCustomerQuotation from "./SendCustomerQuotation";
import HeaderImg from "../../../assets/img/default-banner-img.jpg";

const CustomerQuotationTable = ({ tenderData, selectedQuotation, bidId }) => {
  const sasToken = getSasToken();
  const [rows, setRows] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [quotatonFileName, setQuotatonFileName] = useState(
    localStorage.getItem("quotationFileName" || "")
  );
  const [emailAttachment, setEmailAttachment] = useState(
    localStorage.getItem("quotationFile" || "")
  );

  useEffect(() => {
    const initialRows = selectedQuotation.quotation_details.map((detail) => ({
      id: detail.id,
      item_name: detail.item_name,
      quantity: detail.quantity,
      price: detail.price,
      after_price:
        detail.after_price != null ? detail.after_price : detail.price,
    }));
    setRows(initialRows);
    updateTotalPrice(initialRows);
  }, [selectedQuotation]);

  const handleQuotationPriceChange = (id, after_price) => {
    const formattedPrice = after_price;
    setChangePriceValue(id, formattedPrice);
  };

  const changeNumberFormet = (id, after_price) => {
    const formattedPrice = parseFloat(after_price).toFixed(2);
    setChangePriceValue(id, formattedPrice);
  };

  const setChangePriceValue = (id, formattedPrice) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, after_price: formattedPrice } : row
    );
    setRows(updatedRows);
    updateTotalPrice(updatedRows);
  };

  const updateTotalPrice = (rows) => {
    const total = rows.reduce(
      (acc, row) => acc + (Number(row.after_price) * Number(row.quantity) || 0),
      0
    );
    setTotalPrice(total);
  };

  const generatePDF = async () => {
    const doc = new jsPDF("p", "mm", "a4"); // A4 size
    const imgWidth = doc.internal.pageSize.getWidth();
    const imgHeight = 40; // Set the height of the image as needed

    // Add letterhead
    const imgData = HeaderImg; // Letterhead image
    doc.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

    // Add text on the header image
    doc.setFontSize(14);
    doc.setTextColor(255, 255, 255);
    doc.text(`${tenderData?.com_name}`, 14, 12);
    doc.setFontSize(12);
    doc.text(
      `${tenderData?.address1 || ""} ${tenderData?.address2 || ""} ${
        tenderData?.address3 || ""
      } ${tenderData?.region || ""} ${tenderData?.country || ""}`,
      14,
      18
    );
    doc.text(`Phone: ${tenderData?.contact}`, 14, 24);
    doc.text(`Email: info@company.com`, 14, 30);

    // Customer info
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.text(`${tenderData?.customer_name}`, 14, 56);
    doc.text(`Email: ${tenderData?.customer_email}`, 14, 62);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 14, 68);

    // Quotation title
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("Quotation Details", doc.internal.pageSize.getWidth() / 2, 90, {
      align: "center",
    });

    // Content below the header
    const tableColumn = ["Item Name", "Qty", "Price (1 item)", "Total price"];
    const tableRows = rows.map((row) => [
      row.item_name,
      row.quantity,
      `$${parseFloat(row.after_price).toFixed(2)}`,
      `$${parseFloat(row.after_price * row.quantity).toFixed(2)}`,
    ]);

    // Add a total price row at the end of the table
    const totalPriceRow = ["Total Price", "", "", `$${totalPrice.toFixed(2)}`];
    tableRows.push(totalPriceRow);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 96,
      theme: "grid",
      headStyles: { fillColor: [35, 35, 64] },
      styles: { cellPadding: 2, fontSize: 12 },
      didParseCell: (data) => {
        // Check if it's the Price (1 item) or Total price column
        const isPriceColumn = data.column.index === 2;
        const isTotalPriceColumn = data.column.index === 3;

        if (isPriceColumn || isTotalPriceColumn) {
          data.cell.styles.halign = "right";
        }
      },
      willDrawCell: (data) => {
        // Check if it's the last row (total price row)
        const isLastRow = data.row.index === tableRows.length - 1;
        if (isLastRow) {
          doc.setFont("helvetica", "bold");
        }
      },
      didDrawPage: (data) => {
        // Add a line at the bottom of the page
        const pageHeight = doc.internal.pageSize.height;
        doc.setFillColor(35, 35, 64);
        doc.rect(0, pageHeight - 10, doc.internal.pageSize.getWidth(), 10, "F");

        // Add text above the line
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(10);
        doc.text(
          "© 2024. Nordic Field Services. All Rights Reserved.",
          doc.internal.pageSize.getWidth() / 2,
          pageHeight - 4,
          { align: "center" }
        );
      },
    });

    // Note
    doc.setFontSize(12);
    const noteText = `We hereby submit our quotation for your consideration.`;
    const splitNote = doc.splitTextToSize(noteText, 180);
    doc.text(splitNote, 14, doc.autoTable.previous.finalY + 20);

    doc.setFontSize(12);
    const thankMsg = `Thank you.`;
    doc.text(thankMsg, 14, doc.autoTable.previous.finalY + 26);

    // Create a Blob from the PDF
    const blob = doc.output("blob");

    // Convert Blob to base64
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result.split(",")[1];
      localStorage.setItem("quotationFile", base64data);
      setEmailAttachment(base64data);
    };

    await uploadQuotation(blob);
  };

  //save pdf Azure BLob
  const uploadQuotation = async (blob) => {
    const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME;
    const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;

    // Get the current date and time
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    const formattedTime = currentDate
      .toISOString()
      .split("T")[1]
      .split(".")[0]
      .replace(/:/g, "-"); // Format time as HH-MM-SS

    // Create the blob name with the date and time
    const blobName = `quotation_${formattedDate}_${formattedTime}.pdf`;

    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    try {
      await blockBlobClient.uploadData(blob, blob.size);
      localStorage.setItem("quotationFileName", blobName);
      setQuotatonFileName(blobName);
      Alert.success("File uploaded successfully");
    } catch (error) {
      Alert.error("Failed to upload file", error);
    }
  };

  const pdfGenerateAndUpload = async () => {
    await generatePDF();
  };

  const columns = [
    {
      name: "Item name",
      selector: (row) => row.item_name,
    },
    {
      name: "Qty",
      selector: (row) => row.quantity,
      width: "100px",
    },
    {
      name: "Price (1 item)",
      selector: (row) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(row.price),
      width: "150px",
    },
    {
      name: "Selling Price (1 item)",
      selector: (row) => (
        <div
          style={{ textAlign: "right", display: "flex", alignItems: "center" }}
        >
          <span style={{ marginRight: "5px" }}>$</span>
          <input
            type="number"
            className="form-control"
            value={row.after_price}
            onChange={(e) => handleQuotationPriceChange(row.id, e.target.value)}
            style={{ width: "100%" }}
            onBlur={(e) => changeNumberFormet(row.id, e.target.value)}
            disabled={selectedQuotation?.is_sent_quotation}
          />
        </div>
      ),
      width: "200px",
    },
    {
      name: "Total price",
      selector: (row) => {
        const totalPrice = row.after_price * row.quantity;
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(totalPrice);
      },
      width: "150px",
    },
  ];

  return (
    <>
      <PaginationWithDataTable
        dataColumns={columns}
        entities={rows}
        loading={false}
        pagination={false}
      />
      <div
        className="d-flex justify-content-end p-3 bg-gray-200 border border-top-0"
        style={{ fontSize: 20 }}
      >
        <strong className="flex-grow-1">Total Price: </strong>
        <strong>{`$${totalPrice.toFixed(2)}`}</strong>
      </div>

      <div className="d-flex justify-content-end gap-2 mt-4">
        <SaveCustomerQuotationData
          generateQuotation={pdfGenerateAndUpload}
          quotatonFileName={quotatonFileName}
          tenderData={tenderData}
          totalPrice={totalPrice}
          quotationData={rows}
          bidId={bidId}
          is_sent_quotation={selectedQuotation?.is_sent_quotation}
        />
        <SendCustomerQuotation
          emailAttachment={emailAttachment}
          quotatonFileName={quotatonFileName}
          tenderData={tenderData}
          is_sent_quotation={selectedQuotation?.is_sent_quotation}
          generateQuotation={pdfGenerateAndUpload}
        />
      </div>
    </>
  );
};

export default CustomerQuotationTable;
