import { createSlice } from "@reduxjs/toolkit";
import {
    CustomerSupport
} from "../actions/customerSupportActions";

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const customerSupportSlice = createSlice({
    name: "cutomerSupport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //reject
            .addCase(CustomerSupport.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(CustomerSupport.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(CustomerSupport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default customerSupportSlice.reducer;
