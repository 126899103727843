import React, { useState, useEffect, useCallback } from 'react';
import Footer from '../../components/dashboard/Footer';
import { get, post } from '../../servces/Api';
import Alert from '../../components/common/Alert';
import { Row, Table, Spinner } from 'react-bootstrap';
import TableLoader from '../../components/common/TableLoader';
import { useForm, Controller, useWatch } from 'react-hook-form';
import "../../assets/styles/pages/SubscriptionPackages.scss";

function SubscriptionPackges() {
  const moduleName = "Package Limitations";
  const [selectedPricingPackges, setSelectedPricingPackges] = useState([]);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    getPackages();
  }, []);

  const getPackages = async () => {
    setLoading(true);
    try {
      const response = await get("/api/load_packages");
      if (response.status === 200) {
        const packages = response.data.packages;
        const filteredPackages = packages.filter(pkg => [1, 2, 3, 4].includes(pkg.id));
        setSelectedPricingPackges(filteredPackages);
        if (filteredPackages.length > 0) {
          const features = Object.keys(filteredPackages[0]).filter(
            (key) => key !== "id" && key !== "name"
          );
          setFeatures(features);
        }
        setLoading(false);
      } else {
        Alert.error(response.error);
        setLoading(false);
      }
    } catch (error) {
      Alert.error("Unable to get packges list!");
      setLoading(false);
    }
  };

  const { handleSubmit, control, getValues, reset, watch, formState: { errors } } = useForm({
    defaultValues: { packages: selectedPricingPackges },
  });

  useEffect(() => {
    reset({ packages: selectedPricingPackges });
  }, [selectedPricingPackges, reset]);

  const watchedValues = useWatch({ control, name: "packages" });

  const isSaveButtonDisabled = useCallback(() => {
    const currentValues = getValues("packages");
    return JSON.stringify(currentValues) === JSON.stringify(selectedPricingPackges);
  }, [getValues, selectedPricingPackges]);

  const onSubmit = async (formData) => {
    setLoadingSubmit(true);
    try {
      const response = await post("/api/update_package_limits", formData);
      if (response.status === 200) {
        Alert.success("Packages Updated Successfully");
        const packages = response.data.packages;
        const filteredPackages = packages.filter(pkg => [1, 2, 3, 4].includes(pkg.id));
        setSelectedPricingPackges(filteredPackages);
        if (filteredPackages.length > 0) {
          const features = Object.keys(filteredPackages[0]).filter(
            (key) => key !== "id" && key !== "name"
          );
          setFeatures(features);
        }
        setLoadingSubmit(false);
      } else {
        Alert.error(response.error);
        setLoadingSubmit(false);
      }
    } catch (error) {
      Alert.error("Unable to update packges!");
      setLoadingSubmit(false);
    }
  };

  const handleReset = () => {
    getPackages();
  }

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{ moduleName }</li>
            </ol>
            <h4 className="main-title mb-0">{ moduleName }</h4>
          </div>
          <div></div>
        </div>
        <Row className="justify-content-center">
          <div className="container">
            <form onSubmit={ handleSubmit(onSubmit) }>
              { !loading ? (
                <Table bordered striped className="mb-4">
                  <thead>
                    <tr>
                      <th style={ { width: '30%' } }>Feature</th>
                      <th>Free Trial</th>
                      <th>Starter</th>
                      <th>Business</th>
                      <th>Enterprise</th>
                      {/* {selectedPricingPackges && selectedPricingPackges.map((pkg) => (
                      <th key={pkg.id}>{pkg.name}</th>
                    ))} */}
                    </tr>
                  </thead>
                  <tbody>
                    { features.map((feature) => (
                      <tr key={ feature }>
                        <td style={ { width: '30%' } }>
                          { feature
                            .replace("_", " ")
                            .replace(/\b\w/g, (l) => l.toUpperCase()) }
                        </td>
                        { selectedPricingPackges && selectedPricingPackges.map((pkg, pkgIndex) => (
                          <td key={ pkg.id }>
                            <Controller
                              name={ `packages[${pkgIndex}].${feature}` }
                              control={ control }
                              defaultValue={ pkg[feature] !== null ? pkg[feature] : "Unlimited" }
                              rules={ {
                                required: 'This field is required',
                                validate: {
                                  isNumber: (value) => !isNaN(value) || 'Must be a number',
                                  isNonNegative: (value) => value >= 0 || 'Must be a non-negative number',
                                },
                                setValueAs: (value) => value !== "" ? parseFloat(value) : null
                              } }
                              render={ ({ field }) => (
                                <div>
                                  <input
                                    { ...field }
                                    type="number"
                                    className={ errors?.packages?.[pkgIndex]?.[feature] ? "inputError" : '' }
                                  />
                                  { errors?.packages?.[pkgIndex]?.[feature] && (
                                    <p className="errorMessage">
                                      { errors.packages[pkgIndex][feature].message }
                                    </p>
                                  ) }
                                </div>
                              ) }
                            />
                          </td>
                        )) }
                      </tr>
                    )) }
                  </tbody>
                </Table>
              ) : (
                <TableLoader rowCount={ 3 } columnCount={ 5 } />
              )
              }
              <div className='btn-container'>
                <button type='submit' disabled={ isSaveButtonDisabled() || loading || loadingSubmit }>
                  { loadingSubmit && <Spinner animation="border" size="sm" /> }{ " " }Save
                </button>
                <button type='button' disabled={ isSaveButtonDisabled() || loading } onClick={ handleReset }>Cancel</button>
              </div>
            </form>
          </div>
        </Row>
        <Footer />
      </div>
    </>
  );
}

export default SubscriptionPackges;
