import React, { useState, useEffect } from "react";
import { Button, Tooltip, OverlayTrigger, Row, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "../../common/FormModal";
import LimitationExceedModal from "../../common/LimitationExceedModal";
import {
  registerUser,
  emailVerificationLink,
} from "../../../store/actions/authActions";

const CreateNewUser = ({ companyList, userRoleList }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.auth.userInfo);
  const companyUsersData = useSelector((state) => state.usersManage.data.userdet);
  const [showModalState, setShowModalState] = useState(false);
  const [showExceedPackageModalState, setShowExceedPackageModalState] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const selectedUserRole = watch("user_role_id");

  useEffect(() => {
    const filteredUsers = companyUsersData && companyUsersData.filter(user => user.status === 1);
    setFilteredUsers(filteredUsers);
  }, [companyUsersData]);

  const handleUserCreate = () => {
    const usersLimit = loggedUser?.package_detais?.user_limit;
    if(filteredUsers?.length >= usersLimit && loggedUser.user_role !== "1"){
      showExceedPackageModal()
    }else{
      toggleFormModal()
    }
  }

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
    reset();
  };

  const closeExceedPackageModal = () => {
    setShowExceedPackageModalState(false)
  }
  const showExceedPackageModal = () => {
    setShowExceedPackageModalState(true)
  }

  const onSubmit = async (data) => {
    const email = data.email.toLowerCase();
    if (selectedUserRole === "3") {
      data.company_id = 0;
    }
    await dispatch(registerUser({ ...data, email })).then((response) => {
      if (response.payload === "save successfully") {
        sendEmailVerifiLink(email);
      }
    });
  };

  const sendEmailVerifiLink = async (email) => {
    await dispatch(emailVerificationLink({ email })).then((response) => {
      if (response.payload.status === "success") {
        toggleFormModal();
      }
    });
  };

  return (
    <>
      <OverlayTrigger placement="left" overlay={<Tooltip>Create User</Tooltip>}>
        <Button variant="secondary" onClick={handleUserCreate}>
          <i className="ri-user-add-line"></i> Create User
        </Button>
      </OverlayTrigger>
      <FormModal
        moduleName={"Create new user"}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="600px"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  id="first_name"
                  {...register("first_name", {
                    required: "First name is required",
                  })}
                  disabled={loading}
                />
                {errors.first_name && (
                  <small className="text-danger">
                    {errors.first_name.message}
                  </small>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  id="last_name"
                  {...register("last_name", {
                    required: "Last name is required",
                  })}
                  disabled={loading}
                />
                {errors.last_name && (
                  <small className="text-danger">
                    {errors.last_name.message}
                  </small>
                )}
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-6 mb-3">
              <Form.Group>
                <Form.Label>User role</Form.Label>
                <Form.Control
                  className="form-select"
                  as="select"
                  name="user_role_id"
                  {...register("user_role_id", {
                    required: "User Role is required",
                  })}
                  disabled={loading}
                >
                  <option value="">Select user role</option>
                  {userRoleList &&
                    userRoleList.map((userRole) => (
                      <option key={userRole.id} value={userRole.id}>
                        {userRole.role_name}
                      </option>
                    ))}
                </Form.Control>
                {errors.user_role_id && (
                  <small className="text-danger">
                    {errors.user_role_id.message}
                  </small>
                )}
              </Form.Group>
            </div>
            {selectedUserRole !== "3" && (
              <div className="col-md-6 mb-3">
                <Form.Group>
                  <Form.Label>Company</Form.Label>
                  <Form.Select
                    name="company_id"
                    {...register("company_id", {
                      required: "Company is required",
                    })}
                    disabled={loading}
                  >
                    <option value="">Select company</option>
                    {companyList &&
                      companyList.map((company) => (
                        <option key={company.id} value={company.id}>
                          {company.com_name}
                        </option>
                      ))}
                  </Form.Select>
                  {errors.company_id && (
                    <small className="text-danger">
                      {errors.company_id.message}
                    </small>
                  )}
                </Form.Group>
              </div>
            )}
            <div
              className={
                selectedUserRole !== "3" ? "col-md-12 mb-3" : "col-md-6 mb-3"
              }
            >
              <div className="form-group">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  className="form-input"
                  id="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Please enter a valid email address",
                    },
                  })}
                  disabled={loading}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </div>
            </div>
          </Row>

          <Row>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  className="form-input"
                  {...register("password", {
                    required: "Password is required",
                  })}
                  disabled={loading}
                />
                {errors.password && (
                  <small className="text-danger">
                    {errors.password.message}
                  </small>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <Form.Label>Confirm password</Form.Label>
                <Form.Control
                  type="password"
                  className="form-input"
                  {...register("r_password", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === watch("password") ||
                      "The passwords do not match",
                  })}
                  disabled={loading}
                />
                {errors.r_password && (
                  <small className="text-danger">
                    {errors.r_password.message}
                  </small>
                )}
              </div>
            </div>
          </Row>

          <div className="d-flex flex-wrap gap-2">
            <Button
              type="button"
              variant="outline-primary"
              className="btn-sign"
              onClick={() => {
                toggleFormModal();
              }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="btn-sign"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Create Account
                </>
              ) : (
                "Create Account"
              )}
            </Button>
          </div>
        </Form>
      </FormModal>
      <LimitationExceedModal 
        showViewModal={showExceedPackageModalState}
        closeModal={closeExceedPackageModal}
        name="user limit"
      />
    </>
  );
};

export default CreateNewUser;
