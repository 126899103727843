import { createSlice } from "@reduxjs/toolkit";
import {
  createTender,
  fetchTenders,
  updateTender,
  tenderView,
  fetchTendersForBided,
  addBidForTender,
  fetchBidListTender,
  saveCustomerQuotation,
  sendQuotationCustomer,
  customerRejectQuotation,
  acceptCustomerQuotation,
  fetchCustomerQuotation,
} from "../actions/tenderActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  createNewTender: null,
  tenderList: null,
  tenderUpdate: null,
  tenderViewData: null,
  tendersForbidedData: null,
  addBid: null,
  bidList: null,
  saveCustomerQuotationData: null,
  sendQuotationCustomerData: null,
  customerRejectQuotationData: null,
  acceptCustomerQuotationData: null,
  fetchCustomerQuotationData: null,
};

const tenderSlice = createSlice({
  name: "tender",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create tender
      .addCase(createTender.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createTender.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.createNewTender = action.payload;
      })
      .addCase(createTender.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      // Fetch tenders
      .addCase(fetchTenders.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchTenders.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.tenderList = action.payload;
      })
      .addCase(fetchTenders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      // Update tender
      .addCase(updateTender.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateTender.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.tenderUpdate = action.payload;
      })
      .addCase(updateTender.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      // get data one tender
      .addCase(tenderView.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(tenderView.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.tenderViewData = action.payload;
      })
      .addCase(tenderView.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      // get data tenders for bided
      .addCase(fetchTendersForBided.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchTendersForBided.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.tendersForbidedData = action.payload;
      })
      .addCase(fetchTendersForBided.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      // add bid for tender
      .addCase(addBidForTender.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addBidForTender.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.addBid = action.payload;
      })
      .addCase(addBidForTender.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      // get data Bid List Tender
      .addCase(fetchBidListTender.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchBidListTender.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.bidList = action.payload;
      })
      .addCase(fetchBidListTender.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      //Send quotation to customer
      .addCase(saveCustomerQuotation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(saveCustomerQuotation.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.saveCustomerQuotationData = action.payload;
      })
      .addCase(saveCustomerQuotation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      //Send quotation to customer
      .addCase(sendQuotationCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(sendQuotationCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.sendQuotationCustomerData = action.payload;
      })
      .addCase(sendQuotationCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      //Customer Quotatoin reject
      .addCase(customerRejectQuotation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(customerRejectQuotation.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.customerRejectQuotationData = action.payload;
      })
      .addCase(customerRejectQuotation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      //Customer Quotatoin accept
      .addCase(acceptCustomerQuotation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(acceptCustomerQuotation.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.acceptCustomerQuotationData = action.payload;
      })
      .addCase(acceptCustomerQuotation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      //Get save quotation and send quotaton data
      // Fetch tenders
      .addCase(fetchCustomerQuotation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchCustomerQuotation.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.fetchCustomerQuotationData = action.payload;
      })
      .addCase(fetchCustomerQuotation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      });
  },
});

export default tenderSlice.reducer;
