import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../servces/Api";
import Alert from "../../components/common/Alert";

//Fetch all tenders data
export const fetchProjectList = createAsyncThunk(
  "project/projectList",
  async (_, thunkApi) => {
    try {
      const response = await get("api/load_projects");

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return thunkApi.rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return thunkApi.rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);

//project view
export const fetchProjectInfo = createAsyncThunk(
  "project/projectInfo",
  async (projectId, thunkApi) => {
    try {
      const response = await get(`api/load_one_project/${projectId}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return thunkApi.rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return thunkApi.rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);
