import React from "react";
import MenuBar from "../components/home/MenuBar";
import Banner from "../components/home/Banner";
import MockupSection from "../components/home/MockupSection";
import CardList from "../components/common/CardList";
import ChooseWorkPreference from "../components/home/ChooseWorkPreference";
import TeamAcceleration from "../components/home/TeamAcceleration";
import FeedbackCarousel from "../components/home/FeedbackCarousel";
import ChooseYourPricePackage from "../components/home/ChooseYourPricePackage";
import Footer from "../components/home/Footer";
import { items, feedbackData } from "../data/HomeData";

const Home = () => {
  const ItemsPerPage = 3;

  const handleCardClick = (item) => {
    // Your custom logic when a card is clicked
  };

  return (
    <div className="page-home">
      <MenuBar />
      <Banner />
      <MockupSection />
      <CardList
        items={items}
        handleCardClick={handleCardClick}
        ItemsPerPage={ItemsPerPage}
        listTitle={"Chose Your Template"}
      />
      <ChooseWorkPreference />
      <TeamAcceleration />
      <ChooseYourPricePackage />
      <FeedbackCarousel feedbackData={feedbackData} />
      <Footer />
    </div>
  );
};

export default Home;
