import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function NotificationLoader({ divCount }) {
    return (
        <SkeletonTheme baseColor="#e2e5ec" highlightColor="#f3f5f9">
            {Array.from({ length: divCount }).map((_, index) => (
                <li className='p-1'>
                    <div key={index}>
                        <Skeleton height={50} />
                    </div>
                </li>
            ))}
        </SkeletonTheme>
    )
}

export default NotificationLoader