import { toast } from "react-hot-toast";

const alertSettings = {
  duration: null,
  position: "top-right",
  style: {
    background: "dark",
  },
};

const showToast = (type, msg) => {
  if (type.endsWith("_stick")) {
    toast.custom(createStickToast(msg), alertSettings);
  } else {
    toast[type](msg, alertSettings);
  }
};

const createStickToast = (msg) => (
  <div>
    {msg}
    <button onClick={() => toast.dismiss()}>Dismiss</button>
  </div>
);

const Alert = {
  info: (msg) => showToast("info", msg),
  success: (msg) => showToast("success", msg),
  warning: (msg) => showToast("loading", msg),
  error: (msg) => showToast("error", msg),
};

export default Alert;
