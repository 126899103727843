import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post, get } from "../../servces/Api";
import Alert from "../../components/common/Alert";

export const fetchUserRoles = createAsyncThunk(
  "permissions/fetchUserRoles",
  async () => {
    try {
      const response = await get("/api/permissions");
      return response.data.user_role;
    } catch (error) {
      console.error("Error loading", error);
      throw new Error("Failed to fetch permissions");
    }
  }
);

export const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async (userRoleId) => {
    try {
      const response = await get(`/api/get_load_permission/${userRoleId}`);
      return response.data;
    } catch (error) {
      console.error("Error loading", error);
      throw new Error("Failed to fetch permissions");
    }
  }
);

export const savePermissions = createAsyncThunk(
  "permissions/savePermissions",
  async (data) => {
    try {
      const response = await post(
        `/api/save_permissions/${data.selectedRoleId}`,
        data.modulePermissions
      );
      if (response.status === 200) {
        Alert.success("Permissions saved successfully!");
      } else {
        Alert.error("Failed to save permissions");
        throw new Error("Failed to save permissions");
      }
      return response.data;
    } catch (error) {
      console.error("Error loading", error);
      throw new Error("Failed to save permissions");
    }
  }
);

const initialState = {
  loading: false,
  success: false,
  error: null,
  userRoles: null,
  permissions: [],
  savePermissions: [],
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchUserRoles
      .addCase(fetchUserRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.userRoles = action.payload;
      })
      .addCase(fetchUserRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //fetchPermissions
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.permissions = action.payload;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //savePermissions
      .addCase(savePermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.savePermissions = action.payload;
      })
      .addCase(savePermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default permissionsSlice.reducer;
