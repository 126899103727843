// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.priceCard_card_pricing__D1Dre.priceCard_popular__RqLO6 {
  z-index: 1;
  border: 3px solid #007bff;
}

.priceCard_card_pricing__D1Dre .priceCard_list_unstyled__AUWQY {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.priceCard_card_pricing__D1Dre .priceCard_list_unstyled__AUWQY li {
  padding: 0.5rem 0;
  color: #6c757d;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/home/priceCard.module.scss"],"names":[],"mappings":"AACE;EACE,UAAA;EACA,yBAAA;AAAJ;;AAKE;EACE,gBAAA;EACA,cAAA;EACA,eAAA;AAFJ;AAGI;EACE,iBAAA;EACA,cAAA;AADN","sourcesContent":[".card_pricing {\n  &.popular {\n    z-index: 1;\n    border: 3px solid #007bff;\n  }\n}\n\n.card_pricing {\n  .list_unstyled {\n    list-style: none;\n    margin-left: 0;\n    padding-left: 0;\n    li {\n      padding: 0.5rem 0;\n      color: #6c757d;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card_pricing": `priceCard_card_pricing__D1Dre`,
	"popular": `priceCard_popular__RqLO6`,
	"list_unstyled": `priceCard_list_unstyled__AUWQY`
};
export default ___CSS_LOADER_EXPORT___;
