import React from "react";
import Footer from "../../components/dashboard/Footer";
import useAuth from "../../hooks/useAuth";
import useUserRole from "../../hooks/getUserRole";

//Dashboard components
import AdminDashboard from "../../components/dashboard/AdminDashboard";
import CustomerDashboard from "../../components/dashboard/CustomerDashboard.js";
import CompanyDashboard from "../../components/dashboard/CompanyDashboard";
import DeliveryDashboard from "../../components/dashboard/DeliveryDashboard";
import FieldDashboard from "../../components/dashboard/FieldDashboard";

const Dashboard = () => {
  const isAuthenticated = useAuth();
  const { userRoleId, userRoleName } = useUserRole(isAuthenticated.user_role);
  const first_name = isAuthenticated.first_name;
  const last_name = isAuthenticated.last_name;

  const selectDashboard = (userRoleName) => {
    switch (userRoleName) {
      case "ADMIN":
        return <AdminDashboard />;
      case "COMPANY":
        return <CompanyDashboard />;
      case "CUSTOMER":
        return <CustomerDashboard />;
      case "DELIVERY":
        return <DeliveryDashboard />;
      case "FIELD":
        return <FieldDashboard />;
      default:
        return <>no user role</>;
    }
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol>
            <h4 className="main-title mb-0">
              Welcome{" "}
              <strong>
                <span className="text-lowercase">
                  {first_name} {last_name}
                </span>
              </strong>{" "}
              !
            </h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            {/* <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
            >
              <i className="ri-share-line fs-18 lh-1"></i>Share
            </Button>
            <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
            >
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
              <span className="d-none d-sm-inline"> Report</span>
            </Button> */}
          </div>
        </div>
        <div className="inner-wrapper">
          {userRoleId && selectDashboard(userRoleName)}
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Dashboard;
