import React, { useEffect } from "react";
import { Row, Form } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";

const RegisteredInfoForm = ({ userRoleName, userinfo }) => {
  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setValue("first_name", userinfo?.first_name || "");
    setValue("last_name", userinfo?.last_name || "");
    setValue("email", userinfo?.email || "");
  }, [setValue, userinfo]);

  return (
    <>
      <Row>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              className="form-input"
              {...register("first_name")}
              required
              placeholder="First Name"
              disabled
            />
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              className="form-input"
              {...register("last_name")}
              required
              placeholder="Last Name"
              disabled
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="form-group mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            className="form-input"
            {...register("email")}
            required
            placeholder="Email"
            disabled
          />
        </div>
      </Row>
      <Row>
        <div className="col-md-12 mb-3">
          <div className="form-group">
            <Form.Label>About</Form.Label>
            <Form.Control
              as="textarea"
              {...register("user_description")}
              rows="9"
              placeholder="About"
              className="textarea-no-resize textarea"
            ></Form.Control>
          </div>
        </div>
      </Row>
    </>
  );
};

export default RegisteredInfoForm;
