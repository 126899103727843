import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import "../../assets/styles/components/common/CardList.scss";
import Btn from "./Btn";

const CardList = ({
  items,
  listTitle,
  handleCardClick,
  handleCardAddBidPrice,
  //handleEditClick,
  //handleDeleteClick,
  ItemsPerPage,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const totalPages = Math.ceil(items.length / ItemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const startIndex = currentPage * ItemsPerPage;
  const endIndex = startIndex + ItemsPerPage;
  const currentItems = filteredItems.slice(startIndex, endIndex);

  //const bidPriceState = useSelector((state) => state.BidPrice);

  // const getBidSet = (item) => {
  //   const matchingBid = bidPriceState.data.find(
  //     (bid) => bid.bid_id === item.id
  //   );
  //   return matchingBid ? matchingBid : null;
  // };

  // const isBidAdded = (itemId) => {
  //   if (Array.isArray(bidPriceState.data)) {
  //     return bidPriceState.data.some((bid) => bid.bid_id === itemId);
  //   }
  //   return false;
  // };

  /*   useEffect(() => {
    dispatch(fetchBidPrices());
  }, [dispatch]); */

  return (
    <>
      <div className="template-area pd-top-60 pd-bottom-60">
        <div className="container">
          <div className="row align-items-end mb-5">
            <div className="col col-md-8 col-sm-12">
              <div className="section-title">
                <h2 className="title">{listTitle}</h2>
                {/* <p className="content mb-0">
                  Open-source threaded team chat that helps teams stay
                  productive and focused.
                </p> */}
              </div>
            </div>

            <div className="col col-md-4 col-sm-12  pl-0">
              <div className="search-box d-flex justify-content-end mb-1">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <button className="btn btn-primary mt-0">Search</button>
                </div>
              </div>
            </div>
          </div>
          {currentItems.length === 0 ? (
            <div className="col-md-12 text-center bg-light-gray p-5 no-result">
              Sorry, no results found for your search. Please try a different
              query or refine your search terms.
            </div>
          ) : (
            <div className="row d-flex justify-content-between">
              {currentItems.map((item, index) => (
                <div key={index} md={4} className="col-md-4">
                  <div className="card mb-3 shadow m-auto mw-100">
                    <img className="card-img-top" src={item.image} alt="" />
                    <div className="card-body">
                      <div className="card-title">{item.title}</div>
                      <div className="card-text mb-4">{item.description}</div>
                      <div className="d-flex  gap-2">
                        {/* <button className="btn btn-primary m-0" onClick={() => handleCardClick(item)}>
                          View More
                        </button> */}
                        <Button
                          className="w-100"
                          variant="primary"
                          //onClick={() => handleCardClick(item)}
                        >
                          View More
                        </Button>

                        {/* <button
                          onClick={() => handleCardAddBidPrice(item)}
                          className="btn btn-primary m-0"
                          disabled={isBidAdded(item.id)}
                        >
                          Add Bid Price
                        </button> */}
                        <Button
                          className="w-100"
                          variant="outline-primary"
                          //onClick={() => handleCardAddBidPrice(item)}
                        >
                          Manage
                        </Button>

                        {/* <button
                          onClick={() => handleEditClick(getBidSet(item))}
                          className="btn btn-primary m-0"
                          // disabled={!bidPriceState.data.some(bid => bid.bid_id === item.id)}
                          disabled={!isBidAdded(item.id)}
                        >
                          <i className="ri-edit-line"></i>
                        </button> */}

                        {/* <button
                          onClick={() => handleDeleteClick(getBidSet(item))}
                          className="btn btn-primary m-0"
                          // disabled={!bidPriceState.data.some(bid => bid.bid_id === item.id)}
                          disabled={!isBidAdded(item.id)}
                        >
                          <i className="ri-delete-bin-line"></i>
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-center mt-3 gap-3">
                <Button
                  variant="outline-primary"
                  onClick={handlePrevPage}
                  disabled={currentPage === 0}
                >
                  <i className="ri-arrow-left-s-line"></i> Previous
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages - 1}
                >
                  Next <i className="ri-arrow-right-s-line"></i>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardList;
