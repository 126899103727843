import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    blobUrl: null,
    uploadedFileName: null,
    error: null
};

const fileUploadSlice = createSlice({
    name: 'fileUpload',
    initialState,
    reducers: {
        uploadFileStart(state) {
            state.isLoading = true;
            state.blobUrl = null;
            state.uploadedFileName = null;
            state.error = null;
        },
        uploadFileSuccess(state, action) {
            state.isLoading = false;
            state.blobUrl = action.payload.url;
            state.uploadedFileName = action.payload._name;
        },
        uploadFileFailure(state, action) {
            state.isLoading = false;
            state.blobUrl = null;
            state.uploadedFileName = null;
            state.error = action.payload;
        },
        uploadFileReset(state) {
            state.isLoading = false;
            state.blobUrl = null;
            state.uploadedFileName = null;
            state.error = null;
        }
    }
});

// Export the actions generated by createSlice
export const {
    uploadFileStart,
    uploadFileSuccess,
    uploadFileFailure,
    uploadFileReset
} = fileUploadSlice.actions;

// Export the reducer to be used in the store
export default fileUploadSlice.reducer;
