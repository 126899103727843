import React, { useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import useAuth from "../../../hooks/useAuth";
import { ROLES } from "../../../utils/types";
import TaskCreateModal from "./TaskCreateModal";
import GetReportModal from "./GetReportModal";

const ProjectManagementTopSection = ({
  ProjectTaskData,
  allUserList,
  getSearchByProject,
}) => {
  const isAuthenticated = useAuth();
  const [search, setSearch] = useState("");
  const [showTaskCreateModalState, setShowTaskCreateModalState] =
    useState(false);
  const [showGetReportModalState, setShowGetReportModalState] = useState(false);

  const toggleTaskCreateFormModal = () => {
    setShowTaskCreateModalState(!showTaskCreateModalState);
  };

  const toggleGetReportFormModal = () => {
    setShowGetReportModalState(!showGetReportModalState);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      getSearchByProject(search);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-between mb-4">
      <div className="col-md-6 align-items-center">
        <div className="g-2 align-items-center d-flex justify-content-between">
          {isAuthenticated.user_role == ROLES.COMPANY && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>Create Task</Tooltip>}
            >
              <Button
                variant="primary"
                className="normal-btn align-items-center btn-prymary icon-with-btn w-25 me-2"
                onClick={toggleTaskCreateFormModal}
              >
                <i className="ri-menu-line"></i> New Task
              </Button>
            </OverlayTrigger>
          )}

          <InputGroup>
            <InputGroup.Text>
              <i className="ri-search-line"></i> {/* Remix Icon */}
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search in this Dashboard"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyPress={handleKeyPress}
            />
          </InputGroup>
        </div>
      </div>
      <OverlayTrigger placement="left" overlay={<Tooltip>Get Report</Tooltip>}>
        <Button
          variant="primary"
          className="icon-with-btn"
          onClick={toggleGetReportFormModal}
        >
          <i className="ri-article-line"></i> Get Report
        </Button>
      </OverlayTrigger>
      <TaskCreateModal
        show={showTaskCreateModalState}
        toggleFormModal={toggleTaskCreateFormModal}
      />
      <GetReportModal
        show={showGetReportModalState}
        toggleFormModal={toggleGetReportFormModal}
        ProjectTaskData={ProjectTaskData}
        allUserList={allUserList}
      />
    </div>
  );
};

export default ProjectManagementTopSection;
