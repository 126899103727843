// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial-area .title {
  color: #232340;
  font-size: 3.125rem;
  font-weight: 600;
  font-family: "Lexend Deca", sans-serif;
}
.testimonial-area .testimonial-slider .single-testimonial-inner {
  box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
  padding: 40px 30px;
  margin: 10px;
  background: #fff;
}
.testimonial-area .testimonial-slider .single-testimonial-inner h5,
.testimonial-area .testimonial-slider .single-testimonial-inner p {
  color: #232340;
}
.testimonial-area .testimonial-slider .single-testimonial-inner .icon {
  display: inline-block;
}
.testimonial-area .testimonial-slider .single-testimonial-inner.testimonial-content {
  margin-bottom: 120px;
  position: relative;
}
.testimonial-area .testimonial-slider .single-testimonial-inner.testimonial-content:after {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  transform: rotate(-45deg);
  background: #fff;
  bottom: -9px;
  left: 50%;
  margin-left: -10px;
}
.testimonial-area .testimonial-slider .single-testimonial-inner.testimonial-content .thumb {
  background: #fff;
  box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: absolute;
  bottom: -105px;
  left: 50%;
  transform: translateX(-50%);
}
.testimonial-area .testimonial-slider .single-testimonial-inner.testimonial-content .thumb img {
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/home/FeedbackCarousel.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,sCAAA;AAAJ;AAMI;EACE,8CAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;AAJN;AAKM;;EAEE,cAAA;AAHR;AAKM;EACE,qBAAA;AAHR;AAMM;EACE,oBAAA;EACA,kBAAA;AAJR;AAKQ;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,YAAA;EACA,SAAA;EACA,kBAAA;AAHV;AAMQ;EACE,gBAAA;EACA,8CAAA;EAEA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,SAAA;EACA,2BAAA;AALV;AAMU;EACE,kBAAA;AAJZ","sourcesContent":[".testimonial-area {\n  .title {\n    color: #232340;\n    font-size: 3.125rem;\n    font-weight: 600;\n    font-family: \"Lexend Deca\", sans-serif;\n  }\n  .testimonial-slider {\n    .owl-nav {\n      //display: none;\n    }\n    .single-testimonial-inner {\n      box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);\n      padding: 40px 30px;\n      margin: 10px;\n      background: #fff;\n      h5,\n      p {\n        color: #232340;\n      }\n      .icon {\n        display: inline-block;\n      }\n\n      &.testimonial-content {\n        margin-bottom: 120px;\n        position: relative;\n        &:after {\n          content: \"\";\n          position: absolute;\n          height: 20px;\n          width: 20px;\n          transform: rotate(-45deg);\n          background: #fff;\n          bottom: -9px;\n          left: 50%;\n          margin-left: -10px;\n        }\n\n        .thumb {\n          background: #fff;\n          box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);\n          //padding: 8px;\n          height: 80px;\n          width: 80px;\n          border-radius: 50%;\n          position: absolute;\n          bottom: -105px;\n          left: 50%;\n          transform: translateX(-50%);\n          img {\n            border-radius: 50%;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
