import React from "react";
import { Row, Form } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";

const CommonInfoForm = ({ userRoleName }) => {
  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const phoneUtil = PhoneNumberUtil.getInstance();
  const selectedCountry = watch("country");

  const validatePhoneNumber = (value) => {
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(value);
      return phoneUtil.isValidNumber(phoneNumber) || "Invalid phone number";
    } catch (error) {
      return "Invalid phone number";
    }
  };

  return (
    <>
      <Row>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <Form.Label>
              Select your country <sup>*</sup>
            </Form.Label>
            <Controller
              name="country"
              control={control}
              defaultValue=""
              rules={{ required: "Country is required" }}
              render={({ field }) => (
                <CountryDropdown
                  {...field}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  className="form-select"
                />
              )}
            />
            {errors.country && (
              <small className="text-danger">{errors.country.message}</small>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <Form.Label>
              Select your region <sup>*</sup>
            </Form.Label>
            <Controller
              name="region"
              control={control}
              defaultValue=""
              rules={{ required: "Region is required" }}
              render={({ field }) => (
                <RegionDropdown
                  {...field}
                  country={selectedCountry}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  className="form-select"
                />
              )}
            />
            {errors.region && (
              <small className="text-danger">{errors.region.message}</small>
            )}
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-md-12 mb-3">
          <div className="form-group">
            <Form.Label>
              Address Line 1 <sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              className="form-input"
              {...register("address1", {
                required: "Address Line 1 is required",
              })}
            />
            {errors.address1 && (
              <small className="text-danger">{errors.address1.message}</small>
            )}
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              type="text"
              className="form-input"
              {...register("address2")}
            />
            {errors.address2 && (
              <small className="text-danger">{errors.address2.message}</small>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <Form.Label>Address Line 3</Form.Label>
            <Form.Control
              type="text"
              className="form-input"
              {...register("address3")}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-md-12 mb-3">
          <div className="form-group">
            <Form.Label>
              Contact Number <sup>*</sup>
            </Form.Label>
            <Controller
              name="contact"
              control={control}
              defaultValue=""
              rules={{
                required: "Phone Number is required",
                validate: validatePhoneNumber,
              }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  defaultCountry="se"
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            {errors.contact && (
              <small className="text-danger">{errors.contact.message}</small>
            )}
          </div>
        </div>
      </Row>
    </>
  );
};

export default CommonInfoForm;
