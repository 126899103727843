import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const GuestLayout = () => {
  const isAuthenticated = useAuth();
  const url = localStorage.getItem("intendedUrl");

  if (isAuthenticated && isAuthenticated?.is_onboard === 1) {
    if (url === null) {
      return <Navigate to="/dashboard" />
      /* if (
        isAuthenticated?.user_role === "1" ||
        isAuthenticated?.user_role === "3"
      ) {
        return <Navigate to="/dashboard" />;
      } else {
        return <Navigate to="/my_projects" />;
      } */
    } else {
      return <Navigate to={ url } />;
    }
  } else if (isAuthenticated && isAuthenticated?.is_onboard === 0) {
    return <Navigate to="/onboard" />;
  } else {
    return <Outlet />;
  }
};

export default GuestLayout;
