import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { post } from "../../servces/Api";
import Alert from "../../components/common/Alert";

// Action User Creat
export const registerUser = createAsyncThunk("auth/register", async (data) => {
  try {
    const response = await post("/api/register", data);
    if (response.error) {
      Object.values(response.error).forEach((err) => {
        Alert.error("Failed to create user. Please try again.");
        return false;
      });
    } else if (response.status === 200) {
      Alert.success("User created successfully!");
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      // Handle existing email error
      Alert.error("Email already exists. Please use a different email.");
    } else if (error.response && error.response.status === 500) {
      // Handle server error
      Alert.error("Server error. Please try again later.");
    } else {
      // Handle other errors, e.g., network issues
      Alert.error("Failed to create user. Please try again.");
    }
    return false;
  }
});

// Action Upgarde Package
export const userDowngradePackage = createAsyncThunk(
  "auth/downgradepackage",
  async () => {
    try {
      const response = await post("/api/downgrade_package");
      if (response.status === 200) {
        Alert.success(`Successfully downgraded to Free trial package.`);
        return response.data.user;
      } else {
        Alert.warning("Package Downgrade Unsuccessfully");
      }
    } catch (error) {
      Alert.error("Error in Package Downgration");
      return false;
    }
  }
);

// Action downgarde Package
export const userUpgradePackage = createAsyncThunk(
  "auth/updatepackage",
  async (id) => {
    const data = { package_id: id };
    try {
      const response = await post("/api/upgrade_package", data);
      if (response.status === 200) {
        Alert.success(
          `Successfully updated to ${
            response.data.user.package_id === 1
              ? "Free trial"
              : response.data.user.package_id === 2
              ? "Basic"
              : response.data.user.package_id === 3
              ? "Business"
              : "Enterprise"
          } package.`
        );
        return response.data.user;
      } else {
        Alert.warning(
          "Error upgrade package : ",
          response?.message ? response?.message : ""
        );
      }
    } catch (error) {
      Alert.error("Error in Package Updation");
      return false;
    }
  }
);

// Action User Login
export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }) => {
    const data = { email, password };
    try {
      const response = await post("/api/login", data);
      if (response.data.data.is_verify === 1) {
        Alert.success("Login Success");
        const expiryDate = new Date(response.data.ac_expiration_time);
        localStorage.setItem("tokenExpiry", expiryDate);
        Cookies.set("userToken", response.data.token, {
          expires: expiryDate,
        });
        Cookies.set("refreshToken", response.data.refresh_token, {
          expires: 7,
        });
        localStorage.setItem("logout", false);
        return response.data.data;
      } else {
        Alert.warning("Need verify Account, Please check your email");
      }
    } catch (error) {
      if (error.response.data.message === "Inactive  Login.") {
        Alert.error("Your account is inactive. Please contact support.");
      } else if (
        error.response.data.message === "Failed Login." ||
        error.response.data === "error Login"
      ) {
        Alert.error("These credentials do not match our records.");
      }
      return false;
    }
  }
);

//User userLogout
export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { dispatch }) => {
    try {
      const refreshToken = Cookies.get("refreshToken");
      const numberPart = refreshToken
        ? parseInt(refreshToken.split("|")[0], 10)
        : 0;
      const data = {
        r_id: numberPart,
      };
      const response = await post("/api/logout", data);
      if (response.ok) {
        Alert.Error("logout success");
      }
    } catch (error) {
      console.error("Failed to logout:", error);
      throw error;
    }
  }
);

//Get email verification link
export const emailVerificationLink = createAsyncThunk(
  "auth/getVerificationEmail",
  async ({ email }) => {
    try {
      const response = await post("/api/send_verify_email", {
        email,
      });
      if (response.status === 200) {
        Alert.success(
          "A verification email has been sent. Please check your email."
        );
        return response.data;
      }
    } catch (error) {
      // Handle error cases
      return { error: "Network error" };
    }
  }
);

//User Email verify and activate account
export const accountActivate = createAsyncThunk(
  "auth/EmailVerify",
  async (token, { rejectWithValue }) => {
    try {
      const response = await post("/api/account_activate", { token });
      if (response.data.status === "error") {
        Alert.error("Account activation link is expired or invalid");
        return response.data;
      } else {
        Alert.success("Account activate success");
        return response.data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue("Network error");
      }
    }
  }
);

//Forgot passowrd verify code
export const verifyCode = createAsyncThunk(
  "auth/forgotPasswordVerifyCode",
  async ({ email }) => {
    try {
      const response = await post("/api/forgot_password_verificationCode", {
        email,
      });

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          Alert.error(response.message);
        });
        return response;
      } else if (response.status === 200) {
        Alert.success("Verification Code Sent");
        return response;
      } else {
        Alert.error(response.response.data.message);
        return false;
      }
    } catch (error) {
      Alert.error("Error Verification Code");
      return false;
    }
  }
);

//Forgot password verify code check
export const checkCode = createAsyncThunk(
  "auth/forgotPasswordCheckCode",
  async ({ email, verificationCode }) => {
    try {
      const response = await post(
        "/api/forgot_password_check_verificationCode",
        {
          email,
          verificationCode,
        }
      );
      if (response.error) {
        Object.values(response.error).forEach((err) => {
          Alert.error(response.message);
        });
        return false;
      } else if (response.status === 200 && response.data.verified === true) {
        Alert.success("Verification Matched");
        //return response;
        return true;
      } else {
        Alert.error("Verification code did not matched!");
        return false;
      }
    } catch (error) {
      Alert.error(
        "Verification code something went wrong!. Please try again later."
      );
      return false;
    }
  }
);

//Forgot Password Update
export const updatePassword = createAsyncThunk(
  "auth/forgotPasswordUpdate",
  async ({ email, newPassword, repeatPassword }) => {
    try {
      const response = await post("/api/forgot_password_update", {
        email,
        newPassword,
        repeatPassword,
      });
      if (response.error) {
        Object.values(response.error).forEach((err) => {
          Alert.error("Error Changing");
        });
        return false;
      } else if (response.status === 200) {
        Alert.success("Password updated successfully");
        return true;
      } else if (response.response.status === 404) {
        Alert.error("Email not found");
        return false;
      } else if (response.response.status === 400) {
        Alert.error("Password mismatch");
        return false;
      } else {
        Alert.error("An error occurred. Please try again.");
        return false;
      }
    } catch (error) {
      Alert.error("Error Forgot Password");
      return false;
    }
  }
);
