import React, { useRef } from "react";

import Footer from "../../components/dashboard/Footer";
import CreateNewTender from "../../components/dashboard/tenders/CreateNewTender";
import TenderList from "../../components/dashboard/tenders/TenderList";

const AllTenders = () => {
  const moduleName = "All Tenders";
  const refetchTendersRef = useRef(null);

  const handleRefetchTenders = () => {
    if (refetchTendersRef.current) {
      refetchTendersRef.current();
    }
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{ moduleName }</li>
            </ol>
            <h4 className="main-title mb-0">{ moduleName }</h4>
          </div>
          <div>
            <CreateNewTender onTenderCreated={ handleRefetchTenders } />
          </div>
        </div>
        <div className="inner-wrapper">
          <TenderList refetchTendersRef={ refetchTendersRef } />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AllTenders;
