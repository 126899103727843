import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="main-footer">
      <span>&copy; {`${currentYear}. Nordic Field Services. All Rights Reserved.`}</span>
      <span>
        Created by:{" "}
        <Link to="https://pears.se/" target="_blank">
          Pears Sweden
        </Link>
      </span>
    </div>
  );
}
