import React, { useState } from "react";
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Form,
  Row,
  Alert,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import FormModal from "../../common/FormModal";
import {
  sendQuotationCustomer,
  fetchBidListTender,
} from "../../../store/actions/tenderActions";
import { useForm } from "react-hook-form";

const SendCustomerQuotation = ({
  emailAttachment,
  quotatonFileName,
  tenderData,
  is_sent_quotation,
  generateQuotation
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.tender);
  const [showModalState, setShowModalState] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: tenderData?.customer_email,
      subject: "",
      body: "",
      attachment: "",
    },
  });

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
    reset();
  };

  const onSubmit = async (data) => {
    data.attachment = emailAttachment;
    data.attachment_name = quotatonFileName;
    data.tender_id = tenderData?.id;
    data.is_sent_quotation = is_sent_quotation;

    dispatch(sendQuotationCustomer(data)).then((result) => {
      if (result.payload.is_sent_quotation === 1) {
        dispatch(fetchBidListTender(tenderData?.id));
        toggleFormModal();
      }
    });
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Send quotation to customer</Tooltip>}
      >
        <Button
          variant="primary"
          className="icon-with-btn"
          onClick={toggleFormModal}
        >
          <i className="ri-mail-send-line"></i> Send quotation to customer
        </Button>
      </OverlayTrigger>
      <FormModal
        moduleName={"Create new tender"}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="600px"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <Form.Label>Customer email</Form.Label>
                <Form.Control
                  type="email"
                  disabled={loading}
                  className="form-input"
                  id="email"
                  {...register("email", {
                    required: "Customer email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  className="form-input"
                  id="subject"
                  {...register("subject", {
                    required: "Subject is required",
                  })}
                />
                {errors.subject && (
                  <small className="text-danger">
                    {errors.subject.message}
                  </small>
                )}
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  disabled={loading}
                  {...register("body", {
                    required: "Quotation note is required",
                    maxLength: {
                      value: 1000,
                      message: "Maximum length is 1000 characters",
                    },
                  })}
                  placeholder="Tender description"
                  className="textarea"
                ></Form.Control>
                {errors.body && (
                  <small className="text-danger">{errors.body.message}</small>
                )}
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mb-3">
              <Form.Label>Attachment Doc</Form.Label>
              <Alert
                variant="secondary"
                className="d-flex align-items-center p-1 pl-2"
              >
                <span className="me-auto d-block p-2">{quotatonFileName}</span>
                <Button
                  variant="secondary"
                  className="icon-with-btn"
                  onClick={() => {
                    generateQuotation();
                  }}
                >
                  <i className="ri-attachment-2"></i> Generate attachment
                </Button>
              </Alert>
            </div>
          </Row>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <Button
              type="button"
              disabled={loading}
              variant="outline-primary"
              className="btn-sign"
              onClick={() => {
                reset();
                toggleFormModal();
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              className="btn-sign"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Send Quotation
                </>
              ) : (
                "Send Quotation"
              )}
            </Button>
          </div>
        </Form>
      </FormModal>
    </>
  );
};

export default SendCustomerQuotation;
