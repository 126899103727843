import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-international-phone/style.css";
import { updateUserProfileInfo, getUserProfileInfo } from "../../../store/actions/profileActions";
import { fetchUsersList } from "../../../store/actions/userManageActions";
import { Button, Form, Row } from "react-bootstrap";

const UpdateProfile = ({ profileData, isLoading, onClose, parent }) => {
  const dispatch = useDispatch();
  const {
    user_id = profileData?.userdet.id,
    first_name = "",
    last_name = "",
    address1 = "",
    address2 = "",
    address3 = "",
    region = "",
    country = "",
    contact = "",
    email = "",
    user_description = "",
  } = profileData?.userdet || {};

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user_id,
      first_name,
      last_name,
      address1,
      address2,
      address3,
      region,
      country,
      contact,
      email,
      user_description,
    },
  });

  const phoneUtil = PhoneNumberUtil.getInstance();
  const selectedCountry = watch("country");

  const validatePhoneNumber = (value) => {
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(value);
      return phoneUtil.isValidNumber(phoneNumber) || "Invalid phone number";
    } catch (error) {
      return "Invalid phone number";
    }
  };

  const onSubmit = async (data) => {
    await dispatch(updateUserProfileInfo(data)).then(() => {
      if (parent === 'my_profile') {
        dispatch(getUserProfileInfo(user_id));
      } else {
        dispatch(fetchUsersList(user_id));
      }

      onClose()
    })
  };

  return (
    <>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <Row>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                className="form-input"
                { ...register("first_name", {
                  required: "First Name is required",
                  maxLength: {
                    value: 50,
                    message: "Maximum length is 50 characters",
                  },
                }) }
                placeholder="First Name"
                disabled={ isLoading }
              />
              { errors.first_name && (
                <small className="text-danger">
                  { errors.first_name.message }
                </small>
              ) }
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                className="form-input"
                { ...register("last_name", {
                  required: "Last Name is required",
                  maxLength: {
                    value: 50,
                    message: "Maximum length is 50 characters",
                  },
                }) }
                placeholder="Last Name"
                disabled={ isLoading }
              />
              { errors.last_name && (
                <small className="text-danger">
                  { errors.last_name.message }
                </small>
              ) }
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-md-12 mb-3">
            <div className="form-group">
              <Form.Label>About</Form.Label>
              <Form.Control
                as="textarea"
                { ...register("user_description", {
                  maxLength: {
                    value: 500,
                    message: "Maximum length is 500 characters",
                  },
                }) }
                rows="9"
                placeholder="About"
                className="textarea-no-resize textarea"
                disabled={ isLoading }
              ></Form.Control>
              { errors.user_description && (
                <small className="text-danger">
                  { errors.user_description.message }
                </small>
              ) }
            </div>
          </div>
        </Row>
        <Row>
          <div className="form-group mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              className="form-input"
              { ...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              }) }
              placeholder="Email"
              disabled
            />
            { errors.email && (
              <small className="text-danger">{ errors.email.message }</small>
            ) }
          </div>
        </Row>
        <Row>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <Controller
                name="country"
                control={ control }
                rules={ { required: "Country is required" } }
                render={ ({ field: { onChange, value } }) => (
                  <CountryDropdown
                    value={ value }
                    onChange={ (val) => onChange(val) }
                    className="form-select"
                    disabled={ isLoading }
                  />
                ) }
              />
              { errors.country && (
                <small className="text-danger">{ errors.country.message }</small>
              ) }
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <Controller
                name="region"
                control={ control }
                rules={ { required: "Region is required" } }
                render={ ({ field: { onChange, value } }) => (
                  <RegionDropdown
                    country={ selectedCountry }
                    value={ value }
                    onChange={ (val) => onChange(val) }
                    className="form-select"
                    disabled={ isLoading }
                  />
                ) }
              />
              { errors.region && (
                <small className="text-danger">{ errors.region.message }</small>
              ) }
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <Form.Label>
                Address Line 1 <sup>*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                className="form-input"
                { ...register("address1", {
                  required: "Address Line 1 is required",
                }) }
                disabled={ isLoading }
              />
              { errors.address1 && (
                <small className="text-danger">{ errors.address1.message }</small>
              ) }
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                className="form-input"
                { ...register("address2", {
                  maxLength: {
                    value: 100,
                    message: "Maximum length is 100 characters",
                  },
                }) }
                disabled={ isLoading }
              />
              { errors.address2 && (
                <small className="text-danger">{ errors.address2.message }</small>
              ) }
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <Form.Label>Address Line 3</Form.Label>
              <Form.Control
                type="text"
                className="form-input"
                { ...register("address3", {
                  maxLength: {
                    value: 100,
                    message: "Maximum length is 100 characters",
                  },
                }) }
                disabled={ isLoading }
              />
              { errors.address3 && (
                <small className="text-danger">{ errors.address3.message }</small>
              ) }
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <Form.Label>
                Contact Number <sup>*</sup>
              </Form.Label>
              <Controller
                name="contact"
                control={ control }
                defaultValue=""
                rules={ {
                  required: "Phone Number is required",
                  validate: validatePhoneNumber,
                } }
                render={ ({ field }) => (
                  <PhoneInput
                    { ...field }
                    defaultCountry="se"
                    onChange={ (value) => field.onChange(value) }
                    disabled={ isLoading }
                  />
                ) }
              />
              { errors.contact && (
                <small className="text-danger">{ errors.contact.message }</small>
              ) }
            </div>
          </div>
        </Row>

        <div className="d-flex justify-content-center gap-2 mt-2">
          <Button
            type="button"
            variant="outline-primary"
            className="w-100"
            disabled={ isLoading }
            onClick={ onClose }
          >
            Close
          </Button>
          {/* {isSubmitting ? "Submitting..." : "Submit"} */ }
          <Button
            type="submit"
            variant="primary"
            className="btn-sign w-100"
            disabled={ isLoading }
          >
            { isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Save profile data"
            ) }
          </Button>
        </div>
      </form>
    </>
  );
};

export default UpdateProfile;
