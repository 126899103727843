import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import FormModal from "../../common/FormModal";
import TaskForm from "./TaskForm";

const TaskFormModal = ({
  show,
  toggleFormModal,
  isEdit = false,
  editData = {},
  projectId,
  projectName
}) => {
  return (
    <FormModal
      moduleName={ isEdit ? "Update  task" : "Create new task" }
      modalState={ show }
      toggleFormModal={ toggleFormModal }
      width="600px"
    >
      <TaskForm
        isEdit={ isEdit }
        toggleFormModal={ toggleFormModal }
        editData={ editData }
        projectId={ projectId }
        projectName={ projectName }
      />
    </FormModal>
  );
};

export default TaskFormModal;
