// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mockup-area .content-section {
  padding: 154px 200px 100px 200px;
}
.mockup-area .content-section .title {
  color: #232340;
  font-size: 3.125rem;
  font-weight: 600;
  font-family: "Lexend Deca", sans-serif;
}
.mockup-area .content-section .content {
  color: #232340;
  font-size: 1.125rem;
  font-family: "Lexend Deca", sans-serif;
}
.mockup-area .content-section .learn-more-link {
  color: #5468e7;
  font-size: 1.125rem;
  font-family: "Lexend Deca", sans-serif;
  display: flex;
  align-items: center;
  gap: 7px;
  text-decoration: underline;
  margin-top: 30px;
}

.pr-0 {
  padding-right: 0;
}

.pd-top-60 {
  padding-top: 60px;
}

.pd-bottom-60 {
  padding-bottom: 60px;
}

.pd-top-10 {
  padding-top: 10px;
}

.pd-bottom-10 {
  padding-bottom: 10px;
}

.bg-light-gray {
  background: #f5f9ff;
}

@media only screen and (max-width: 1366px) {
  .mockup-area .content-section {
    padding: 152px 60px 0px 130px;
  }
}
@media only screen and (max-width: 1024px) {
  .mockup-area .content-section {
    padding: 22px 19px 0px 44px;
  }
}
@media only screen and (max-width: 991px) {
  .mockup-area .content-section {
    padding: 0 10%;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/home/MockupSection.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;AAAJ;AACI;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,sCAAA;AACN;AACI;EACE,cAAA;EACA,mBAAA;EACA,sCAAA;AACN;AACI;EACE,cAAA;EACA,mBAAA;EACA,sCAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,0BAAA;EACA,gBAAA;AACN;;AAGA;EACE,gBAAA;AAAF;;AAEA;EACE,iBAAA;AACF;;AACA;EACE,oBAAA;AAEF;;AAAA;EACE,iBAAA;AAGF;;AADA;EACE,oBAAA;AAIF;;AAFA;EACE,mBAAA;AAKF;;AAFA;EACE;IACE,6BAAA;EAKF;AACF;AAFA;EACE;IACE,2BAAA;EAIF;AACF;AADA;EACE;IACE,cAAA;EAGF;AACF","sourcesContent":[".mockup-area {\n  .content-section {\n    padding: 154px 200px 100px 200px;\n    .title {\n      color: #232340;\n      font-size: 3.125rem;\n      font-weight: 600;\n      font-family: \"Lexend Deca\", sans-serif;\n    }\n    .content {\n      color: #232340;\n      font-size: 1.125rem;\n      font-family: \"Lexend Deca\", sans-serif;\n    }\n    .learn-more-link {\n      color: #5468e7;\n      font-size: 1.125rem;\n      font-family: \"Lexend Deca\", sans-serif;\n      display: flex;\n      align-items: center;\n      gap: 7px;\n      text-decoration: underline;\n      margin-top: 30px;\n    }\n  }\n}\n.pr-0 {\n  padding-right: 0;\n}\n.pd-top-60 {\n  padding-top: 60px;\n}\n.pd-bottom-60 {\n  padding-bottom: 60px;\n}\n.pd-top-10 {\n  padding-top: 10px;\n}\n.pd-bottom-10 {\n  padding-bottom: 10px;\n}\n.bg-light-gray {\n  background: #f5f9ff;\n}\n\n@media only screen and (max-width: 1366px) {\n  .mockup-area .content-section {\n    padding: 152px 60px 0px 130px;\n  }\n}\n\n@media only screen and (max-width: 1024px) {\n  .mockup-area .content-section {\n    padding: 22px 19px 0px 44px;\n  }\n}\n\n@media only screen and (max-width: 991px) {\n  .mockup-area .content-section {\n    padding: 0 10%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
