import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";

import useAuth from "../../hooks/useAuth";
import useUserRole from "../../hooks/getUserRole";
import RegisteredInfoForm from "../../components/onboard/RegisteredInfoForm";
import CommonInfoForm from "../../components/onboard/CommonInfoForm";
import CompanyInfoForm from "../../components/onboard/CompanyInfoForm";
import DeliveryInfoForm from "../../components/onboard/DeliveryInfoForm";
import FieldInfoForm from "../../components/onboard/FieldInfoForm";
import { userOnboard } from "../../store/actions/onboardActions";
import { logoutUser } from "../../store/actions/authActions";
import CompletedOnboard from "../../components/onboard/CompletedOnboard";

const UsersOnboard = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [countdown, setCountdown] = useState(10);
  const methods = useForm();
  const [formData, setFormData] = useState({});
  const [onboardProccessCompleted, setonboardProccessCompleted] =
    useState(false);
  const isAuthenticated = useAuth();
  const userinfo = isAuthenticated;
  const dispatch = useDispatch();
  const { userRoleName, userRoleId } = useUserRole(isAuthenticated.user_role);
  const isCustomerOrAdmin =
    userRoleName === "CUSTOMER" || userRoleName === "ADMIN";

  const { loading, onboardedUser, success } = useSelector(
    (state) => state.userOnboarded
  );

  useEffect(() => {
    if (userinfo.is_onboard === 0 && userinfo.is_pending === 0) {
      setonboardProccessCompleted(true);
    }
  }, [userinfo]);

  const onSubmit = async (data) => {
    const updatedData = {
      ...formData,
      ...data,
    };
    dispatch(userOnboard(updatedData)).then((response) => {
      if (response.payload || response.payload.is_onboard === 0) {
        setonboardProccessCompleted(true);
        let timer = setInterval(() => {
          setCountdown((prevCount) => prevCount - 1);
        }, 1000);

        setTimeout(() => {
          clearInterval(timer);
          dispatch(logoutUser());
        }, 10000);
      }
    });
  };

  const handleNextStep = () => {
    methods.trigger().then((isValid) => {
      if (isValid) {
        setStep(step + 1);
      }
    });
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  return (
    <div className="page-sign">
      <Card className="card-sign card-signup">
        {!onboardProccessCompleted && (
          <Card.Header>
            <Card.Title>
              Onboard process -{" "}
              <span className="text-dark text-lowercase">
                <span>{userRoleName}</span>{" "}
                {userRoleId === 4 || userRoleId === 5 ? (
                  <span>officer </span>
                ) : null}
              </span>
            </Card.Title>
            <Card.Text>
              Streamlined Onboarding for Success: Welcome to Your New Journey!
            </Card.Text>
          </Card.Header>
        )}

        <Card.Body>
          {onboardProccessCompleted ? (
            <CompletedOnboard
              isCustomerOrAdmin={isCustomerOrAdmin}
              countdown={countdown}
            />
          ) : (
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                {step === 1 && (
                  <RegisteredInfoForm
                    userRoleName={userRoleName}
                    userinfo={userinfo}
                  />
                )}
                {step === 2 && <CommonInfoForm />}
                {!isCustomerOrAdmin &&
                  step === 3 &&
                  userRoleName === "COMPANY" && <CompanyInfoForm />}
                {!isCustomerOrAdmin &&
                  step === 3 &&
                  userRoleName === "DELIVERY" && <DeliveryInfoForm />}
                {!isCustomerOrAdmin &&
                  step === 3 &&
                  userRoleName === "FIELD" && <FieldInfoForm />}

                <div className="d-flex justify-content-center gap-2 mt-2">
                  {step > 1 && (
                    <Button
                      type="button"
                      variant="outline-primary"
                      className="w-100"
                      onClick={handlePreviousStep}
                    >
                      Previous
                    </Button>
                  )}
                  {((isCustomerOrAdmin && step === 2) ||
                    (!isCustomerOrAdmin && step === 3)) && (
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn-sign w-100"
                      disabled={loading}
                    >
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  )}
                  {step < (isCustomerOrAdmin ? 2 : 3) && (
                    <Button
                      type="button"
                      variant="primary"
                      className="w-100"
                      onClick={handleNextStep}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </Form>
            </FormProvider>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UsersOnboard;
