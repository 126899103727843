import React from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../../assets/img/icon.png";
import "../../assets/styles/components/home/Footer.scss";

const Footer = () => {
  return (
    <>
      <footer className="footer-area bg-blue-dark mt-0">
        <div className="container pd-top-60 pd-bottom-60">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src={FooterLogo} width={150} alt="img" />
                </div>
                <div className="details mt-4">
                  <p>Next generation of Field Services Partner</p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Company</h4>
                <ul>
                  <li>
                    <Link to="/">Product</Link>
                  </li>
                  <li>
                    <Link to="/">Web</Link>
                  </li>
                  <li>
                    <Link to="/">Support</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Services</h4>
                <ul>
                  <li>
                    <Link to="/">Site Survey/Site Readiness</Link>
                  </li>
                  <li>
                    <Link to="/">Cpe Installation Service</Link>
                  </li>
                  <li>
                    <Link to="/">Break Fix Services </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Contact Us</h4>
                <div className="details">
                  <p>22, Blomdalsvägen 3b, Stokholm , Sweden</p>
                  <p>
                  <i className="ri-phone-fill"></i> +46 (0)10 491 23 66
                  </p>
                  <p>
                    <i className="ri-mail-send-line"></i> info@nordicfield.se
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom footer-bg pd-top-10 pd-bottom-10">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 align-self-center">
                <p className="mb-0">
                  © Nordic Field Services. All Rights Reserved
                </p>
              </div>
              <div className="col-md-6 text-lg-end bottom-links">
                <a href="/#">Trams &amp; Condition</a>
                <a href="/#">Privacy Policy</a>
                <a href="/#">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
