import React from 'react';
import { Button } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CustomerSupport } from "../../store/actions/customerSupportActions"

const CustomerSupportForm = ({ toggleFormModal }) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { loading } = useSelector((state) => state.cutomerSupport);

    const onSubmit = (data) => {
        dispatch(CustomerSupport(data)).then(() => {
            toggleFormModal();
        })
    };

    return (
        <>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="name">
                                Your Name<sup className="required">*</sup>
                            </label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                { ...register("name", { required: "Name is required" }) }
                                placeholder="Your Name"
                                disabled={ loading }
                            />
                            { errors.name && (
                                <small className="text-danger mb-2">{ errors.name.message }</small>
                            ) }
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="email">
                                Email Address<sup className="required">*</sup>
                            </label>
                            <input
                                type="email"
                                className="form-control form-control-sm"
                                { ...register("email", { required: "Email is required" }) }
                                placeholder="Email Address"
                                disabled={ loading }
                            />
                            { errors.email && (
                                <small className="text-danger mb-2">{ errors.email.message }</small>
                            ) }
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="contact">
                                Phone Number<sup className="required">*</sup>
                            </label>
                            <input
                                type="number"
                                className="form-control form-control-sm"
                                { ...register("contact", { required: "Phone number is required" }) }
                                placeholder="Contact Number"
                                disabled={ loading }
                            />
                            { errors.contact && (
                                <small className="text-danger mb-2">{ errors.contact.message }</small>
                            ) }
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="subject">
                                Subject<sup className="required">*</sup>
                            </label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                { ...register("reason", { required: "Subject is required" }) }
                                placeholder="Subject"
                                disabled={ loading }
                            />
                            { errors.reason && (
                                <small className="text-danger mb-2">{ errors.reason.message }</small>
                            ) }
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="message">
                                Your message here<sup className="required">*</sup>
                            </label>
                            <textarea
                                className="form-control"
                                id="message"
                                { ...register("message", { required: "Message is required" }) }
                                style={ { height: 150 } }
                                placeholder="Your message here"
                                disabled={ loading }
                            />
                            { errors.message && (
                                <small className="text-danger mb-2">{ errors.message.message }</small>
                            ) }
                        </div>
                    </div>
                </div>

                <div className={ `col-md-5 d-flex justify-content-start gap-3` }>
                    <Button
                        variant='secondary'
                        className="btn-sign w-100"
                        type="button"
                        onClick={ () => { reset(); toggleFormModal(); } }
                        style={ { width: 120 } }
                        disabled={ loading }
                    >Clear</Button>
                    <Button
                        type="submit"
                        variant="primary"
                        className="btn-sign w-100"
                        disabled={ loading }
                    >
                        { loading ? (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            "Submit"
                        ) }
                    </Button>
                </div>
            </form>
        </>
    );
}

export default CustomerSupportForm;
