import React from "react";
import { Modal } from "react-bootstrap";

const FormModal = ({
  id,
  modalState,
  moduleName,
  toggleFormModal,
  children,
  size,
  staticBackdrop,
  noHeader,
}) => {
  const handleClose = () => {
    toggleFormModal();
  };

  return (
    <Modal
      id={ id }
      show={ modalState }
      onHide={ handleClose }
      size={ size ? size : "lg" }
      animation={ false }
      fullscreen={ false }
      centered
      backdrop={ staticBackdrop === true ? "static" : true }
      keyboard={ false }
    >
      { !noHeader && (
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title> { moduleName }</Modal.Title>
        </Modal.Header>
      ) }
      <Modal.Body>{ children }</Modal.Body>
    </Modal>
  );
};

export default FormModal;
