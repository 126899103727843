import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { BlobServiceClient } from "@azure/storage-blob";
import { getSasToken } from "../../utils/utils";

const DownloadButton = ({ fileName, toolTip, variant, iconClassName }) => {
  const [loading, setLoading] = useState(false);
  const sasToken = getSasToken();

  const handleFileDownload = async () => {
    const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME;
    const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;

    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    try {
      setLoading(true);
      const downloadBlockBlobResponse = await blockBlobClient.download(0);
      const blobURL = window.URL.createObjectURL(
        await downloadBlockBlobResponse.blobBody
      );
      const a = document.createElement("a");
      a.href = blobURL;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("File download failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{toolTip}</Tooltip>}>
      <Button
        className="small-icon-with-btn"
        disabled={loading}
        variant={variant || "primary"}
        size="sm"
        onClick={handleFileDownload}
      >
        {loading ? (
          <>
            <Spinner animation="border" size="sm" />{" "}
            <i
              className={iconClassName ? iconClassName : "ri-download-2-line"}
            ></i>{" "}
            Download
          </>
        ) : (
          <>
            <i
              className={iconClassName ? iconClassName : "ri-download-2-line"}
            ></i>{" "}
            Download
          </>
        )}
      </Button>
    </OverlayTrigger>
  );
};

export default DownloadButton;
