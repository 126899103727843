import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import moment from "moment";

import Footer from "../../components/dashboard/Footer";
import useAuth from "../../hooks/useAuth";
import TenderView from "../../components/dashboard/tenders/TenderView";
import {
  tenderView,
  fetchBidListTender,
} from "../../store/actions/tenderActions";
import { getSasToken } from "../../utils/utils";
import AddBidModal from "../../components/dashboard/tenders/AddBidModal";
import styles from "../../assets/styles/components/tender-list/TenderView.module.scss";
import TenderBid from "../../components/dashboard/tenders/TenderBid";
import TenderViewLoading from "./TenderViewLoading";
import defaultBannerImg from "../../assets/img/default-banner-img.jpg";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const formatRemainingTime = (duration) => {
  const year = Math.max(duration.years(), 0);
  const month = Math.max(duration.months(), 0);
  const days = Math.max(duration.days(), 0);
  const hours = Math.max(duration.hours(), 0);
  const minutes = Math.max(duration.minutes(), 0);
  const seconds = Math.max(duration.seconds(), 0);

  const yearString = year != 0 ? `${year}y` : "";
  const monthString = month != 0 ? `${month}m` : "";
  const daysString = days != 0 ? `${days}d` : "";
  const hoursString = hours != 0 ? `${hours}h` : "";
  const minutesString = minutes != 0 ? `${minutes}m` : "";
  const secondsString = seconds != 0 ? `${seconds}s` : "";

  return `${yearString} ${monthString} ${daysString} ${hoursString} ${minutesString} ${secondsString}`;
};

const TenderViewPage = () => {
  const isAuthenticated = useAuth();
  const query = useQuery();
  const tenderId = query.get("tenderId");
  const tenderName = query.get("tenderName");
  const forBided = query.get("forBided");
  const moduleName = tenderName;
  const dispatch = useDispatch();
  const { loading, tenderViewData, bidList } = useSelector(
    (state) => state.tender
  );
  const tenderData = tenderViewData?.tender[0];
  const [closingDate, setClosingDate] = useState(moment());
  const today = moment();

  const [remainingDuration, setRemainingDuration] = useState(
    moment.duration(closingDate.diff(today))
  );

  const [currentImgSrc, setCurrentImgSrc] = useState(
    tenderViewData?.tender[0]?.images
  );
  const sasToken = getSasToken();
  const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
  const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME;
  const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${tenderViewData?.tender[0]?.images}?${sasToken}`;

  const handleImageError = () => {
    setCurrentImgSrc(defaultBannerImg); // Set default image on error
  };

  useEffect(() => {
    dispatch(tenderView(tenderId));
    dispatch(fetchBidListTender(tenderId));
  }, [dispatch, tenderId]);

  useEffect(() => {
    if (tenderViewData && tenderViewData.tender[0]) {
      const closingDate = moment(
        tenderViewData.tender[0]?.tender_closing_date || ""
      );
      const now = moment();
      const duration = moment.duration(closingDate.diff(now));
      setClosingDate(closingDate);
      setRemainingDuration(duration);

      const interval = setInterval(() => {
        const now = moment();
        const duration = moment.duration(closingDate.diff(now));
        setRemainingDuration(duration);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [tenderViewData]);

  let bg;
  if (remainingDuration.asDays() <= 2) {
    bg = "danger";
  } else if (remainingDuration.asDays() <= 5) {
    bg = "warning";
  } else {
    bg = "success";
  }

  const checkCompanyId = (data, isAuthenticated) => {
    return data?.companyQuotations?.some(
      (quotation) => quotation?.user_id === isAuthenticated?.id
    );
  };

  const isCompanyIdMatch = checkCompanyId(bidList, isAuthenticated);

  return (
    <>
      <div className="main main-app tender-view p-3 p-lg-4">
        { loading ? (
          <TenderViewLoading />
        ) : (
          <div className="border border-secondary">
            <Container fluid className="p-0">
              <Row className="m-0">
                <Col className="p-0">
                  <div className={ styles.banner_container }>
                    <Image
                      src={ fileUrl }
                      className={ styles.banner_image }
                      fluid
                      onError={ handleImageError }
                    />
                    <div className={ styles.overlay_content }>
                      <div>
                        <ol className="breadcrumb fs-sm mb-1">
                          <li className="breadcrumb-item" aria-current="page">
                            <Link to="/dashboard">Dashboard</Link>
                          </li>
                          <li className="breadcrumb-item" aria-current="page">
                            <Link to="/tender_list">Tender</Link>
                          </li>
                          <li className="breadcrumb-item active">
                            { moduleName }
                          </li>
                        </ol>
                        <h4 className="main-title mb-0">{ moduleName }</h4>
                      </div>
                      <div className="d-flex gap-2">
                        { forBided ? (
                          <Link
                            to="/bids_on_external_tenders"
                            className="btn btn-primary icon-with-btn"
                          >
                            <i className="ri-arrow-left-s-line"></i>{ " " }
                            <span>Back to Bids on External Tenders</span>
                          </Link>
                        ) : (
                          <Link
                            to="/tender_list"
                            className="btn btn-primary icon-with-btn"
                          >
                            <i className="ri-arrow-left-s-line"></i>{ " " }
                            <span>Back to tender list</span>
                          </Link>
                        ) }

                        { tenderData?.user_id !== isAuthenticated.id &&
                          remainingDuration.asSeconds() > 0 &&
                          !isCompanyIdMatch && (
                            <AddBidModal tenderId={ tenderData?.id } />
                          ) }
                        <Badge className={ styles.remain_badge } bg={ bg }>
                          { remainingDuration.asSeconds() <= 0
                            ? "Tender Closed"
                            : `Remaining Time: ${formatRemainingTime(
                              remainingDuration
                            )}` }
                        </Badge>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className={ styles.info_wrapper }>
              { !loading && tenderData && <TenderView tenderData={ tenderData } rejectReson={ bidList?.customerQuotations[0]?.reject_reason } /> }
              { !loading && bidList && (
                <TenderBid
                  tenderAddUser={ tenderData?.user_id }
                  bidList={ bidList }
                  remainingDays={ remainingDuration.days() }
                  iscustomerQuotations={ bidList?.customerQuotations?.length }
                  tenderStatus={ tenderData?.tender_progress_type }
                />
              ) }
            </div>
          </div>
        ) }
        <Footer />
      </div>
    </>
  );
};

export default TenderViewPage;
