import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Collapse, Button, Table, Alert } from "react-bootstrap";
import useAuth from "../../../hooks/useAuth";
import { ROLES, FILTER_TYPE } from "../../../utils/types";
import TaskListTable from "./TaskListTable";
import TaskPersonList from "./TaskPersonList";
import TaskSortList from "./TaskSortList";
import TaskFilter from "./TaskFilter";
import TaskFormModal from "./TaskCreateModal";
import { getFiltersByPerson } from "../../../store/actions/projectManageActions";
import TableLoader from "./../../common/TableLoader";

const ProjectsTaskList = ({ ProjectTaskData }) => {
  const moment = require("moment");
  const isAuthenticated = useAuth();
  const dispatch = useDispatch();
  const [isOpenTitle, setIsOpenTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModalState, setShowModalState] = useState(false);
  const [filteredTaskList, setFilteredTaskList] = useState([]);
  const [filteredProjectId, setFilteredProjectId] = useState("");
  const [filteredType, setFilteredType] = useState("");
  const isCompany = isAuthenticated.user_role == ROLES.COMPANY;

  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentProjectName, setCurrentProjectName] = useState(null);

  const toggleFormModal = (id, name) => {
    setCurrentItemId(id);
    setCurrentProjectName(name);
    setShowModalState(!showModalState);
  };

  const toggleCollapse = (title) => {
    setIsOpenTitle(title === isOpenTitle ? "" : title);
  };

  const getPersonWiseFilters = async (assignee_id, project_id) => {
    setFilteredProjectId(project_id);
    setFilteredType(FILTER_TYPE.PERSON);
    const data = {
      project_id,
      assignee_id,
    };
    setIsLoading(true);
    const response = await dispatch(getFiltersByPerson(data));

    if (!response.payload) {
      setIsLoading(false);

      return false;
    } else {
      const task = response.payload?.tasks;
      const taskData = task?.data;
      setFilteredTaskList(taskData);
      setIsLoading(false);
    }
  };
  const getSortWiseFilters = async (statusList, orderType, project_id) => {
    setFilteredProjectId(project_id);
    setFilteredType(FILTER_TYPE.SORT);
    const data = {
      project_id,
      task_status: statusList,
      sort_order: orderType,
    };
    setIsLoading(true);
    const response = await dispatch(getFiltersByPerson(data));

    if (!response.payload) {
      setIsLoading(false);
      return false;
    } else {
      const task = response.payload?.tasks;
      const taskData = task?.data;
      setFilteredTaskList(taskData);
      setIsLoading(false);
    }
  };

  const getFilters = async (
    closingDate,
    selectedPersonOption,
    selectedTaskStatusOption,
    project_id
  ) => {
    setIsLoading(true);
    setFilteredProjectId(project_id);
    setFilteredType(FILTER_TYPE.FILTER);
    const data = {
      project_id,
    };
    if (selectedPersonOption != null) {
      data.assignee_id = [selectedPersonOption.value];
    }
    if (selectedTaskStatusOption.length > 0) {
      let list = [];
      selectedTaskStatusOption.map((a) => {
        list.push(a.value);
      });
      data.task_status = list;
    }
    if (closingDate != "") {
      data.due_date = closingDate;
    }
    const response = await dispatch(getFiltersByPerson(data));

    if (!response.payload) {
      setIsLoading(false);
      return false;
    } else {
      const task = response.payload?.tasks;
      const taskData = task?.data;
      setFilteredTaskList(taskData);
      setIsLoading(false);
    }
  };
  return (
    <div className="project-table">
      {ProjectTaskData && ProjectTaskData.length > 0 ? (
        ProjectTaskData.map((item) => (
          <div key={item.id}>
            <div className="d-flex justify-content-between align-items-center mb-2 border rounded bg-gray-200">
              <Button
                onClick={() => toggleCollapse(item.id)}
                aria-controls="example-collapse-text "
                variant="link"
                aria-expanded={isOpenTitle === item.id}
                className="icon-with-btn text-dark "
              >
                {isOpenTitle === item.id ? (
                  <i className="ri-arrow-down-s-line"></i>
                ) : (
                  <i className="ri-arrow-right-s-line"></i>
                )}
                {item.project_title}
              </Button>
              <div className="d-flex">
                <TaskPersonList
                  PersonList={item.assignees}
                  getPersonWiseFilters={(assignee_id) =>
                    getPersonWiseFilters([assignee_id], item.id)
                  }
                  filteredProjectId={filteredProjectId}
                  filteredType={filteredType}
                />
                <TaskSortList
                  getSortWiseFilters={(status_list, order_type) =>
                    getSortWiseFilters(status_list, order_type, item.id)
                  }
                  filteredProjectId={filteredProjectId}
                  filteredType={filteredType}
                />
                <TaskFilter
                  PersonList={item.assignees}
                  filteredProjectId={filteredProjectId}
                  filteredType={filteredType}
                  clearFilter={() => {
                    setFilteredType("");
                    setFilteredTaskList("");
                  }}
                  getFilters={(
                    closingDate,
                    selectedPersonOption,
                    selectedTaskStatusOption
                  ) =>
                    getFilters(
                      closingDate,
                      selectedPersonOption,
                      selectedTaskStatusOption,
                      item.id
                    )
                  }
                />
                <div style={{ alignContent: "flex-end" }}>
                  <Button
                    variant="link"
                    className="align-items-center icon-with-btn text-dark "
                    onClick={() => {
                      setFilteredType("");
                      setFilteredTaskList("");
                    }}
                  >
                    <i className="ri-filter-off-line fs-5"></i> Clear Filters
                  </Button>
                </div>
              </div>
            </div>
            <Collapse in={isOpenTitle === item.id}>
              <div id="task-collapse-text">
                <Card className="border-0 p-0">
                  <Card.Body className="border-0 p-0">
                    <Table className="mb-0 table table-hover table-bordered mb-2">
                      {isLoading ? (
                        <TableLoader rowCount={4} columnCount={6} />
                      ) : (
                        <>
                          <thead>
                            <tr>
                              <th width="5%">Id</th>
                              <th width="30%">Task</th>
                              <th>Person</th>
                              <th>Status</th>
                              <th width="12%">Task Closing Date</th>
                              <th width="10%">Attachment</th>
                              <th width="9%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <TaskListTable
                              taskList={
                                filteredType != "" &&
                                item.id === filteredProjectId
                                  ? filteredTaskList
                                  : item
                                  ? item.tasks
                                  : []
                              }
                              projectName={item.project_title}
                            />

                            {isCompany && (
                              <tr>
                                <td colSpan="6" className="text-left">
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      toggleFormModal(
                                        item.id,
                                        item.project_title
                                      )
                                    }
                                  >
                                    + Add Task
                                  </Button>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </>
                      )}
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            </Collapse>
          </div>
        ))
      ) : (
        <Alert
          variant="primary"
          className="d-flex align-items-center mb-2 w-100"
        >
          <i className="ri-information-line"></i> No data available in the
          table.
        </Alert>
      )}
      <TaskFormModal
        show={showModalState}
        toggleFormModal={toggleFormModal}
        projectId={currentItemId}
        projectName={currentProjectName}
      />
    </div>
  );
};

export default ProjectsTaskList;
