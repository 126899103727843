import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectList, fetchProjectInfo } from "../actions/projectActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  projectListData: null,
  projectInfoData: null,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // project list
      .addCase(fetchProjectList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchProjectList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.projectListData = action.payload;
      })
      .addCase(fetchProjectList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      })

      //project info
      .addCase(fetchProjectInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchProjectInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.projectInfoData = action.payload;
      })
      .addCase(fetchProjectInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      });
  },
});

export default projectSlice.reducer;
