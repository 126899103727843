import React, { useEffect } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { fetchProjectList } from "../../../store/actions/projectActions";
import SingleCard from "../../common/SingleCard";
import CardLoading from "../../common/CardLoading";

const ProjectList = () => {
  const dispatch = useDispatch();
  const { loading, projectListData } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(fetchProjectList());
  }, [dispatch]);

  return (
    <>
      {projectListData?.length > 0 ? (
        <Row xs={1} md={3} className="g-3">
          {projectListData?.map((item) => (
            <Col key={item.project_title}>
              {loading ? (
                <CardLoading />
              ) : (
                <SingleCard
                  imgSrc={item.images}
                  title={item.project_title}
                  text={item.project_description}
                  status={item.is_completed}
                  viewMoreUrl={`/project-view?projectId=${item.id}&projectName=${item.project_title}`}
                />
              )}
            </Col>
          ))}
        </Row>
      ) : (
        <Alert
          variant="primary"
          className="d-flex align-items-center mb-2 w-100"
        >
          <i className="ri-information-line"></i> There are no related projects
          available.
        </Alert>
      )}
    </>
  );
};

export default ProjectList;
