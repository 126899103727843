import React from "react";
import MenuBar from "../components/home/MenuBar";
import SupportBanner from "../assets/img/support_banner.jpg"
import CustomerSupportForm from "../components/customer-support/CustomerSupportForm"
import Footer from "../components/home/Footer";

const HelpAndSupport = () => {
  return (
    <div className="page-home">
      <MenuBar />
      <div className="position-relative" style={ { height: '400px', marginTop: '-137px' } }>
        <div
          className="position-absolute top-0 start-0 w-100 h-100"
          style={ {
            backgroundImage: `url(${SupportBanner})`,
            backgroundSize: 'cover',
          } }
        ></div>
        <div
          className="position-absolute top-0 start-0 w-100 h-100"
          style={ { backgroundColor: 'rgba(35, 35, 64, 0.5)' } }
        ></div>
        <div className="position-relative" style={ { zIndex: 1 } }>
        </div>
      </div>



      <div className="work-preference pd-top-60 pd-bottom-60 bg-light-gray">
        <div className="container">
          <CustomerSupportForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpAndSupport;
