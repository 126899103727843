import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

import pageSvg from "../../assets/svg/pair_programming.svg";
import { acceptCustomerQuotation } from "../../store/actions/tenderActions";

const AcceptQuotation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let quotation_id = searchParams.get("quotation_id");
  if (quotation_id) {
    quotation_id = parseInt(quotation_id, 10);
  }

  const [acceptQuotationData, setAcceptQuotationData] = useState({
    customer_quotation_id: quotation_id,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(acceptCustomerQuotation(acceptQuotationData)).then((result) => {
      if (
        result.payload.message ===
        "Project created successfully and chat rooms allocated."
      )
        navigate("/my_projects");
      localStorage.removeItem("intendedUrl");
    });
  };

  return (
    <>
      <div className="main main-app">
        <div className="page-auth">
          <div className="content">
            <Container>
              <Card className="card-auth">
                <Card.Body className="text-center">
                  <div className="mb-5">
                    <img src={ pageSvg } alt="Email veryfication" width={ 200 } />
                  </div>
                  <Card.Title>Continue your quotation process</Card.Title>
                  <Card.Text className="mb-5">
                    Please click the done button, To continue your accepting
                    quotation process.
                  </Card.Text>

                  <Row className="g-2 g-sm-3">
                    <Col lg>
                      <Button
                        variant="primary"
                        className="btn-sign"
                        onClick={ handleSubmit }
                      >
                        Done
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptQuotation;
