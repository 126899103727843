import React, { useEffect } from "react";
import { Badge, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchOnboardUsersList } from "../../store/actions/userManageActions";
import Footer from "../../components/dashboard/Footer";
import { formatDate } from "../../utils/utils";
import PagintionWithDataTable from "../../components/common/PagintionWithDataTable";
import UserInfoView from "../../components/dashboard/user-manage/UserInfoView";
import UserApproval from "../../components/dashboard/onboardUser/UserApproval";

const OnboardUsers = () => {
  const moduleName = "Onboard Users";
  const dispatch = useDispatch();
  const { onboardUserList, isLoading, error } = useSelector(
    (state) => state.usersManage
  );

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "75px",
    },
    {
      name: "Full Name",
      selector: (row) => row.first_name + " " + row.last_name,
    },
    {
      name: "Create Date",
      selector: (row) => formatDate(row.updated_at),
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "User Role",
      selector: (row) => (
        <strong className="text-capitalize text-dark">
          { row.user_role ? row.user_role.role_name : "N/A" }
        </strong>
      ),
    },
    {
      name: "Onboard process status",
      selector: (row) => (
        <Badge
          bg={ `${row.is_onboard === 0 && row.is_pending === 1 ? "danger" : "success"
            }` }
        >
          { row.is_onboard === 0 && row.is_pending === 1
            ? "Document Upload Pending"
            : "Admin Approval Pending" }
        </Badge>
      ),
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-2">
          <UserInfoView userId={ row.id } />
          <UserApproval
            user={ row }
            onApprove={ () => dispatch(fetchOnboardUsersList()) }
            isOnboard={ row.is_onboard }
            isPending={ row.is_pending }
          />
        </div>
      ),
      width: "150px",
    },
  ];

  useEffect(() => {
    dispatch(fetchOnboardUsersList());
  }, [dispatch]);

  const rows = Array.isArray(onboardUserList.userdet)
    ? onboardUserList.userdet?.map(
      ({
        id,
        first_name,
        last_name,
        updated_at,
        email,
        status,
        is_onboard,
        is_pending,
        user_role,
      }) => ({
        id,
        first_name,
        last_name,
        updated_at,
        email,
        status,
        is_onboard,
        is_pending,
        user_role,
      })
    )
    : [];

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{ moduleName }</li>
            </ol>
            <h4 className="main-title mb-0">{ moduleName }</h4>
          </div>
        </div>
        <div className="inner-wrapper">
          <PagintionWithDataTable
            dataColumns={ columns }
            entities={ rows }
            loading={ isLoading }
            rowCount={ 4 }
            columnCount={ 8 }
          />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default OnboardUsers;
