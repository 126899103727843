import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialBg from "../../assets/img/bg/11.png";
import quoteIcon from "../../assets/img/testimonial/01.png";
import "../../assets/styles/components/home/FeedbackCarousel.scss";

import Rating from "react-rating-stars-component";
import FeedbackForm from "./FeedbackForm";

const FeedbackCarousel = ({ feedbackData }) => {
  /* function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <i className="ri-arrow-right-s-line" className={className} onClick={onClick}></i>;
    
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <i className="ri-arrow-left-s-line" className={className} onClick={onClick}></i>;
    
  } */
  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    //nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div
        className="testimonial-area pd-top-60 pd-bottom-60 bg-gray"
        style={{ backgroundImage: `url(${TestimonialBg})` }}
      >
        <div className="container">
          <div className="section-title text-center mb-5">
            <h2 className="title">What People Say About Us</h2>
            <FeedbackForm />
          </div>
          <div className="testimonial-slider  slider-control-round slider-control-dots slider-control-right-top">
            <Slider {...settings}>
              {feedbackData.map((feedback, index) => (
                <div className="item" key={feedback.id}>
                  <div className="single-testimonial-inner testimonial-content text-center">
                    <h5>{feedback.userName}</h5>
                    <div className="icon mb-2">
                      <img src={quoteIcon} alt="img" />
                    </div>
                    <p>{feedback.feedbackText}</p>
                    <div className="ratting-inner mt-4 d-flex justify-content-center">
                      <Rating
                        value={feedback.rating}
                        count={5}
                        size={24}
                        edit={false}
                        color="#ffd700"
                      />
                    </div>
                    <div className="thumb">
                      <img src={feedback.userProfileImage} alt="img" />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackCarousel;
