import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Header from "../components/dashboard/Header";

const OnboardLayout = () => {
  const isAuthenticated = useAuth();

  return isAuthenticated && isAuthenticated.is_onboard === 0 ? (
    <>
      <Header sidebarShow={ false } />
      <Outlet />
    </>
  ) : (
    <Navigate to="/signin" />
  );
};

export default OnboardLayout;
