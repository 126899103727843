import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Row, Form, Spinner } from "react-bootstrap";

import {
  verifyCode,
  checkCode,
  updatePassword,
} from "../store/actions/authActions";
import Logo from "../assets/img/icon_mob.png";
import bg from "../assets/img/bg/login.jpg";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showVerificationCodeFields, setShowVerificationCodeFields] =
    useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    verificationCode: "",
    newPassword: "",
    repeatPassword: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [ResponseVerificationCode, setResponseVerificationCode] =
    useState(false);

  const isEmailValid = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const isPasswordMatch = () => {
    return credentials.newPassword === credentials.repeatPassword;
  };

  const handleGetVerificationCode = async (event) => {
    if (!credentials.email || !isEmailValid(credentials.email)) {
      setEmailError(true);
    } else {
      event.preventDefault();
      setLoading(true);
      try {
        const response = await dispatch(verifyCode(credentials));
        if (response.payload.status == 200) {
          setEmailError(false);
          setShowVerificationCodeFields(true);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const handleVerify = async (event) => {
    event.preventDefault();
    const credentialsWithVerificationCode = {
      ...credentials,
      verificationCode: credentials.verificationCode,
    };

    dispatch(checkCode(credentialsWithVerificationCode)).then((result) => {
      if (result.payload) {
        setResponseVerificationCode(true);
      }
    });
  };

  const handleValueChange = (e) => {
    const targetInput = e.target;
    const inputName = targetInput.name;
    const inputValue = targetInput.value;

    setCredentials({
      ...credentials,
      [inputName]: inputValue,
    });

    setEmailError(false);
    setPasswordsMatch(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isPasswordMatch()) {
      setPasswordsMatch(false);
      return;
    }
    await dispatch(updatePassword(credentials)).then((result) => {
      if (result.payload) {
        navigate("/signin");
      } else {
        return;
      }
    });
  };

  return (
    <>
      <div className="page-sign" style={{ backgroundImage: `url(${bg})` }}>
        <Card className="card-sign forgot-password">
          <Card.Body className="text-center">
            <Link to="/" className="d-flex mb-4 justify-content-center">
              <img src={Logo} alt="" width={140} />
            </Link>
            <Card.Title>Reset your password</Card.Title>
            <Card.Text className="mb-4">
              {!showVerificationCodeFields
                ? "Enter your email address and we will send you a verification code to reset your password."
                : "Enter your verification code to reset your password."}
            </Card.Text>
            {loading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <>
                {!showVerificationCodeFields && (
                  <Row className="g-2">
                    <Form.Control
                      type="email"
                      required
                      id="email"
                      value={credentials.email}
                      onChange={handleValueChange}
                      name="email"
                      autoComplete="email"
                      autoFocus
                      isInvalid={emailError}
                      placeholder="Enter email address"
                    />
                    {emailError && (
                      <Form.Control.Feedback type="invalid">
                        Enter a valid email address
                      </Form.Control.Feedback>
                    )}

                    <Button onClick={handleGetVerificationCode}>
                      Get Verification Code
                    </Button>
                  </Row>
                )}

                {showVerificationCodeFields && !ResponseVerificationCode && (
                  <Row className="g-2">
                    <Form.Control
                      type="text"
                      required
                      id="verificationCode"
                      value={credentials.verificationCode}
                      onChange={handleValueChange}
                      name="verificationCode"
                      placeholder="Enter Verification Code"
                    />

                    <Button type="submit" onClick={handleVerify}>
                      Verify
                    </Button>
                  </Row>
                )}

                {ResponseVerificationCode && (
                  <Row className="g-2 mt-1">
                    <Form.Control
                      required
                      id="newPassword"
                      value={credentials.newPassword}
                      onChange={handleValueChange}
                      name="newPassword"
                      type="password"
                      placeholder="Enter New Password"
                    />

                    <Form.Control
                      required
                      id="repeatPassword"
                      value={credentials.repeatPassword}
                      onChange={handleValueChange}
                      name="repeatPassword"
                      type="password"
                      placeholder="Repeat Password"
                      isInvalid={!passwordsMatch}
                    />
                    {!passwordsMatch && (
                      <Form.Control.Feedback type="invalid">
                        Passwords do not match
                      </Form.Control.Feedback>
                    )}

                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!passwordsMatch}
                    >
                      Reset Password
                    </Button>
                  </Row>
                )}
              </>
            )}
          </Card.Body>
          <Card.Footer>
            Know your password? <Link to="/signin">Sign In</Link>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
}
