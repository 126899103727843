import React, { useState, useEffect } from "react";
import { Button, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "../../common/FormModal";
import { createTender } from "../../../store/actions/tenderActions";
import TenderForm from "./TenderForm";
import LimitationExceedModal from "../../common/LimitationExceedModal";
import { useForm } from "react-hook-form";

const CreateNewTender = ({ onTenderCreated }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.auth.userInfo);
  const tenderList = useSelector((state) => state.tender.tenderList?.tenders);
  const { loading } = useSelector((state) => state.tender);
  const [closingDate, setClosingDate] = useState(new Date());
  const [showModalState, setShowModalState] = useState(false);
  const [uploadFileName, setUploadFlename] = useState("");
  const [showExceedPackageModalState, setShowExceedPackageModalState] = useState(false);
  const [filteredTenders, setFilteredTenders] = useState([]);

  useEffect(() => {
    const userTenders = tenderList && tenderList.filter(tender => tender.user_id === loggedUser.id);
    setFilteredTenders(userTenders);
  }, [tenderList]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customer_name: "",
      customer_email: "",
      tender_name: "",
      tender_description: "",
      tender_type: "",
      tender_option: "",
      tender_closing_date: "",
      attachments: "",
      images: "",
    },
  });

  const handleTenderCreate = () => {
    const tenderLimit = loggedUser?.package_detais?.tender_limit;
    if (filteredTenders?.length >= tenderLimit && loggedUser.user_role !== "1") {
      showExceedPackageModal()
    } else {
      toggleFormModal()
    }
  }

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
    reset();
  };

  const closeExceedPackageModal = () => {
    setShowExceedPackageModalState(false)
  }
  const showExceedPackageModal = () => {
    setShowExceedPackageModalState(true)
  }

  const onSubmit = async (data) => {
    const dateString = closingDate;
    const dateObject = new Date(dateString);
    const formatClosingDate = dateObject
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    data.tender_closing_date = formatClosingDate;

    dispatch(createTender(data)).then(() => {
      if (onTenderCreated) {
        onTenderCreated();
      }
      toggleFormModal();
    });
  };

  return (
    <>
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip>Create Tender</Tooltip>}
      >
        <Button variant="secondary" onClick={handleTenderCreate}>
          <i className="ri-user-add-line"></i> Create tender
        </Button>
      </OverlayTrigger>
      <FormModal
        moduleName={"Create new tender"}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="600px"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TenderForm
            register={register}
            watch={watch}
            errors={errors}
            loading={loading}
            closingDate={closingDate}
            setClosingDate={setClosingDate}
            setValue={setValue}
            uploadFileName={uploadFileName}
            setUploadFlename={setUploadFlename}
            reset={reset}
          />
          <div className="d-flex flex-wrap gap-2 mt-3">
            <Button
              type="button"
              disabled={loading}
              variant="outline-primary"
              className="btn-sign"
              onClick={() => {
                reset();
                toggleFormModal();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="btn-sign"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Create tender
                </>
              ) : (
                "Create tender"
              )}
            </Button>
          </div>
        </Form>
      </FormModal>
      <LimitationExceedModal
        showViewModal={showExceedPackageModalState}
        closeModal={closeExceedPackageModal}
        name="tender limit"
      />
    </>
  );
};

export default CreateNewTender;
