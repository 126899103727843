import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {
  registerUser,
  userLogin,
  logoutUser,
  emailVerificationLink,
  accountActivate,
  verifyCode,
  checkCode,
  updatePassword,
  userUpgradePackage,
  userDowngradePackage
} from "../actions/authActions";

const initialState = {
  loading: false,
  userRetister: null,
  userInfo: null,
  error: null,
  success: false,
  sendEmilVerification: null,
  accountActivated: "idle",
  verifyCode: "idle",
  checkCode: "idle",
  updatePassword: "idle",
  userPackageExpired: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setUserPackageExpired: (state) => {
      state.userPackageExpired = true;
    },
    setUserPackageNotExpired: (state) => {
      state.userPackageExpired = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //user Register
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.userRetister = action.payload;
          state.success = true;
        } else {
          state.success = false;
        }
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload.message || "Failed to create user. Please try again.";
        state.success = false; // reset success status
      })

      //send email verification link
      .addCase(emailVerificationLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(emailVerificationLink.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.sendEmilVerification = action.payload;
      })
      .addCase(emailVerificationLink.rejected, (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = action.payload.message;
      })

      //user Login
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
        state.success = true;

        /* if (action.payload.status === 1) {
          state.userInfo = action.payload;
          state.success = true;
        } else {
          state.error = "Failed to log in. Account not activated.";
          state.success = false;
        } */
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.message || "Failed to log in. Please try again.";
        state.success = false;
      })

      //account activate
      .addCase(accountActivate.fulfilled, (state, action) => {
        if (action.payload.status === "error") {
          state.accountActivated = "failed";
        } else {
          state.accountActivated = "succeeded";
          state.accountActivated = action.payload;
        }
      })
      .addCase(accountActivate.pending, (state) => {
        state.accountActivated = "loading";
      })
      .addCase(accountActivate.rejected, (state, action) => {
        state.accountActivated = "failed";
      })

      //Forgot password
      .addCase(verifyCode.fulfilled, (state, action) => {
        state.verifyCode = "succeeded";
        state.verifyCode = action.payload;
      })
      .addCase(verifyCode.pending, (state) => {
        state.verifyCode = "loading";
      })
      .addCase(verifyCode.rejected, (state) => {
        state.verifyCode = "failed";
      })

      //check code
      .addCase(checkCode.fulfilled, (state, action) => {
        if (action.payload === true) {
          state.success = true;
          state.checkCode = action.payload;
        } else {
          state.success = false;
        }
      })
      .addCase(checkCode.pending, (state) => {
        state.checkCode = "loading";
      })
      .addCase(checkCode.rejected, (state) => {
        state.checkCode = "failed";
      })

      //update passowrd
      .addCase(updatePassword.fulfilled, (state, action) => {
        if (action.payload === true) {
          state.success = true;
          state.updatePassword = action.payload;
        } else {
          state.success = false;
        }
      })
      .addCase(updatePassword.pending, (state) => {
        state.updatePassword = "loading";
      })
      .addCase(updatePassword.rejected, (state) => {
        state.updatePassword = "failed";
      })

      //user logout
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        Cookies.remove("userToken");
        Cookies.remove("refreshToken");
        Cookies.remove("sasToken");
        return initialState;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //Upgrade Package
      .addCase(userUpgradePackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userUpgradePackage.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        if (action.payload) {
          state.userInfo = action.payload;
        }
      })
      .addCase(userUpgradePackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "Failed to upgrade package. Please try again.";
        state.success = false;
      })

      //Upgrade Package
      .addCase(userDowngradePackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userDowngradePackage.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
        state.success = true;
      })
      .addCase(userDowngradePackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "Failed to downgrade package. Please try again.";
        state.success = false;
      });

  },
});

export const { setCredentials, clearError, setTokenAndExpiry, setUserPackageExpired, setUserPackageNotExpired } =
  authSlice.actions;
export default authSlice.reducer;
