// Button.js
import React from "react";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const Btn = ({
  onClick,
  onLongPress,
  title,
  iconLeft,
  iconRight,
  //style,
  styleClass,
  textStyle,
  disabled,
  loading,
  type,
  size,
  navigateTo
}) => {

  const navigate = useNavigate();

  const handleClick = () => {
    if (navigateTo) {
      navigate(navigateTo);
    } else {
      onClick();
    }
  };

  return (
    <Button
      onClick={handleClick}
      onContextMenu={onLongPress}
      disabled={disabled}
      className={`btn ${styleClass}`}
      type={type}
      size={size}
    >
      {iconLeft}
      {loading ? (
        <span>Loading...</span>
      ) : (
        <span style={textStyle}>{title} </span>
      )}
      {iconRight}
    </Button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  onLongPress: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  //style: PropTypes.object,
  styleClass: PropTypes.string,
  textStyle: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.string,
  navigateTo: PropTypes.string,
};

Button.defaltProps = {
  styleClass: "btn-primary",
};

export default Btn;
