import React, { useState } from "react";
import { Card, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getSasToken } from "../../utils/utils";
import DefaultImg from "../../assets/img/CardImage.jpg";

const SingleCard = ({ imgSrc, title, text, status, viewMoreUrl }) => {
  const [currentImgSrc, setCurrentImgSrc] = useState(imgSrc);
  const sasToken = getSasToken();
  const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
  const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME;
  const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${imgSrc}?${sasToken}`;

  const handleImageError = () => {
    setCurrentImgSrc(DefaultImg); // Set default image on error
  };
  return (
    <Card className="shadow">
      <Link to={viewMoreUrl} rel="noopener noreferrer">
        <Card.Img
          src={fileUrl}
          alt={title}
          onError={handleImageError}
          className="card-img-top"
        />
      </Link>
      <div className="position-absolute top-10 start-10">
        {status === 0 ? (
          <Badge bg="danger">Ongoing</Badge>
        ) : (
          <Badge bg="success">Completed</Badge>
        )}
      </div>
      <Card.Body>
        <Card.Title className="text-dark">{title}</Card.Title>
        <Card.Text className="three-lines">{text}</Card.Text>
        <Link className="btn btn-primary" to={viewMoreUrl}>
          View more
        </Link>
      </Card.Body>
    </Card>
  );
};

export default SingleCard;
