import React, { useState } from "react";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: "200px",
    color: "black",
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: "200px",
    color: "black",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#444",
    color: "white",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "white",
    ":hover": {
      backgroundColor: "#ff5630",
      color: "white",
    },
  }),
};

const SearchSelect = ({
  options,
  selectedOption,
  handleChange,
  isMultiValue = false,
  disabled = false,
}) => {
  return (
    <div style={{ minWidth: "100px" }}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        styles={customStyles}
        isSearchable
        placeholder="Search and select..."
        isMulti={isMultiValue}
        isDisabled={disabled}
      />
    </div>
  );
};

export default SearchSelect;
