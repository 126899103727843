export const ROLES = {
  ADMIN: 1,
  CUSTOMER: 2,
  COMPANY: 3,
  DELIVERY: 4,
  FIELD: 5,
};

export const LOGOUT_USER = "LOGOUT_USER";

export const TENDER_TYPES = {
  RFQ: "Request for Quotation (RFQ)",
  RFP: "Request for Proposal (RFP)",
  ITT: "Invitation to Tender (ITT)",
};

export const TENDER_OPTIONS = {
  ALL: "All",
  FIXED_PRICE: "Fixed Price",
  HOURLY_RATE: "Hourly Rate",
  MILESTONE: "Milestone-based",
};

export const projectUserTypes = [
  { id: 2, type: "Service Provider" },
  { id: 3, type: "3rd Party Company" },
  { id: 4, type: "Field Visit" },
  { id: 5, type: "Delivery Agent" },
];

export const TASK_STATUS_TYPE_LABEL = {
  IN_PROGRESS: "In Progress",
  DONE: "Done",
  HOLD: "On Hold",
  TODO: "To Do",
  VERIFY: "Verify",
  CANCEL: "Cancel",
};

export const TASK_STATUS_TYPE = [
  {
    id: 1,
    name: TASK_STATUS_TYPE_LABEL.IN_PROGRESS,
  },
  {
    id: 2,
    name: TASK_STATUS_TYPE_LABEL.DONE,
  },
  {
    id: 3,
    name: TASK_STATUS_TYPE_LABEL.HOLD,
  },
  {
    id: 4,
    name: TASK_STATUS_TYPE_LABEL.TODO,
  },
  {
    id: 5,
    name: TASK_STATUS_TYPE_LABEL.VERIFY,
  },
  {
    id: 6,
    name: TASK_STATUS_TYPE_LABEL.CANCEL,
  },
];

export const TENDER_PROGRESS = [
  { id: 1, type: "Open to bidding", bg: "success" },
  { id: 2, type: "Cancelled", bg: "danger" },
  { id: 3, type: "Customer quotation created", bg: "warning" },
  { id: 4, type: "Quotation sent to customer", bg: "info" },
  { id: 5, type: "The customer accepted the quotation", bg: "success" },
  { id: 6, type: "The customer rejected the quotation", bg: "danger" },
  { id: 7, type: "Bidding is over", bg: "danger" },
];

export const SAVE_FEEDBACK_SUCCESS = "SAVE_FEEDBACK_SUCCESS";

export const FILTER_TYPE = {
  PERSON: "PERSON",
  SORT: "SORT",
  FILTER: "FILTER",
 
};