import React, { useState } from "react";
import { Button, Dropdown, Alert as NewAlert, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getTaskStatusColor } from "../../../utils/utils";
import TaskFormModal from "./TaskCreateModal";
import {
  TASK_STATUS_TYPE,
  TASK_STATUS_TYPE_LABEL,
  ROLES,
} from "../../../utils/types";
import AddReviewForTask from "./AddReviewForTask";
import useAuth from "../../../hooks/useAuth";
import DownloadButton from "../../common/DownloadButton";
import { taskStatusUpdate } from "../../../store/actions/projectManageActions";
import { get, post, put } from "../../../servces/Api";
import Alert from "../../common/Alert";

const TaskListTable = ({ taskList, projectName = "" }) => {
  const isAuthenticated = useAuth();
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState(taskList);
  const { isLoading } = useSelector((state) => state.projectManage);
  const [selectedStatusValues, setSelectedStatusValues] = useState({});
  const [showModalState, setShowModalState] = useState(false);
  const [addReviewShow, setAddReviewShow] = useState(false);
  const [editData, setEditData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  const userRoleNumber = parseInt(isAuthenticated?.user_role, 10);
  const isCompany = userRoleNumber === ROLES.COMPANY;

  const handleSelect = async (taskId, statusId) => {
    const data = {
      task_id: taskId,
      task_status_id: statusId,
    };
    await setStatusData(data);
    checkStatus(statusId, data);
  };

  const toggleFormModal = (data) => {
    setEditData(data);
    setShowModalState(!showModalState);
  };

  const updateTaskStatus = async (data) => {
    setStatusData(data);
    setIsStatusUpdating(true);
    // dispatch(taskStatusUpdate(data));
    const taskId = data.task_id;
    const statusId = data.task_status_id;
    try {
      const response = await dispatch(taskStatusUpdate(data));
      if (response?.payload?.status === 200) {
        const updatedTasks = tasks.map((task) =>
          task.id === taskId ? { ...task, task_status_id: statusId } : task
        );
        setTasks(updatedTasks);
        setSelectedStatusValues({
          ...selectedStatusValues,
          [taskId]: statusId,
        });
        setIsStatusUpdating(false);
        return true;
      }
    } catch (error) {
      // server error
      Alert.error("Failed to update your task status.Please try again later.");
      setIsStatusUpdating(false);
      return false;
    }
  };

  const toggleFormModalReview = () => {
    setAddReviewShow(!addReviewShow);
  };

  const handleReviewSubmit = (reviewData) => {
    const status = TASK_STATUS_TYPE.find(
      (status) => status.id === statusData.task_status_id
    );
    if (statusData && reviewData) {
      if (status.name === TASK_STATUS_TYPE_LABEL.DONE) {
        statusData.task_end_feedback_rate = reviewData.task_end_feedback_rate;
        updateTaskStatus(statusData);
        toggleFormModalReview();
      } else if (status.name === TASK_STATUS_TYPE_LABEL.VERIFY) {
        statusData.assignee_comment = reviewData.assignee_comment;
        updateTaskStatus(statusData);
        toggleFormModalReview();
      }
    }
  };

  const checkStatus = (statusId, data) => {
    const status = TASK_STATUS_TYPE.find((status) => status.id === statusId);
    if (
      status.name === TASK_STATUS_TYPE_LABEL.DONE ||
      status.name === TASK_STATUS_TYPE_LABEL.VERIFY
    ) {
      setAddReviewShow(true);
    } else {
      updateTaskStatus(data);
    }
  };

  const getStatusName = (id) => {
    const selectedStatus = TASK_STATUS_TYPE.find((status) => status.id === id);
    return selectedStatus ? selectedStatus.name : "";
  };

  const showStatus = (id) => {
    const allowedStatusForNoCompany = [1, 4, 5];

    if (!isCompany && !allowedStatusForNoCompany.includes(id)) return false;

    return true;
  };

  return (
    <>
      {tasks && tasks.length > 0 ? (
        tasks.map((list) => (
          <tr key={list.id} style={{ verticalAlign: "middle" }}>
            <td>{list.id}</td>
            <td>{list.task_title}</td>
            <td>{`${list.assignee.first_name} ${list.assignee.last_name}`}</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle
                  variant={getTaskStatusColor(
                    selectedStatusValues[list.id] || list.task_status_id
                  )}
                  className="w-100 d-flex justify-content-between align-items-center border-0"
                  id={`dropdown-${list.id}`}
                  title={
                    selectedStatusValues[list.id]
                      ? getStatusName(selectedStatusValues[list.id])
                      : getStatusName(list.task_status_id)
                  }
                  disabled={
                    (statusData?.task_id == list.id && isStatusUpdating) ||
                    list.task_status_id === 6
                  }
                >
                  {statusData?.task_id == list.id && isStatusUpdating && (
                    <Spinner size="sm" />
                  )}
                  {selectedStatusValues[list.id]
                    ? getStatusName(selectedStatusValues[list.id])
                    : getStatusName(list.task_status_id)}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100">
                  {TASK_STATUS_TYPE.map(
                    (status) =>
                      showStatus(status.id) && (
                        <Dropdown.Item
                          onClick={() => handleSelect(list.id, status.id)}
                          key={status.id}
                          eventKey={status.id}
                        >
                          {status.name}
                        </Dropdown.Item>
                      )
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </td>
            <td>{list.task_closing_date}</td>
            <td>
              {list.attachments && list.attachments !== "" ? (
                <DownloadButton
                  fileName={list.attachments}
                  toolTip="Download attachment"
                  variant="secondary"
                  iconClassName="ri-attachment-2"
                />
              ) : (
                "Not uploaded"
              )}
            </td>
            <td>
              <Button
                variant="primary"
                size="sm"
                className="ms-3"
                onClick={() => toggleFormModal(list)}
              >
                {isCompany ? "View /Edit" : "View"}
              </Button>
            </td>
          </tr>
        ))
      ) : (
        <>
          <tr>
            <td colSpan="7" className="text-center">
              <NewAlert
                variant="primary"
                className="d-flex align-items-center mb-2 w-100"
              >
                <i className="ri-information-line"></i> No data available in the
                table.
              </NewAlert>
            </td>
          </tr>
        </>
      )}
      <TaskFormModal
        show={showModalState}
        toggleFormModal={toggleFormModal}
        isEdit={true}
        editData={editData}
        projectId={editData ? editData.project_id : null}
        projectName={projectName}
      />
      <AddReviewForTask
        showValue={addReviewShow}
        toggleFormModalReview={toggleFormModalReview}
        status={statusData?.task_status_id || 1}
        onSubmit={handleReviewSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

export default TaskListTable;
