import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUsersList,
  fetchOnboardUsersList,
  updateUserStatus,
  userApproveByAdmin,
} from "../actions/userManageActions";

const initialState = {
  data: {
    userdet: [],
    company: [],
    user_role: [],
  },
  userStatus: {},
  userApprove: {},
  onboardUserList: {
    userdet: [],
  },
  isLoading: false,
  error: null,
};

const userManageSlice = createSlice({
  name: "userManage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //users list
      .addCase(fetchUsersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchUsersList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      //onboard user list
      .addCase(fetchOnboardUsersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOnboardUsersList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.onboardUserList = action.payload;
      })
      .addCase(fetchOnboardUsersList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      //update user status
      .addCase(updateUserStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userStatus = action.payload;
      })
      .addCase(updateUserStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      //Approve user by Admin
      .addCase(userApproveByAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userApproveByAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userApprove = action.payload;
      })
      .addCase(userApproveByAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default userManageSlice.reducer;
