import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import router from "./routes/Router";
import { logoutUser } from "./store/actions/authActions";
import { store, persistor } from "./store/store";
import { LOGOUT_USER } from "./utils/types";

// import css
import "./assets/css/remixicon.css";
import "./assets/styles/style.scss";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        handleTabFocus();
      }
    };

    // Add event listener when component mounts
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up: remove event listener when component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleTabFocus = () => {
    const isLogout = localStorage.getItem("logout");
    const userToken = Cookies.get("userToken");
    const refreshToken = Cookies.get("refreshToken");
    const sasToken = Cookies.get("sasToken");

    if (isLogout && !userToken && !refreshToken && !sasToken) {
      store.dispatch({ type: LOGOUT_USER });
      persistor.purge();
    }
  };

  return (
    <>
      <HelmetProvider>
        <Toaster />
        <RouterProvider router={router} />
      </HelmetProvider>
    </>
  );
};

export default App;
