import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { userApproveByAdmin } from "../../../store/actions/userManageActions";
import ConfirmModal from "../../common/ConfirmModal";
import FormModal from "../../common/FormModal";
import { Form, Spinner } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { post } from "../../../servces/Api";
import Alert from "../../common/Alert";

const UserApproval = ({ user, isOnboard, isPending, onApprove }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState("")
  const [loading, setLoading] = useState(false)

  const toggleApprovalModal = () => {
    setShowModal(!showModal);
    setEndDate("")
  };

  const handleConfirmUserApproved = async () => {
    setLoading(true);
    const updatedData = {
      id: user.id,
      package_end_date: endDate === "" ? null : endDate,
    }
    try {
      const response = await dispatch(userApproveByAdmin(updatedData));
      if (response.meta.requestStatus === "fulfilled") {
        onApprove(); // Trigger the parent component's approval logic
        toggleApprovalModal(); // Hide the modal after approval
      } else {
        Alert.error("Unable to approve user!");
      }
    } catch (error) {
      Alert.error("Unable to approve user!");
    } finally {
      setLoading(false);
      setEndDate("");
    }
  };

  return (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip>Approve User</Tooltip>}>
        <Button
          variant="primary"
          size="sm"
          onClick={toggleApprovalModal}
          disabled={isOnboard === 0 && isPending === 1 ? true : false}
        >
          Approve
        </Button>
      </OverlayTrigger>

      <FormModal
        moduleName="Confirm User Approval"
        modalState={showModal}
        toggleFormModal={toggleApprovalModal}
        size="md"
      >
        <>
          {user.user_role.id === 3 ?
            <>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <p className="text-center">If the package end date is selected for the user, it will serve as the user's package end date. If not selected, the free trial end date will be used instead.</p>
              </div>
              <div className="d-flex flex-column p-2">
                <Form.Label className="font-weight-bold">Package End Date :</Form.Label>
                <ReactDatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  minDate={new Date()}
                  dropdownMode="select"
                  selected={endDate}
                  placeholderText="Package end date"
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </>
            :
            <div className="d-flex flex-column align-items-center justify-content-center">
              <p className="text-center">Are you sure you want to approve this user's account?</p>
            </div>
          }
        </>
        <div className='d-flex justify-content-end mr-5 p-2'>
          <Button disabled={loading} onClick={handleConfirmUserApproved} variant="success" className='fs-6 text-capitalize'>
            {loading && <Spinner animation="border" size="sm" />}{" "}Confirm
          </Button>
        </div>
      </FormModal>
    </>
  );
};

export default UserApproval;
