import { React, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { PricePackageCard } from "./PricePackageCard";
import { PricePackageData } from "../../data/HomeData";
import { get } from "../../servces/Api";
import Alert from "../common/Alert";

const ChooseYourPricePackage = () => {
  const [packageDetails, setPackageDetails] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get("/api/packages");
        if (response) {
          const packages = response.data.packages;
          const filteredPackges = packages.filter(pkg => pkg.id !== 5);
          setPackageDetails(filteredPackges);
        }
      } catch (err) {
        Alert.error("Error in package details fetch");
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
      <div className="testimonial-area pd-top-60 pd-bottom-60 bg-gray">
        <div className="container">
          <div className="section-title text-center mb-5">
            <h2 className="title">Select a pricing plan</h2>
            <p>
              Select a pricing plan that aligns with your project's needs and
              budget
            </p>
            <div className="d-flex gap-2 justify-content-center">
              <Link
                to="/signup"
                type="button"
                className="btn btn-primary feed-btn"
              >
                Start Free Trial
              </Link>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-8">
            {packageDetails.map((item) => (
              <PricePackageCard key={item.id} pakageData={item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseYourPricePackage;
