// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-area .section-title .title {
  color: #232340;
  font-size: 3.125rem;
  font-weight: 600;
  font-family: "Lexend Deca", sans-serif;
}
.template-area .section-title .content {
  color: #232340;
  font-size: 1.125rem;
  font-family: "Lexend Deca", sans-serif;
}
.template-area .section-title .search-box .input {
  padding: 0 20px 0 20px;
  color: var(--heading-color);
  width: 100%;
  border: 0;
}
.template-area .no-result {
  border: 1px solid #d1ecfd;
  color: #6c757d;
  font-size: 1.125rem;
}
.template-area .card .card-body {
  padding: 20px;
}
.template-area .card .card-body .card-title {
  font-size: 1.25rem;
  font-weight: 600;
}

@media only screen and (max-width: 991px) {
  .template-area .col {
    width: 100%;
    margin-bottom: 10px;
  }
  .template-area .col .search-box .input-group {
    gap: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .template-area .col {
    min-width: 100%;
    margin-bottom: 10px;
  }
  .template-area .col .search-box .input-group {
    gap: 10px;
  }
  .template-area .col .search-box .input-group .form-control,
  .template-area .col .search-box .input-group .btn {
    min-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/common/CardList.scss"],"names":[],"mappings":"AAEI;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,sCAAA;AADN;AAGI;EACE,cAAA;EACA,mBAAA;EACA,sCAAA;AADN;AAIM;EACE,sBAAA;EACA,2BAAA;EACA,WAAA;EACA,SAAA;AAFR;AAME;EACE,yBAAA;EACA,cAAA;EACA,mBAAA;AAJJ;AAOI;EACE,aAAA;AALN;AAMM;EACE,kBAAA;EACA,gBAAA;AAJR;;AAUA;EAEI;IACE,WAAA;IACA,mBAAA;EARJ;EAUM;IACE,SAAA;EARR;AACF;AAaA;EAEI;IACE,eAAA;IACA,mBAAA;EAZJ;EAcM;IACE,SAAA;EAZR;EAaQ;;IAEE,eAAA;EAXV;AACF","sourcesContent":[".template-area {\n  .section-title {\n    .title {\n      color: #232340;\n      font-size: 3.125rem;\n      font-weight: 600;\n      font-family: \"Lexend Deca\", sans-serif;\n    }\n    .content {\n      color: #232340;\n      font-size: 1.125rem;\n      font-family: \"Lexend Deca\", sans-serif;\n    }\n    .search-box {\n      .input {\n        padding: 0 20px 0 20px;\n        color: var(--heading-color);\n        width: 100%;\n        border: 0;\n      }\n    }\n  }\n  .no-result {\n    border: 1px solid #d1ecfd;\n    color: #6c757d;\n    font-size: 1.125rem;\n  }\n  .card {\n    .card-body {\n      padding: 20px;\n      .card-title {\n        font-size: 1.25rem;\n        font-weight: 600;\n      }\n    }\n  }\n}\n\n@media only screen and (max-width: 991px) {\n  .template-area {\n    .col {\n      width: 100%;\n      margin-bottom: 10px;\n      .search-box {\n        .input-group {\n          gap: 10px;\n        }\n      }\n    }\n  }\n}\n@media only screen and (max-width: 767px) {\n  .template-area {\n    .col {\n      min-width: 100%;\n      margin-bottom: 10px;\n      .search-box {\n        .input-group {\n          gap: 10px;\n          .form-control,\n          .btn {\n            min-width: 100%;\n          }\n        }\n      }\n    }\n  }\n}\n\n@media only screen and (max-width: 575px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
