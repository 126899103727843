import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ConfirmAlert from "../../common/ConfirmAlert";
import Alert from "../../common/Alert";

const BidQuotation = ({ setBidQuotationData }) => {
  const [data, setData] = useState([]);
  const [itemName, setItemName] = useState("");
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState("");
  const [editId, setEditId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#ccd2da",
        minHeight: 40,
      },
    },
    headCells: {
      style: {
        fontSize: "0.8125rem",
        borderBottom: "1px solid #ddd", // Border for header cells
      },
    },
    cells: {
      style: {
        borderLeft: "1px solid #ddd", // Border for regular cells
        borderRight: "1px solid #ddd", // Border for regular cells
      },
    },
  };

  useEffect(() => {
    setBidQuotationData(data);
  }, [data]);
  const handleEditChange = (id, field, value) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleBlur = (id, field, value) => {
    const item = data.find((item) => item.id === id);
    if (item.id === "new-item" && itemName && qty && price) {
      setData([
        ...data.slice(0, data.length - 1),
        {
          id: data.length,
          itemName,
          qty: Number(qty),
          price: Number(price),
        },
      ]);
      setItemName("");
      setQty("");
      setPrice("");
      return;
    }
    if (item) {
      handleEditChange(id, field, value);
    }
  };

  const saveEdit = (id) => {
    setEditId(null);
  };

  const addRow = () => {
    if (!itemName || !qty || !price) {
      Alert.error("All fields are mandatory! Please fill them in.");
      return;
    }

    const newItem = {
      id: data.length + 1,
      itemName,
      qty: Number(qty),
      price: Number(price),
    };
    setData([...data, newItem]);

    setItemName("");
    setQty("");
    setPrice("");
  };

  const deleteSelectedRows = () => {
    if (selectedRows.length === 0) {
      return;
    } else {
      setShowConfirmAlert(true);
    }
  };

  const confirmDelete = () => {
    const remainingData = data.filter(
      (item) => !selectedRows.map((row) => row.id).includes(item.id)
    );

    setData(remainingData);
    setSelectedRows([]);
    setShowConfirmAlert(false);
    setItemName("");
    setQty("");
    setPrice("");
  };

  const cancelDelete = () => {
    setShowConfirmAlert(false);
    setSelectedRows([]);
  };

  const columns = [
    {
      name: "Item Name",
      selector: (row) => row.itemName,
      sortable: true,
      cell: (row) =>
        editId === row.id ? (
          <input
            type="text"
            className="form-control"
            value={row.itemName}
            onChange={(e) =>
              handleEditChange(row.id, "itemName", e.target.value)
            }
          />
        ) : (
          row.itemName
        ),
    },
    {
      name: "Qty",
      selector: (row) => row.qty,
      sortable: true,
      cell: (row) =>
        editId === row.id ? (
          <input
            type="number"
            className="form-control"
            value={row.qty}
            onChange={(e) => handleEditChange(row.id, "qty", e.target.value)}
          />
        ) : (
          row.qty
        ),
    },
    {
      name: "Price (1 item)",
      selector: (row) => row.price,
      cell: (row) =>
        editId === row.id ? (
          <input
            type="number"
            className="form-control"
            value={row.price}
            onChange={(e) => handleEditChange(row.id, "price", e.target.value)}
            onBlur={(e) => handleBlur(row.id, "price", e.target.value)}
          />
        ) : typeof row.price === "number" ? (
          `$${row.price.toFixed(2)}`
        ) : (
          row.price
        ),
    },
    {
      name: "Total price",
      selector: (row) => row.price * row.qty,
      cell: (row) => {
        const price = parseFloat(row.price);
        const qty = parseFloat(row.qty);

        if (isNaN(price) || isNaN(qty)) {
          return ""; // Return empty string if price or qty is not a valid number
        }

        const total = price * qty;
        return `$${total.toFixed(2)}`;
      },
    },

    {
      name: "Actions",
      cell: (row) =>
        editId === row.id ? (
          <button type="button" onClick={() => saveEdit(row.id)}>
            Save
          </button>
        ) : row.id === "new-item" ? (
          <Button
            type="button"
            variant="primary"
            onClick={addRow}
            className="form-control"
          >
            Add Item
          </Button>
        ) : (
          <Button
            type="button"
            variant="secondary"
            onClick={() => setEditId(row.id)}
          >
            <i className="ri-edit-line"></i>
          </Button>
        ),
      ignoreRowClick: true,
      width: "118px",
    },
  ];

  const total = data.reduce((acc, item) => acc + item.qty * item.price, 0);

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center p-4 px-0">
        <h3 className="flex-grow-1 m-0">Add item's bid quotation</h3>

        {data.length > 0 && selectedRows.length > 0 && (
          <Button variant="danger" onClick={deleteSelectedRows}>
            Delete Selected
          </Button>
        )}
      </div>

      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={[
          ...data,
          {
            id: "new-item",
            itemName: (
              <input
                type="text"
                className="form-control"
                placeholder="Item Name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
            ),
            qty: (
              <input
                type="number"
                className="form-control"
                placeholder="Qty"
                value={qty}
                onChange={(e) => setQty(e.target.value)}
              />
            ),
            price: (
              <input
                type="number"
                className="form-control"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                onBlur={addRow}
              />
            ),
            actions: (
              <Button variant="primary" onClick={addRow}>
                Add Item
              </Button>
            ),
          },
        ]}
        selectableRows
        onSelectedRowsChange={({ selectedRows }) =>
          setSelectedRows(selectedRows)
        }
        selectedRows={selectedRows}
      />
      <div
        className="d-flex justify-content-end p-3 bg-gray-200 border border-top-0"
        style={{ fontSize: 20 }}
      >
        <strong className="flex-grow-1">Total Price: </strong>
        <strong>${total}</strong>
      </div>
      <ConfirmAlert
        show={showConfirmAlert}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        title="Confirmation"
        message="Are you sure you want to delete the selected rows?"
      />
    </div>
  );
};

export default BidQuotation;
