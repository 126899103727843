// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-area {
  color: #f5f5f5;
}
.footer-area a {
  color: #f5f5f5;
  font-size: 14px;
  font-weight: 300;
}
.footer-area a:hover {
  color: #5468e7;
}
.footer-area .bottom-links a {
  color: #f5f5f5;
  font-size: 14px;
  font-weight: 300;
  padding-right: 6px;
  padding-left: 6px;
}
.footer-area .bottom-links a:hover {
  color: #5468e7;
}
.footer-area.bg-blue-dark {
  background: #232340;
}
.footer-area .footer-bg {
  background: #19192e;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/home/Footer.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AADI;EACE,cAAA;AAGN;AACI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AACN;AAAM;EACE,cAAA;AAER;AAGE;EACE,mBAAA;AADJ;AAGE;EACE,mBAAA;AADJ","sourcesContent":[".footer-area {\n  color: #f5f5f5;\n  a {\n    color: #f5f5f5;\n    font-size: 14px;\n    font-weight: 300;\n    &:hover {\n      color: #5468e7;\n    }\n  }\n  .bottom-links {\n    a {\n      color: #f5f5f5;\n      font-size: 14px;\n      font-weight: 300;\n      padding-right: 6px;\n      padding-left: 6px;\n      &:hover {\n        color: #5468e7;\n      }\n    }\n  }\n\n  &.bg-blue-dark {\n    background: #232340;\n  }\n  .footer-bg {\n    background: #19192e;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
