import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import FormModal from './FormModal';

const ViewAttachmentModal = ({ fileUrl, showViewModal, closeModal }) => {
    const [fileType, setFileType] = useState(null);

    const getFileType = () => {
        const parts = fileUrl.split('/');
        const fileNameWithQueryParams = parts[parts.length - 1];
        const fileName = fileNameWithQueryParams.split('?')[0];

        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
            return 'pdf';
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
            return 'image';
        } else if (['xls', 'xlsx'].includes(extension)) {
            return 'excel';
        } else {
            return null;
        }
    };

    useEffect(() => {
        setFileType(getFileType());
    }, []);

    return (
        <FormModal
            moduleName={ "File Preview" }
            modalState={ showViewModal }
            toggleFormModal={ closeModal }
            size="md"
        >
            <div style={ { display: "flex", alignItems: "center", justifyContent: "center" } }>

                { fileType === 'pdf' && (
                    <iframe title="PDF Viewer" src={ fileUrl } width="100%" height="500px" />
                ) }
                { fileType === 'image' && (
                    <img src={ fileUrl } alt="File Preview" style={ { maxWidth: '100%', maxHeight: "500px" } } />
                ) }
                { fileType === 'excel' && (
                    <iframe title="Excel Viewer" src={ fileUrl } width="100%" height="500px" />
                ) }
                { fileType === null && <p>Unsupported file type</p> }

            </div>

        </FormModal>
    );
};

export default ViewAttachmentModal;
