import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Rating from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../store/actions/dashboardActions";
import { Card, Col, Nav, Row, Alert, Badge } from "react-bootstrap";
import { TASK_STATUS_TYPE } from "../../utils/types";
import { getTaskStatusColor } from "../../utils/utils";
import { formatDate } from "../../utils/utils";
import CountLoader from "../dashboard/ContLoader";
import ListLoader from "../dashboard/ListLoader";
import Avatar from "../common/Avatar";

const DeliveryDashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, error, dashboardData } = useSelector((state) => state.dashboard);
  const colCount = 3;

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);

  const countData = [
    {
      label: "Completed projects",
      icon: "ri-briefcase-line",
      value: dashboardData?.data?.completed_Project_Count || 0,
      smallText: "your projects"
    },
    {
      label: "Ongoing projects",
      icon: "ri-briefcase-line",
      value: dashboardData?.data?.ongoing_project_count || 0,
      smallText: "Your ongoing projects"
    },
    {
      label: "Customer feedback rating",
      icon: "ri-service-linee",
      value: dashboardData?.data?.customer_task_avg_rate || 0,
      smallText: dashboardData?.data?.customer_task_avg_rate ? "Your average rating" : '',
      element: dashboardData?.data?.customer_task_avg_rate ? (
        <Rating
          count={ dashboardData.data.customer_task_avg_rate }
          size={ 28 }
          edit={ false }
          color="#ffd700"
        />
      ) : (

        <small className="d-flex">There is no customer feedback available at this time.</small>

      )
    },
  ];

  const getStatusNameById = (id) => {
    const status = TASK_STATUS_TYPE.find((status) => status.id === id);
    return status ? status.name : "Unknown Status";
  };

  return (
    <>
      { isLoading ? (
        <CountLoader colCOunt={ colCount } />
      ) : (
        <Row>
          { countData.map((card, index) => (
            <Col xs="6" xl="4" key={ index }>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">
                    { card.label }
                  </Card.Title>
                  { card.element ? card.element : <h3 className="card-value mb-1">
                    <i className={ card.icon }></i> { card.value }
                  </h3> }
                  <small>{ card.smallText }</small>
                </Card.Body>
              </Card>
            </Col>
          )) }
        </Row>
      ) }

      <Row className="g-3 mt-2">
        { isLoading ? (
          <ListLoader rowCount={ 10 } xlCol={ 6 } />
        ) : (
          <Col md="6" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Pending tasks</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  { dashboardData?.data?.pending_task_list?.length ? (
                    dashboardData.data.pending_task_list.map((task, index) => (
                      <li className="people-item" key={ index }>
                        <Avatar initial={ task.task_title.charAt(0) } />
                        <div className="people-body">
                          <h6>
                            <Link to='/projects-manage'>
                              { task.task_title }
                            </Link>
                          </h6>
                          <span className="three-lines">{ task.task_description }</span>
                          <span>
                            <strong className="text-danger">Deadline: </strong>
                            { formatDate(task.task_closing_date) }
                          </span>
                        </div>
                        <Badge bg={ getTaskStatusColor(task.task_status_id) }>
                          { getStatusNameById(task.task_status_id) }
                        </Badge>
                      </li>
                    ))
                  ) : (
                    <li className="people-item">
                      <div className="people-body">
                        <Alert variant="primary" className="d-flex align-items-center mt-2 mb-2">
                          <small>No Pending tasks available</small>
                        </Alert>
                      </div>
                    </li>
                  ) }
                </ul>
              </Card.Body>
              { dashboardData?.data?.ongoing_project_list?.length !== 0 ? (
                <Card.Footer className="d-flex justify-content-start">
                  <Link to="/projects-manage" className="fs-sm">
                    Manage your task
                  </Link>
                </Card.Footer>
              ) : '' }
            </Card>
          </Col>
        ) }

        { isLoading ? (
          <ListLoader rowCount={ 10 } />
        ) : (
          <Col md="6" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Customer feedback</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  { dashboardData?.data?.customer_task_feedback?.length ? (
                    dashboardData.data.customer_task_feedback.map((feedback) => (
                      <li className="people-item" key={ feedback.id }>
                        <Avatar initial={ <i class="ri-message-2-line"></i> } />
                        <div className="people-body">
                          <h6>
                            { feedback.comment }
                          </h6>
                          <span className="three-lines">{ feedback.comment }</span>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="people-item">
                      <div className="people-body">
                        <Alert variant="primary" className="d-flex align-items-center mt-2 mb-2">
                          <small>No Customer feedback available</small>
                        </Alert>
                      </div>
                    </li>
                  ) }
                </ul>
              </Card.Body>
            </Card>
          </Col>
        ) }
      </Row>
    </>
  );
};

export default DeliveryDashboard;
