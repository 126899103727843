import React from "react";
import { Link } from "react-router-dom";
import HeaderSection3 from "../../assets/img/HeaderSection3.png";
import "../../assets/styles/components/home/ChooseWorkPreference.scss";

export default function ChooseWorkPreference() {
  return (
    <div className="work-preference pd-top-60 pd-bottom-60 bg-light-gray">
      <div className="container">
        <div className="team-details-page">
          <div className="row">
            <div className="col-lg-5 text-center">
              <div className="thumb mb-4 mb-lg-0">
                <img src={HeaderSection3} alt="img" />
              </div>
            </div>
            <div className="col-lg-7 align-self-center ps-xl-5">
              <div className="content-section mb-0 ">
                <h2 className="title mb-2">Choose how you want to work</h2>

                <p className="content">
                  you’ve got all the flexibility to work when, where and how
                  it’s best for you. You can easily chat, hop on a huddle to
                  talk things out live.
                </p>
                <Link className="learn-more-link" to="/#">
                  Learn more <i className="ri-arrow-right-line"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
