import React from "react";
import pageSvg from "../assets/svg/mailbox.svg";

import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

const AcceptedQuotationMsg = () => {
  return (
    <div className="page-auth">
      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <img src={pageSvg} alt="Email veryfication" width={200} />
              </div>
              <Card.Title>Quotation Already Accepted!</Card.Title>
              <Card.Text>
                Thank you for accepting the quotation. If you have any questions
                or need further assistance, please don't hesitate to contact us.
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default AcceptedQuotationMsg;
