import React from "react";
import DataTable from "react-data-table-component";
import TableLoader from "./TableLoader";
import { Alert } from "react-bootstrap";

const PaginationWithDataTable = ({
  dataColumns,
  entities,
  loading,
  pagination = true,
  rowCount,
  columnCount,
}) => {
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#ccd2da",
        minHeight: 40,
      },
    },
    headCells: {
      style: {
        fontSize: "0.8125rem",
        borderBottom: "1px solid #ddd", // Border for header cells
      },
    },
    cells: {
      style: {
        borderLeft: "1px solid #ddd", // Border for regular cells
        borderRight: "1px solid #ddd", // Border for regular cells
      },
    },
  };

  return (
    <DataTable
      columns={dataColumns}
      defaultSortAsc={true}
      data={entities}
      sortActive={true}
      fixedHeader
      pagination={pagination}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30, 60, 100]}
      customStyles={customStyles}
      striped
      highlightOnHover
      progressPending={loading}
      progressComponent={
        <TableLoader rowCount={rowCount} columnCount={columnCount} />
      }
      noDataComponent={
        !loading && (
          <Alert
            variant="primary"
            className="d-flex align-items-center mb-2 w-100"
          >
            <i className="ri-information-line"></i> No data available in the
            table.
          </Alert>
        )
      }
      responsive
    />
  );
};

export default PaginationWithDataTable;
