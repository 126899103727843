import React from "react";
import BidsAccordion from "./BidsAccordion";
import { Alert } from "react-bootstrap";

const TenderBid = ({
  bidList = {},
  tenderAddUser,
  remainingDays,
  iscustomerQuotations,
  tenderStatus
}) => {
  const bidListItems = bidList.companyQuotations || [];

  return (
    <>
      { bidListItems.length > 0 ? (
        <div className="mb-4">
          <Alert
            variant="success"
            className="d-flex align-items-center mb-2 mt-4 mb-3"
          >
            <i className="ri-list-check"></i>
            <p className="mb-0">
              { bidListItems.length }{ " " }
              <strong>bid{ bidListItems.length > 1 ? `'s` : "" }</strong> have
              been received for this tender.
            </p>
          </Alert>

          <BidsAccordion
            bidListItems={ bidListItems }
            tenderAddUser={ tenderAddUser }
            remainingDays={ remainingDays }
            iscustomerQuotations={ iscustomerQuotations }
            tenderStatus={ tenderStatus }
          />
        </div>
      ) : (
        <Alert
          variant="primary"
          className="d-flex align-items-center mb-2 mt-4"
        >
          <i className="ri-information-line"></i>
          <p className="mb-0">
            No <strong>bids</strong> have been received for this tender.
          </p>
        </Alert>
      ) }
    </>
  );
};

export default TenderBid;
