import { createSlice } from "@reduxjs/toolkit";
import { userOnboard } from "../actions/onboardActions";

const initialState = {
  loading: false,
  success: false,
  error: null,
  onboardedUser: null,
};

const onboardSlice = createSlice({
  name: "userOnboarded",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(userOnboard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userOnboard.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.onboardedUser = action.payload;
        if (action.payload) {
          state.success = true;
        }
      })
      .addCase(userOnboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default onboardSlice.reducer;
