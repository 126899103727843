import React, { useEffect, useState } from "react";
import { Button, Dropdown, Popover } from "react-bootstrap";
import { CustomToggle, getRandomColor } from "./../../../utils/utils";

const TaskPersonList = ({
  PersonList,
  getPersonWiseFilters,
  filteredProjectId,
  filteredType,
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} variant="secondary">
        <Button
          variant="link"
          className="align-items-center icon-with-btn text-dark "
        >
          <i className="ri-account-circle-fill fs-5" />
          Person
        </Button>
      </Dropdown.Toggle>

      <Dropdown.Menu className="mt-10-f me--10-f dropdown-max-width">
        <div className="d-flex flex-column align-items-start p-2">
          <div className="mb-2">Filter this board by person</div>
          <div className="d-flex">
            {PersonList &&
              PersonList.map((list) => (
                <div
                  className=" me-2 text-white task-status-icon text-capitalize p-2 cursor-pointer"
                  style={{ backgroundColor: getRandomColor() }}
                  key={list.id}
                  onClick={() => getPersonWiseFilters(list.id)}
                >
                  {list.first_name.slice(0, 2)}
                </div>
              ))}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TaskPersonList;
