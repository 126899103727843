import React from "react";
import moment from "moment";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

//Date format date only
export const formatDate = (dateString) => {
  const date = moment(dateString);
  return date.format("MMMM Do YYYY");
};

export const getSasToken = () => {
  return Cookies.get("sasToken");
};

export const getTaskStatusColor = (statusId) => {
  switch (statusId) {
    case 1: // Assuming 1 corresponds to IN PROGRESS
      return "warning";
    case 2: // Assuming 2 corresponds to DONE
      return "success";
    case 3: // Assuming 3 corresponds to HOLD
      return "warning";
    case 4: // Assuming 4 corresponds to TODO
      return "primary";
    case 5: // Assuming 4 corresponds to VERIFY
      return "warning";
    case 6: // Assuming 4 corresponds to CANCEL
      return "danger";
    default:
      return "secondary"; // Default Bootstrap variant
  }
};

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="dropdown-link"
  >
    {children}
  </Link>
));

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className="custom-input" onClick={onClick} ref={ref}>
    <input value={value} readOnly className="form-control w-100" />
    <i className="ri-calendar-line calendar-icon"></i>
  </div>
));
