import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useUserRole from "../../hooks/getUserRole";
import useAuth from "../../hooks/useAuth";
import Footer from "../../components/dashboard/Footer";
import ProjectManagementTopSection from "../../components/dashboard/projects/ProjectManagementTopSection";
import ProjectsTaskList from "../../components/dashboard/projects/ProjectsTaskList";
import {
  fetchTaskList,
  getSearchProjects,
} from "../../store/actions/projectManageActions";
import TableLoader from "./../../components/common/TableLoader";

const ProjectsManage = () => {
  const moduleName = "Project Management";
  const isAuthenticated = useAuth();
  const { userRoleName } = useUserRole(isAuthenticated.user_role);
  const dispatch = useDispatch();
  const { isLoading, projectTaskListData } = useSelector(
    (state) => state.projectManage
  );
  const [projectData, setProjectData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  useEffect(() => {
    setIsSearch(false);
    dispatch(fetchTaskList());
  }, [dispatch]);

  const getSearchByProject = async (search_task) => {
    setIsSearchLoading(true);

    search_task === "" ? setIsSearch(false) : setIsSearch(true);
    if (search_task != "") {
      const data = {
        search_task,
      };
      const response = await dispatch(getSearchProjects(data));

      if (!response.payload) {
        setIsSearchLoading(false);
        return false;
      } else {
        const project = response.payload?.projects;
        setProjectData(project);
        setIsSearchLoading(false);
      }
    }
    setIsSearchLoading(false);
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active ">{moduleName}</li>
            </ol>
            <h4 className="main-title mb-0 text-capitalize">{moduleName}</h4>
          </div>
          <div>{/* TODO: Added any acction buttons */}</div>
        </div>
        <div className="inner-wrapper">
          {projectTaskListData?.projects &&
            projectTaskListData.projects.length > 0 && (
              <ProjectManagementTopSection
                ProjectTaskData={
                  projectTaskListData ? projectTaskListData.projects : [] || []
                }
                allUserList={
                  projectTaskListData ? projectTaskListData.allUsers : []
                }
                getSearchByProject={(search) => getSearchByProject(search)}
              />
            )}

          {isLoading || isSearchLoading ? (
            <TableLoader rowCount={4} columnCount={6} />
          ) : (
            <ProjectsTaskList
              ProjectTaskData={
                isSearch
                  ? projectData
                  : projectTaskListData
                  ? projectTaskListData.projects
                  : [] || []
              }
            />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProjectsManage;
