import React, { useState } from "react";
import {
  Button,
  OverlayTrigger,
  Popover,
  Dropdown,
  ListGroup,
  Badge,
  Form,
} from "react-bootstrap";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDatePicker from "react-datepicker";
import { Link, Navigate } from "react-router-dom";
import { CustomToggle, getRandomColor } from "./../../../utils/utils";
import { TASK_STATUS_TYPE } from "./../../../utils/types";
import { CustomInput } from "../../../utils/utils";
import CustomSearchSelect from "../../common/CustomSearchSelect";

const TaskFilter = ({
  PersonList,
  filteredProjectId,
  filteredType,
  clearFilter,
  getFilters,
}) => {
  const moment = require("moment");
  const [closingDate, setClosingDate] = useState("");
  const [selectedPersonOption, setSelectedPersonOption] = useState(null);
  const [selectedTaskStatusOption, setSelectedTaskStatusOption] = useState([]);

  const handlePersonChange = (option) => {
    setSelectedPersonOption(option);
  };
  const handleTaskChange = (option) => {
    setSelectedTaskStatusOption(option);
  };

  const getTaskOptions = () => {
    let options = [];
    TASK_STATUS_TYPE.map((item) => {
      options.push({ value: item.id, label: item.name });
    });
    return options;
  };

  const getPersonOptions = () => {
    let options = [];
    PersonList.map((item) => {
      options.push({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
      });
    });
    return options;
  };

  const filter = () => {
    if (
      closingDate === "" &&
      selectedPersonOption === null &&
      selectedTaskStatusOption.length === 0
    )
      return;

    getFilters(
      closingDate != "" ? moment(closingDate).format("YYYY-MM-DD") : "",
      selectedPersonOption,
      selectedTaskStatusOption
    );
  };
  const resetAll = () => {
    setClosingDate("");
    setSelectedPersonOption(null);
    setSelectedTaskStatusOption([]);
    clearFilter();
  };
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} variant="secondary">
        <Button
          variant="link"
          className="align-items-center icon-with-btn  text-dark"
        >
          <i className="ri-filter-2-line fs-5"></i> Filter
        </Button>
      </Dropdown.Toggle>

      <Dropdown.Menu className="mt-10-f me--10-f dropdown-max-width">
        <div className="dropdown-menu-body">
          <div className="flex-fill bg-gray-100 b-5">
            <div className="mt-2">
              <div className="ps-3 pe-3  d-flex justify-content-between align-items-center fw-bold">
                <p className="text-center">Quick filter</p>
                <p
                  className="text-center cursor-pointer"
                  onClick={() => {
                    resetAll();
                  }}
                >
                  Clear all
                </p>
              </div>
              <div className="p-3 pt-0 d-flex gap-2 text-white">
                <div>
                  <p className="m-0 p-2 text-black">Task Status</p>
                  <CustomSearchSelect
                    options={getTaskOptions()}
                    selectedOption={selectedTaskStatusOption}
                    handleChange={handleTaskChange}
                    isMultiValue={true}
                  />
                </div>
                <div>
                  <p className="m-0 p-2 text-black">Assignee</p>
                  <CustomSearchSelect
                    options={getPersonOptions()}
                    selectedOption={selectedPersonOption}
                    handleChange={handlePersonChange}
                  />
                </div>
                <div>
                  <p className="m-0 p-2 text-black">Due Date</p>
                  <ListGroup className="border-0">
                    <ReactDatePicker
                      className="form-control"
                      selected={closingDate}
                      onChange={(date) => setClosingDate(date)}
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<CustomInput />}
                    />
                  </ListGroup>
                </div>
              </div>
              <div className="ps-3 pe-3  d-flex justify-content-end align-items-center fw-bold">
                <Button
                  variant="secondary"
                  className="align-items-center icon-with-btn"
                  onClick={() => filter()}
                >
                  <i className="ri-filter-2-line fs-5"></i> Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TaskFilter;
