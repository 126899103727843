import React, { useState, useEffect } from 'react'
import Footer from '../../components/dashboard/Footer';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from "../../assets/styles/components/common/PricingPackages.module.scss"
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Placeholder } from 'react-bootstrap';
import ConfirmModal from '../../components/common/ConfirmModal';
import { useDispatch } from 'react-redux';
import { userUpgradePackage } from '../../store/actions/authActions';
import { setUserPackageNotExpired } from '../../store/reducers/authSlice';
import { PricePackageData } from '../../data/HomeData';
import { get } from '../../servces/Api';
import Alert from '../../components/common/Alert';

function PricingPackages() {
    const dispatch = useDispatch();
    const UserData = useSelector((state) => state.auth.userInfo);
    const UserExpired = useSelector((state) => state.auth.userPackageExpired);
    const [timePeriod, setTimePeriod] = useState("month");
    const [showModal, setShowModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState();
    const [selectedPricingPackges, setSelectedPricingPackges] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getPackages();
    }, []);

    const getPackages = async () => {
        setLoading(true);
        try {
            const response = await get("/api/auth_packages");
            if (!response.error) {
                const packages = response.data.packages;
                const filteredPackages = packages.filter(pkg => [2, 3, 4].includes(pkg.id));
                setSelectedPricingPackges(filteredPackages);
                setLoading(false);
            } else {
                Alert.error(response.error);
                setLoading(false);
            }
        } catch (error) {
            Alert.error("Unable to get packges list!");
            setLoading(false);
        }
    };

    const pricingCardCLick = (id) => {
        setSelectedPackage(id);
        setShowModal(true);
    }

    const handleConfirmUpgrade = () => {
        dispatch(userUpgradePackage(selectedPackage))
            .then(() => {
                dispatch(setUserPackageNotExpired());
                setShowModal(false);
            })
            .catch((error) => {
                console.error("Upgrade failed:", error);
            });
    }

    function capitalizeFirstLetter(string) {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="w-100">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item">
                                <Link href="#">Upgrade Plan</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Select Plan
                            </li>
                        </ol>
                        <div class="row g-0 d-flex align-items-center mb-3">
                            <div class="col-sm-6 col-md-8">
                                <h4 className="fs-24 mb-0">Upgrade Plan</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={ styles['top-container'] }>
                    <p className={ styles['main-topic'] }>Pricing Plan</p>
                    <p className={ styles['desc'] }>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                    {/* <ButtonGroup aria-label="Basic example">
                        <Button
                            style={{
                                background: timePeriod === "month" ? "#5f5fd3" : "#ffffff",
                                color: timePeriod === "month" ? "#ffffff" : "#5f5fd3"
                            }}
                            className={styles['btn']}
                            onClick={() => setTimePeriod("month")}
                        >
                            Monthly
                        </Button>
                        <Button
                            style={{
                                background: timePeriod === "month" ? "#ffffff" : "#5f5fd3",
                                color: timePeriod === "month" ? "#5f5fd3" : "#ffffff"
                            }}
                            className={styles['btn']}
                            onClick={() => setTimePeriod("year")}
                        >
                            Yearly
                        </Button>
                    </ButtonGroup> */}
                </div>
                { loading &&
                    <Placeholder as="div" animation="glow" className={ styles["middle-container"] }>
                        <Placeholder className={ styles["place-holder"] } />
                        <Placeholder className={ styles["place-holder"] } />
                        <Placeholder className={ styles["place-holder"] } />
                    </Placeholder>
                }
                { !loading && <div className={ styles["middle-container"] }>
                    { selectedPricingPackges.length > 0 && selectedPricingPackges.map((pkg) => (
                        <div className={ `${styles["grid-item"]} ${(UserData.package_id === pkg.id && !UserExpired) ? styles['selected'] : ""}` }>
                            <div className={ styles['title'] }>{ capitalizeFirstLetter(pkg.name) }</div>
                            <div className={ styles['desc'] }>{ pkg?.description }</div>
                            <div className={ styles["horizontal-line"] }></div>
                            <div className={ styles['price-monthly'] }>
                                <span className={ styles['hilight'] }>${ pkg?.price }</span>
                            </div>
                            {/* <div className={styles['price-yearly']}>
                                or $199 yearly
                            </div> */}
                            <button onClick={ () => pricingCardCLick(pkg.id) } className={ styles['btn'] }>
                                Subscribe Now
                            </button>
                            <div className={ styles["horizontal-line"] }></div>
                            <div className={ styles['list'] }>
                                { pkg?.features.length > 0 && pkg.features.map((feature) => (
                                    <div className={ styles['list-item'] }>
                                        <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                        <div>{ feature?.feature_name }</div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    ))
                    }
                </div> }
                <div className={ styles['bottom-container'] }>
                    <p className={ styles['title'] }>Compare plan features</p>
                    <div className={ styles['main-container-column'] }>
                        <div className={ styles['child1'] }>
                            Plan
                        </div>
                        <div className={ styles['child'] }>
                            Starter Plan
                        </div>
                        <div className={ styles['child'] }>
                            Business Plan
                        </div>
                        <div className={ styles['child'] }>
                            Enterprise Plan
                        </div>
                    </div>
                    <>
                        <div className={ styles['text-divider'] } >Feature Category</div>
                        <div className={ styles['data-container'] }>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child'] }>
                                    10
                                </div>
                                <div className={ styles['child'] }>
                                    20
                                </div>
                                <div className={ styles['child'] }>
                                    Unlimited
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1-light'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1-light'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                        </div>
                    </>
                    <>
                        <div className={ styles['text-divider'] } >Feature Category</div>
                        <div className={ styles['data-container'] }>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child'] }>
                                    10
                                </div>
                                <div className={ styles['child'] }>
                                    20
                                </div>
                                <div className={ styles['child'] }>
                                    Unlimited
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1-light'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1-light'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                        </div>
                    </>
                    <>
                        <div className={ styles['text-divider'] } >Feature Category</div>
                        <div className={ styles['data-container'] }>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child'] }>
                                    10
                                </div>
                                <div className={ styles['child'] }>
                                    20
                                </div>
                                <div className={ styles['child'] }>
                                    Unlimited
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1-light'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1-light'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child-light'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                            <div className={ styles['main-container-data'] }>
                                <div className={ styles['child1'] }>
                                    Feature text goes here
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                </div>
                                <div className={ styles['child'] }>
                                    <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-close-line"></i>
                                </div>
                            </div>
                        </div>
                    </>
                    <ConfirmModal
                        show={ showModal }
                        onHide={ () => setShowModal(false) }
                        onConfirm={ handleConfirmUpgrade }
                        title="Please confirm the package subscription"
                        message={ `Are you sure you want to subscribe to the  ${selectedPackage === 2 ? "Starter" : selectedPackage === 3 ? "Business" : "Enterprise"} Package?` }
                    />
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default PricingPackages