import Pusher from "pusher-js";

const pusherKey = process.env.REACT_APP_PUSHER_KEY;

const pusher = new Pusher(`${pusherKey}`, {
  cluster: 'ap2',
  encrypted: true,
});

export default pusher;
