import { Link } from "react-router-dom";
import styles from "../../assets/styles/components/home/priceCard.module.scss";

export const PricePackageCard = ({ pakageData }) => {

  function formatPackageName(str) {
    return str
      .split('_') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return (
    <div className="col">
      <div
        className={`card text-center px-3 mb-4 shadow-sm ${
          styles.card_pricing
        } ${pakageData.id === 3 && styles.popular}`}
      >
        <span
          className={`h6 w-80 mx-auto px-4 py-1 rounded-bottom bg-gradient text-white shadow-sm ${
            pakageData.id === 3 ? "bg-primary" : "bg-secondary"
          }`}
        >
          {formatPackageName(pakageData?.name)}
        </span>
        <div className="bg-transparent card-header pt-4 border-0">
          <h1
            className="h1 font-weight-normal text-primary text-center mb-0"
            data-pricing-value="15"
          >
            $<span className="price">{pakageData?.price}</span>
            <span className="h6 text-muted ml-2">/ per month</span>
          </h1>
        </div>
        <div className="card-body pt-0">
          <ul className={`${styles.list_unstyled} mb-4`}>
            {pakageData?.features.map((feature) => (
              <li>{feature?.feature_name}</li>
            ))}
          </ul>
          <Link to="/signup" variant="primary" className="btn btn-primary">
            Choose Plan
          </Link>
        </div>
      </div>
    </div>
  );
};
