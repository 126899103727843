import React from "react";

import Footer from "../../components/dashboard/Footer";
import Chat from "../../components/chat/Chat";

const ProjectChat = () => {
  const moduleName = "Blank Page";

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        {/* <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{moduleName}</li>
            </ol>
            <h4 className="main-title mb-0">{moduleName}</h4>
          </div>
          <div></div>
        </div> */}
        <div className="inner-wrapper">
          <Chat />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ProjectChat;
