import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardLoading = () => {
  return (
    <SkeletonTheme baseColor="#e2e5ec" highlightColor="#f3f5f9">
      <div className="card">
        <Skeleton height={180} />
        <div className="card-body">
          <h5 className="card-title">
            <Skeleton width={`80%`} />
          </h5>
          <p className="card-text">
            <Skeleton count={3} />
          </p>
          <Skeleton width={`60%`} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default CardLoading;
