import baseAxios from "axios";
import Cookies from "js-cookie";

const axios = baseAxios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "fa",
  },
});

let isRefreshing = false;
let failedRequests = [];

const isTokenExpired = (expiryTime) => {
  return new Date(expiryTime) < new Date();
};

const refreshToken = async () => {
  const refreshToken = Cookies.get("refreshToken");
  if (!refreshToken) {
    console.error("No refresh token available");
    throw new Error("");
  }

  try {
    const response = await baseAxios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/api/auth/refresh-token`,
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": "fa",
        },
      }
    );

    const { token, ac_expiration_time: expirationTime } = response.data;
    const expiryDate = new Date(expirationTime);

    Cookies.set("userToken", token, { expires: expiryDate });
    localStorage.setItem("tokenExpiry", expiryDate.toISOString());

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return token;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

axios.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("userToken");
    const tokenExpiry = localStorage.getItem("tokenExpiry");

    if (token && tokenExpiry && !isTokenExpired(tokenExpiry)) {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    }

    if (token && tokenExpiry && isTokenExpired(tokenExpiry)) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newToken = await refreshToken();
          config.headers["Authorization"] = `Bearer ${newToken}`;
          failedRequests.forEach((req) => req.resolve(newToken));
          failedRequests = [];
        } catch (error) {
          failedRequests.forEach((req) => req.reject(error));
          failedRequests = [];
          throw error;
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise((resolve, reject) => {
          failedRequests.push({
            resolve: (newToken) => {
              config.headers["Authorization"] = `Bearer ${newToken}`;
              resolve(config);
            },
            reject: (error) => reject(error),
          });
        });
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newToken = await refreshToken();
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          failedRequests.forEach((req) => req.resolve(newToken));
          failedRequests = [];
          return axios(originalRequest);
        } catch (refreshError) {
          failedRequests.forEach((req) => req.reject(refreshError));
          failedRequests = [];
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise((resolve, reject) => {
          failedRequests.push({
            resolve: (newToken) => {
              originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
              resolve(axios(originalRequest));
            },
            reject: (error) => reject(error),
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
