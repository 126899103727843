import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CountLoader = ({ colCOunt }) => {

  return (
    <Row className="row">
      <SkeletonTheme baseColor="#e2e5ec" highlightColor="#f3f5f9">
        { Array.from({ length: colCOunt }).map((_, index) => (
          <Col md="6" xl={ Array.from({ length: colCOunt }).length >= 4 ? "3" : "4" } key={ index }>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  <Skeleton width={ `100%` } height={ 108 } />
                </h5>
              </div>
            </div>
          </Col>
        )) }
      </SkeletonTheme>
    </Row>
  );
};

export default CountLoader;