import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/dashboard/Footer";
import { getUserProfileInfo } from "../../store/actions/profileActions";
import ProfileView from "../../components/common/profile/ProfileView";
import useAuth from "../../hooks/useAuth";

const MyProfile = () => {
  const isAuthenticated = useAuth();
  const dispatch = useDispatch();
  const { profileData, isLoading, error } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    dispatch(getUserProfileInfo(isAuthenticated.id));
  }, [dispatch, isAuthenticated]);

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Settings
              </li>
            </ol>
            <h4 className="main-title mb-0">My Profile</h4>
          </div>
        </div>
        <div className="inner-wrapper">
          <ProfileView
            profileData={ profileData }
            isLoading={ isLoading }
            myProfile={ true }
          />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyProfile;
