import React, { useState, forwardRef } from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import FormModal from "../../common/FormModal";
import { getUserProfileInfo } from "../../../store/actions/profileActions";
import ProfileView from "../../common/profile/ProfileView";

const UserInfoView = forwardRef(({ userId }, ref) => {
  const dispatch = useDispatch();
  const [showModalState, setShowModalState] = useState(false);
  const { profileData, isLoading, error } = useSelector(
    (state) => state.profile
  );

  const toggleFormModal = () => {
    setShowModalState((prevState) => !prevState);
  };

  const handleViewInfo = (userId) => {
    dispatch(getUserProfileInfo(userId));
    toggleFormModal();
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>View user information</Tooltip>}
      >
        <div ref={ref} style={{ display: "inline-block" }}>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              handleViewInfo(userId);
            }}
            className="mr-2 d-flex"
          >
            <i className="ri-eye-line"></i>
          </Button>
        </div>
      </OverlayTrigger>
      <FormModal
        moduleName={"Profile details"}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="600px"
      >
        <div className="container">
          <div className="row align-items-center">
            <ProfileView isLoading={isLoading} profileData={profileData} />
          </div>
        </div>
      </FormModal>
    </>
  );
});

export default UserInfoView;
